import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { Radio, Divider, Form, Input, message, Button, TimePicker, DatePicker,Select,Space } from "antd";
import moment from 'moment'
import TextArea from "antd/es/input/TextArea";
import CandidateAddModal from "../../components/CandidateAddModal";

import useCandidateStore from "../../store/candidateModal.store";
import useResumeStore from "../../store/resume.store";
import useJDStore from "../../store/jd.store";

import "./jobApplicationForm.scss";
import cloudIcon from "../../assets/upload.png";
import downloadIcon from "../../assets/download.png";
import profileIcon from "../../assets/profile.png";

import editIcon from "../../assets/pencil.png";


export default function JobApplicationForm() {
    const [currentView, setCurrentView] = useState("profile");
    const { companyName,jdID, resumeID } = useParams();

    const [formKey, setFormKey] = useState(1);
    const [isRequired, setIsRequired] = useState(true);

    const { setCandidateModalOpen } = useCandidateStore();
    
    const { createJd, updateJd, selectedJD, clearJD } = useJDStore();
    const { resumes, getResumesByJD, selectedProfileResume, downloadResumeByID, getSpecificApplicationByJD,
            updateProfileInApplication,updateProfileResumeDetails,getProfileResumeDetails,selectedApplication} =
        useResumeStore();

    var value = jdID;
    const isEditMode = true
    const [currentApplication, setCurrentApplication] = useState(selectedApplication);

    useEffect(() => {
        console.log("Selected Profile 4444", selectedProfileResume)
        setCurrentApplication(selectedApplication)
    }, [selectedApplication]);

    const handleDownloadResume = (resumeID) => {
        downloadResumeByID(jdID, resumeID);
    };

    function getTimeNow(){
        const today = new Date();
        const month = today.getMonth()+1;
        const year = today.getFullYear();
        const date = today. getDate();
        const currentDate = date + "-" + month + "-" + year;
        return currentDate
    }

    const encodeApplicationJson = (values) =>{

        let encodedJsonValues ={};

        console.log("Application Data to encode", values)
        //FSM to maintain the JD state
        encodedJsonValues.applicationstatus = values.applicationstatus

        //get company details
        const userData = JSON.parse(localStorage.getItem("userDetails"));
        console.log("User data", userData)
        encodedJsonValues.qhoid= userData.qhoid;
        encodedJsonValues.accounttype= userData.account_type ;
        encodedJsonValues.qhrid= resumeID;
        encodedJsonValues.accounttype= userData.account_type ;
        encodedJsonValues.qhcompanyjid= jdID
        encodedJsonValues.version= "1.0"
        encodedJsonValues._id ="Not Needed Only Dummy"

        //now fill the parameters
        encodedJsonValues.applicationid ={};
        encodedJsonValues.applicationid.name=values.name;
        encodedJsonValues.applicationid.email=values.email;
        encodedJsonValues.applicationid.mobile=values.mobile;
        encodedJsonValues.applicationid.aadharid=values.aadharid;


        encodedJsonValues.newemploymentdetails ={};
        encodedJsonValues.newemploymentdetails.preferredlocation=values.preferredlocation;
        encodedJsonValues.newemploymentdetails.locationchangereason=values.locationchangereason;
        encodedJsonValues.newemploymentdetails.ectc={}
        encodedJsonValues.newemploymentdetails.ectc.totalctc= values.ectc;    
        encodedJsonValues.newemploymentdetails.payroll = values.payroll  ;  

        let newDate = getTimeNow()

        encodedJsonValues.sourcingdetails ={};
        encodedJsonValues.sourcingdetails.uploadedby= "TEAM"; //TEAM,VENDOR<PORTAL
        encodedJsonValues.sourcingdetails.teamdetails={}
        encodedJsonValues.sourcingdetails.teamdetails.qhuid= userData.qhuid;
        encodedJsonValues.sourcingdetails.teamdetails.name=userData.username; 
        encodedJsonValues.sourcingdetails.teamdetails.updatedon= newDate; 
        encodedJsonValues.sourcingdetails.resumeownership=userData.organization; 

        //encodedJsonValues.resumestatus ={};
        //encodedJsonValues.resumestatus.resumestatus= values.skills;
        //encodedJsonValues.resumestatus.resumesuitability= values.roles_and_responsibility;
        //encodedJsonValues.resumestatus.tags= values.skills;
        //encodedJsonValues.resumestatus.updatedby={}
        //encodedJsonValues.resumestatus.updatedby.updatedon= newDate;
        //encodedJsonValues.resumestatus.updatedby.qhuid= values.education;

        encodedJsonValues.candidatestatus ={};
        encodedJsonValues.candidatestatus.candidatestatus= values.candidatestatus;
        encodedJsonValues.candidatestatus.updatedby={}
        encodedJsonValues.candidatestatus.updatedby.updatedon= newDate;
        encodedJsonValues.candidatestatus.updatedby.qhuid= userData.qhuid;

        encodedJsonValues.interviewstatus =null;
        encodedJsonValues.selectionstatus =null;        
        encodedJsonValues.skillsevalsatus =null;
        encodedJsonValues.testevalstatus =null;
        
        
        return encodedJsonValues
    }

    function getTimeNow(){
        const today = new Date();
        const month = today.getMonth()+1;
        const year = today.getFullYear();
        const date = today. getDate();
        const currentDate = date + "-" + month + "-" + year;
        return currentDate
    }

    const encodeResumeDetailsJson = (values) =>{

        let encodedJsonValues ={};

        console.log("Resune Data to encode", values)

        //get company details
        const userData = JSON.parse(localStorage.getItem("userDetails"));
        encodedJsonValues.qhoid= userData.qhoid;
        encodedJsonValues.accounttype= userData.account_type ;
        encodedJsonValues.qhrid= resumeID;
        encodedJsonValues.accounttype= userData.account_type ;
        encodedJsonValues.qhcompanyjid= jdID
        encodedJsonValues._id ="Not Needed Only Dummy"

        //now fill the parameters
        encodedJsonValues.employmentdetails ={};        
        encodedJsonValues.employmentdetails.currentcompany = values.currentcompany;
        encodedJsonValues.employmentdetails.designation = values.designation;
        encodedJsonValues.employmentdetails.currentlocation = values.currentlocaton;
        encodedJsonValues.employmentdetails.currentctc = {};
        encodedJsonValues.employmentdetails.currentctc.totalctc = values.currentctc;
        encodedJsonValues.employmentdetails.noticeperiod = {};
        encodedJsonValues.employmentdetails.noticeperiod.officialnp = values.officialnp; 
        encodedJsonValues.employmentdetails.noticeperiod.isnegotiable = values.isnegotiable;  
        encodedJsonValues.employmentdetails.noticeperiod.negotiableto = values.negotiableto;  
        encodedJsonValues.employmentdetails.noticeperiod.lwd = values.lwd;  
        encodedJsonValues.employmentdetails.noticeperiod.reason = values.reason;  

        encodedJsonValues.educationaldetail ={}; 
        encodedJsonValues.educationaldetail.maxdegree=values.maxdegree;
        encodedJsonValues.educationaldetail.college=values.college;
        encodedJsonValues.educationaldetail.stream=values.stream;;
        encodedJsonValues.educationaldetail.percentage="To DO";
        encodedJsonValues.educationaldetail.passoutyear= "To DO";
        encodedJsonValues.educationaldetail.eductionremarks= "To DO"            

        let newDate = getTimeNow()

        encodedJsonValues.personaldetail ={};
        encodedJsonValues.personaldetail.name= values.name;
        encodedJsonValues.personaldetail.email= values.email;
        encodedJsonValues.personaldetail.mobile= values.mobile;
        encodedJsonValues.personaldetail.aadharid= values.aadharid;
        encodedJsonValues.personaldetail.dob= values.dob;
        encodedJsonValues.personaldetail.gender= values.gender;  
        encodedJsonValues.personaldetail.age= values.age;      
        encodedJsonValues.personaldetail.martialstatus= values.martialstatus;    

        encodedJsonValues.offerdetails={}
        encodedJsonValues.offerdetails.otheroffer = values.otheroffer;
        encodedJsonValues.offerdetails.offerctc = values.ctc;
        encodedJsonValues.offerdetails.offerdoj = values.doj;
        encodedJsonValues.offerdetails.companytype = values.offercompanytype;
        encodedJsonValues.offerdetails.companyname = "ToDO";
       
        return encodedJsonValues
    }


    const handleFormSubmit = (values) => {
        const jasonValues = encodeApplicationJson(values)
        const resumeJasonValues = encodeResumeDetailsJson(values)
        console.log("handle jdid id", jdID, resumeID)
        updateProfileInApplication({
            jasonValues,jdID,resumeID
        });
        updateProfileResumeDetails({
            resumeJasonValues,jdID,resumeID
        });
        message.success("Updated Profile Detials");
    };    

  const handleUpdateFormSubmit = (values) => {
      console.log("called")
      message.success("Job description updated successfully");
  };

  const appvalues ={
    name: selectedProfileResume?.personaldetail.name,
    email: selectedProfileResume?.personaldetail.email,
    mobile: selectedProfileResume?.personaldetail.mobile,
    score: selectedApplication?.resumescore.score,
    skills: selectedApplication?.resumestatus.tags,
    payroll: selectedApplication?.newemploymentdetails.payroll,
    candidatestatus: selectedApplication?.candidatestatus.status,
    appliedbefore: selectedApplication?.candidatestatus.status,
    interviewbefore: selectedApplication?.candidatestatus.status,
    currentlocation: selectedProfileResume?.employmentdetails.currentlocation,
    preferredlocation: selectedProfileResume?.personaldetail.name,
    locationchangereason: selectedProfileResume?.personaldetail.email,
    maxdegree: selectedProfileResume?.personaldetail.mobile,
    stream: selectedProfileResume?.personaldetail.email,
    college: selectedProfileResume?.personaldetail.mobile,
    designation: selectedProfileResume?.employmentdetails.currentcompany,
    experience: selectedProfileResume?.personaldetail.email,
    relevantexperience: selectedApplication?.candidatestatus.status,
    currentcompany: selectedProfileResume?.employmentdetails.currentcompany,
    noticeperiod: selectedProfileResume?.employmentdetails.noticeperiod,
    negotiablenoticeperiod: selectedProfileResume?.personaldetail.mobile,
    relevantexperience: selectedProfileResume?.personaldetail.mobile,
    lwd: selectedProfileResume?.personaldetail.mobile,
    reason: selectedProfileResume?.personaldetail.mobile,
    currentctc: selectedProfileResume?.employmentdetails.currentctc,
    ectc: selectedProfileResume?.personaldetail.mobile,
    ectcoptions: selectedProfileResume?.personaldetail.mobile,
    ctcremarks: selectedProfileResume?.personaldetail.mobile,
    otheroffer: selectedProfileResume?.personaldetail.mobile,
    offercompanytype: selectedProfileResume?.personaldetail.mobile,   
    offerctc: selectedProfileResume?.personaldetail.mobile,
    aadharnumber: selectedProfileResume?.personaldetail.mobile,
    offerremarks: selectedProfileResume?.personaldetail.mobile 
  }

    return (
            <div className="jobApplication-summary-wrapper">
            <div className="jobApplication-header-wrapper">
                   <div className ="jobApplication-heading"> Candidate Interest From {appvalues.name} </div> 
                   <div className="jobApplication-heading-role-desc"> Role </div>
                   <div className="jobApplication-heading-role"> Embedded Firmware Engineer </div>   
                   <div className ="jobApplication-Qh-branding">
                        Autogenerated from &nbsp;
                        <a href="https://quallyhireai.com">Quallyhire.AI</a>
                   </div>
            </div>                      
            <div className="profile-creation-form-wrapper">
                <div className="jobApplication-section-header"> Please provide below details {appvalues.name} </div>                                                                      
            <Form
                key={formKey}
                className="profile-creation-form"
                name="profile-creation-form"
                justify="start"
                layout="vertical"
                autoComplete="off"
                onFinish={value ? handleUpdateFormSubmit : handleFormSubmit}
                initialValues={appvalues}
            >
                <div className="triple-input-wrapper">
                    <Form.Item
                        required={value ? false : true}
                        className="jd-creation-field-item"
                        label="Candidate Name"
                        name="name"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>

                    <Form.Item
                        required={value ? false : true}
                        className="jd-creation-field-item"
                        label="Email ID"
                        name="email"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                    <Form.Item
                            required={value ? false : true}
                            name="mobile"
                            className="jd-creation-field-item"
                            label="Mobile Number"
                        >
                            <Input
                                disabled={!isEditMode}
                                required={value ? false : true}
                                className="jd-creation-input"
                            />
                    </Form.Item>
                </div>

                <div className="triple-input-wrapper">

                    <Form.Item
                        required={value ? false : true}
                        name="candidatestatus"
                        className="jd-creation-field-item"
                        label="Candidate Status"
                    >
                            <Select
                                disabled={!isEditMode}
                                placeholder="Please Select"                                
                                required={value ? false : true}
                                className="jd-creation-input"                            
                                options={[
                                    { value: 'Available', label: 'I am Interested' },
                                    { value: 'Not Interested', label: 'I am Not Interested'},
                                    { value: 'Location Issue', label: 'Location Issue' },
                                ]}
                            />                         
                    </Form.Item>
                    <Form.Item
                        required={isRequired}
                        name="appliedbefore"
                        className="jd-creation-field-item"
                        label="Have you applied before for this role directly or through other agency"
                    >
                        <Radio.Group
                            disabled={!isEditMode}
                            className="jd-creation-radio-wrapper"
                        >
                            <Radio className="radio-option" value={true}>
                                Yes
                            </Radio>
                            <Radio className="radio-option" value={false}>
                                No
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        required={isRequired}
                        name="interviewbefore"
                        className="jd-creation-field-item"
                        label="Have you given interview before"
                    >
                        <Radio.Group
                            disabled={!isEditMode}
                            className="jd-creation-radio-wrapper"
                        >
                            <Radio className="radio-option" value={true}>
                                Yes
                            </Radio>
                            <Radio className="radio-option" value={false}>
                                No
                            </Radio>
                        </Radio.Group>
                    </Form.Item>                                                        
                </div>

                <div className="triple-input-wrapper">
                    <Form.Item
                        required={value ? false : true}
                        name="currentlocation"
                        className="jd-creation-field-item"
                        label="Current Location"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item> 
                    <Form.Item
                        required={value ? false : true}
                        name="currentcompany"
                        className="jd-creation-field-item"
                        label="Current Company"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>

                    <Form.Item
                        required={value ? false : true}
                        name="designation"
                        className="jd-creation-field-item"
                        label="Current Designation"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>

                                        
                </div>

                <div className="triple-input-wrapper">

                    <Form.Item
                        required={value ? false : true}
                        name="currentctc"
                        className="jd-creation-field-item"
                        label="Current CTC (LPA)"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            required={value ? false : true}
                            className="jd-creation-input"
                        />                       
                    </Form.Item> 
                    <Form.Item
                        required={value ? false : true}
                        className="jd-creation-field-item"
                        label="Total Exp "
                        name="experience"
                    >
                    <Space.Compact>
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            placeholder={"Yrs"}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            placeholder={"Months"}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                        </Space.Compact>  
                    </Form.Item>  

                    <Form.Item
                        required={value ? false : true}
                        className="jd-creation-field-item"
                        label="Relevant Exp "
                        name="relevantexperience"
                    >
                        <Space.Compact>
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            placeholder={"Yrs"}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            placeholder={"Months"}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                        </Space.Compact>  
                    </Form.Item>                     
                </div>
                            
                <div className="triple-input-wrapper">                  

                <Form.Item
                        className="jd-creation-field-item"
                        label="LWD"
                        name="lwd"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            placeholder={"dd/mm/yyyy"}
                            className="jd-creation-input"
                        />

                    </Form.Item> 
                    <Form.Item
                        required={value ? false : true}
                        className="jd-creation-field-item"
                        label="Official Notice Period (Days)"
                        name="noticeperiod"
                    >
                    <Select
                            disabled={!isEditMode}
                            placeholder="Please select"                                
                            required={value ? false : true}
                            className="jd-creation-input"                            
                            options={[
                                { value: 0, label: 0 },                                
                                { value: 15, label: 15 },
                                { value: 30, label: 30 },
                                { value: 45, label: 45 },
                                { value: 60, label: 60 },
                                { value: 90, label: 90},
                                { value: 120, label: 120 },
                                { value: 180, label: 180 },
                            ]}
                    />                          
                    </Form.Item>
                    <Form.Item
                        required={value ? false : true}
                        className="jd-creation-field-item"
                        label="Negotiable Notice Period (Days)"
                        name="negotiablenoticeperiod"
                    >
                    <Select
                            disabled={!isEditMode}
                            placeholder="Please select"                                
                            required={value ? false : true}
                            className="jd-creation-input"                            
                            options={[
                                { value: 0, label: 0 },
                                { value: 5, label: 5 },
                                { value: 10, label: 10 },
                                { value: 15, label: 15 },
                                { value: 30, label: 30 },
                                { value: 45, label: 45 },
                                { value: 60, label: 60 },
                                { value: 90, label: 90},
                                { value: 120, label: 120 },
                                { value: 180, label: 180 },
                            ]}
                    /> 
                    </Form.Item>                    
                </div>

                <div className="triple-input-wrapper">
                <Form.Item
                        required={value ? false : true}
                        name="locationchangereason"
                        className="jd-creation-field-item"
                        label="Relocation Reason"
                    >
                            <Select
                                disabled={!isEditMode}
                                placeholder="Please Select"                                
                                required={value ? false : true}
                                className="jd-creation-input"                            
                                options={[
                                    { value: 'NA', label: 'NA' },
                                    { value: 'Partner Job', label: 'Partner Job' },
                                    { value: 'Family Member Lives there', label: 'Family Member Lives there' },
                                    { value: 'Carrer Growth', label: 'Carrer Growth' },
                                    { value: 'Other Reasons', label: 'Other Reasons'},
                                ]}
                            />                         
                    </Form.Item>

                    <Form.Item
                        required={value ? false : true}
                        className="jd-creation-field-item"
                        label="Reason for Leaving "
                        name="reason"
                    >
                    <Select
                            disabled={!isEditMode}
                            placeholder="Please select"                                
                            required={value ? false : true}
                            className="jd-creation-input"                            
                            options={[
                                { value: "No Project,On Bench", label: "No Project,On Bench"},                                
                                { value: "LayOff", label: "LayOff" },
                                { value: "Financial Growth", label: "Financial Growth" },
                                { value: "No JobSatisfaction", label: "No JobSatisfaction" },
                                { value: "Poor Work Environment", label: "Poor Work Environment" },
                                { value: "Persoanl Reason", label: "Persoanl Reason"},
                                { value: "To Take care of family", label: "To Take care of family" },
                                { value: "Othe reasons" ,label: "Othe reasons" },
                            ]}
                    />                          
                    </Form.Item> 
                                                 
                </div>                
                <div className="triple-input-wrapper">
                    <Form.Item
                        required={value ? false : true}
                        name="ectc"
                        className="jd-creation-field-item"
                        label="ECTC (LPA)"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            required={value ? false : true}
                            className="jd-creation-input"
                        />                       
                    </Form.Item>                                                        
                    <Form.Item
                        required={isRequired}
                        name="ectcoptions"
                        className="jd-creation-field-item"
                        label="ECTC Options"
                    >
                        <Radio.Group
                            disabled={!isEditMode}
                            className="jd-creation-radio-wrapper-3"
                        >
                            <Radio className="radio-option" value={"Negotiable"}>
                                Negotiable
                            </Radio>
                            <Radio className="radio-option" value={"Fixed"}>
                                Fixed
                            </Radio>                            
                            <Radio className="radio-option" value={"Will Discuss with HR"}>
                                Will discuss with HR
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        required={isRequired}
                        name="otheroffer"
                        className="jd-creation-field-item"
                        label="Other offers"
                    >
                        <Radio.Group
                            disabled={!isEditMode}
                            className="jd-creation-radio-wrapper-4"
                        >
                            <Radio className="radio-option" value={"No Offer"}>
                                No Offer
                            </Radio>                           
                            <Radio className="radio-option" value={"One Offer"}>
                                One Offer
                            </Radio>
                            <Radio className="radio-option" value={"Multiple Offer"}>
                                Multiple offer
                            </Radio>
                            <Radio className="radio-option" value={"In Pipeline"}>
                                In Pipeline
                            </Radio>                            
                        </Radio.Group>
                    </Form.Item>                     
                </div>
                  

                <Button
                    style={{ width: "100%", height: "40px", fontWeight: "600" }}
                    type="primary"
                    disabled={!isEditMode}
                    htmlType="submit"
                >
                    Submit Details
                </Button>
            </Form>         
            </div>

            <CandidateAddModal />

            <div className="jobApplication-footer">
                Disclaimer: Submission of this form does not guarantee job for this position. This form is just for collection of your information
                and submitting your profile for this position. Based on the information provided, your profile will be screened from the recruiter.
                <br></br>
                <a href="https://quallyhireai.com">Quallyhire.AI</a> &nbsp; &copy;2025
            </div>
        </div>
    );
}
