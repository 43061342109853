import React, { useEffect,useState } from "react";
import { Button, Empty,Select } from "antd";
import { useNavigate } from "react-router-dom";

import useStatsStore from "../../store/stats.store";
import useMembersStore from "../../store/members.store";


import "./jds.scss";
import TeamAssignmentCard from "../TeamAssignmentCard";

export default function JDs(props) {
  console.log(props);


  const status = localStorage.getItem("status");

  const { getAllJdsInternalConsolidatedStats ,allJdsInternalConsolidatedStats } = useStatsStore();
  const { getClients,clients} =  useMembersStore();

  const [filterParamPrio, setFilterPrio] = useState("all");
  const [filterParamJobStatus, setFilterJobStatus] = useState("active");
  const [filterParamClient, setFilterClient] = useState("all");
  const [filterParamTA, setFilterTA] = useState("all");
  const [clientList, setClientList] = useState();
  const [filterAppNum, setAppNum] = useState("all");
  const [filterDuration, setDuration] = useState("all");

  const ClientAll = { value: 'all', label: 'All' }

  var clientInDB = clients?.map((client, idx) => {
    return {
      value:client?.ClientName,
      label:client?.ClientName,
    };
  })

  const handleDuration =(value)=>{
    setDuration(value)
  }
  const handleNumberApplications =(value)=>{
    setAppNum(value)
  }
  const handleFilterJDPriority =(value)=>{
    setFilterPrio(value)
  }
  const handleFilterJDstatus =(value)=>{
    setFilterJobStatus(value)
  }
  const handleFilterCustomers =(value)=>{
    setFilterClient(value)
  }
  const handleFilterTA =(value)=>{
    setFilterTA(value)
  }

  const handleUpdateFilter =() =>{
    let encodedJsonValues ={};
    encodedJsonValues.prio = filterParamPrio;
    encodedJsonValues.jobstatus = filterParamJobStatus;
    encodedJsonValues.client = filterParamClient;
    encodedJsonValues.ta = filterParamTA;
    encodedJsonValues.appnum = filterAppNum;

    getAllJdsInternalConsolidatedStats(filterDuration,encodedJsonValues)
  }

  useEffect(() => {
    getClients()
    clientInDB?.push(ClientAll)
    console.log("clientList", clientList)
    console.log("clientInDB", clientInDB)
    console.log("ClientAll", ClientAll)
    setClientList([...clientInDB])
    handleUpdateFilter()
  }, []);

  return (
    <div className="teamTask-wrapper">
      <div className="teamTask-title"> {allJdsInternalConsolidatedStats?.JDListInternalActivity?.length} Jobs Found</div>
      <div>
        <div className="teamTask-filter">
        <div className="teamTask-filter-item-wrapper">
            <div className="teamTask-filter-item-label"> 
          
            </div>
            <div className="teamTask-filter-item"
                 style={{margin: "10px"}}>
              <Button
                onClick={handleUpdateFilter}
              >
                Update Now
              </Button>
            </div>
          </div>
          <div className="teamTask-filter-item-wrapper">
            <div className="teamTask-filter-item-label">
              Customers
            </div>
            <div className="teamTask-filter-item">
              <Select
                style={{ width: 130,height: 24 ,textAlign:"left"}}
                onChange={handleFilterCustomers}
                showSearch={true}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}  
                placeholder= "All"                                                     
                options={clientList}
              />
            </div>
          </div>
          <div className="teamTask-filter-item-wrapper">
            <div className="teamTask-filter-item-label">
              JD Status
            </div>
            <div className="teamTask-filter-item">
              <Select
                style={{ width: 130,height: 24 }}
                onChange={handleFilterJDstatus}
                showSearch={true}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}                  
                placeholder= "Active"                                               
                options={[
                          { value: "active", label: 'Active' },
                          { value: "closed", label: 'Closed' },
                          { value: "hold", label: 'Hold' }, 
                          { value: "wait_fb", label: 'Temp Hold' },                                                               
                        ]}
              /> 
            </div>
          </div>
          <div className="teamTask-filter-item-wrapper">
            <div className="teamTask-filter-item-label">
              Priority
            </div>
            <div className="teamTask-filter-item">
              <Select
                style={{ width: 130,height: 24 }}
                onChange={handleFilterJDPriority}
                showSearch={true}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}                  
                placeholder={"All"}                                                  
                options={[
                          { value: "all", label: 'All' },
                          { value: "high", label: 'High' },
                          { value: "urgent", label: 'Urgent' },
                          { value: "todays", label: 'Todays' },  
                          { value: "low", label: 'Low' },
                          { value: "normal", label: 'Normal' },                                                                
                        ]}
              /> 
            </div>
          </div>
          <div className="teamTask-filter-item-wrapper">
            <div className="teamTask-filter-item-label">
              Resumes
            </div>
            <div className="teamTask-filter-item">
              <Select
                style={{ width: 130,height: 24 }}
                onChange={handleNumberApplications}
                showSearch={true}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}                  
                placeholder={"All"}                                                  
                options={[
                          { value: "all", label: 'All' },
                          { value: "yes", label: 'Present' },
                          { value: "no", label: 'Not Present' },                                                               
                        ]}
              /> 
            </div>
          </div>
          <div className="teamTask-filter-item-wrapper">
            <div className="teamTask-filter-item-label">
              Duration
            </div>
            <div className="teamTask-filter-item">
              <Select
                style={{ width: 130,height: 24 }}
                onChange={handleDuration}
                showSearch={true}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}                  
                placeholder={"All"}                                                  
                options={[
                          { value: "all", label: 'All' },
                          { value: "24", label: 'Last 24 hrs' },
                          { value: "168", label: 'last 7 days' },   
                          { value: "720", label: 'last 30 days' },                                                               
                        ]}
              /> 
            </div>
          </div>    

        </div>
            
      </div>      
      {allJdsInternalConsolidatedStats?.JDListInternalActivity?.length > 0 ? (
      <>
              {allJdsInternalConsolidatedStats?.JDListInternalActivity?.map((jd, idx) => (
                    <TeamAssignmentCard data={jd} index= {idx}/>
              ))}
      </>
      ) : (
        <Empty style={{ margin: "60px" }} />
      )}
    </div>
  );
}
