import { create } from "zustand";

import axios from "../helper/axios";

var userData = JSON.parse(localStorage.getItem("userDetails"));

const useStatsStore = create((set) => ({
    graphsGroupUserStats: null,
    unreadNotifications: 0,
    newNotificationIndices: null,
    teamsDashConsolidatedStats: null,
    teamsInternalConsolidatedStats:null,
    jdsConsolidatedStats:null,
    allJdsInternalConsolidatedStats:null,
    myDashConsolidatedStats: null,
    myInternalConsolidatedStats:null,
    interviewSchedules:null,
    autoResumeMatchingStats: null,
      
    getGraphsGroupUserStats: async (duration,data) => {
        try {
           userData = JSON.parse(localStorage.getItem("userDetails"));
           console.log(data);
           const response = await axios.post(`/getGrpahGroupActivity/${userData.account_type}/${userData.qhoid}/${userData.qhuid}/${duration}`,data);
           console.log(response.data.name);  
           console.log(response.data.data);

            const newStats = response?.data?.data;

            set((state) => {
        if (newStats?.useractivity === null) {
          return {
            graphsGroupUserStats: newStats,
          };
        }

                const changedIndicesSet = new Set(state.newNotificationIndices || []);

                newStats?.useractivity?.forEach((newObj, index) => {
                    const oldObj = state.graphsGroupUserStats?.useractivity[index];
                    if(!oldObj || JSON.stringify(newObj) !== JSON.stringify(oldObj)){
                        console.log(index);
                        changedIndicesSet.add(index);
                    }
                });
                console.log(changedIndicesSet, "this is changedIndicesSet");

        const hasChanged =
          JSON.stringify(state.graphsGroupUserStats?.useractivity) !==
          JSON.stringify(newStats?.useractivity);
        return {
          newNotificationIndices: changedIndicesSet,
          graphsGroupUserStats: newStats,
          unreadNotifications: hasChanged
            ? state.unreadNotifications + 1
            : state.unreadNotifications,
        };
      });
    } catch (err) {
      console.error(err);
    }
  },
  getTeamsDashBoardConsolidatedStats: async (jdid, id) => {
    try {
      userData = JSON.parse(localStorage.getItem("userDetails"));
      const response = await axios.get(
        `/getTeamDashBoardConsolidated/${userData.account_type}/${userData.qhoid}/${userData.qhuid}`
      );
      console.log(response.data.name);
      console.log(response.data.data);
      set({ teamsDashConsolidatedStats: response?.data.data });
    } catch (err) {
      console.error(err);
    }
  },

    getTeamsInternalConsolidatedStats: async (duration) => {
        try {
           userData = JSON.parse(localStorage.getItem("userDetails"));
           const response = await axios.get(`/getTeamInternalConsolidated/${userData.account_type}/${userData.qhoid}/${userData.qhuid}/${duration}`);
            console.log(response.data.name); 
            console.log(response.data.data);
            set({ teamsInternalConsolidatedStats: response?.data.data });
        } catch (err) {
            console.error(err);
        }
    }, 
    getAllJdsInternalConsolidatedStats: async (duration,data) => {
        try {
           userData = JSON.parse(localStorage.getItem("userDetails"));
           console.log(data);
           const response = await axios.post(`/getAllJdsInternalConsolidated/${userData.account_type}/${userData.qhoid}/${userData.qhuid}/${duration}`,data);
           console.log(response.data.name); 
           console.log(response.data.data);
           set({ allJdsInternalConsolidatedStats: response?.data.data });
        } catch (err) {
            console.error(err);
        }
    },     
    getJdsConsolidatedStats: async (data) => {
        try {
           userData = JSON.parse(localStorage.getItem("userDetails"));
           console.log(data);
           const duration = "all"
           const response = await axios.post(`/getJdsConsolidated/${userData.account_type}/${userData.qhoid}/${userData.qhuid}/${duration}`,data);
           console.log(response.data.name); 
           set({ jdsConsolidatedStats: response?.data.data });
           console.log(response.data.data);
        } catch (err) {
            console.error(err);
        }
    },
    getMyDashBoardConsolidatedStats: async (jdid,id) => {
        try {
           userData = JSON.parse(localStorage.getItem("userDetails"));
           const response = await axios.get(`/getMyDashBoardConsolidated/${userData.account_type}/${userData.qhoid}/${userData.qhuid}`);
            console.log(response.data.name); 
            console.log(response.data.data);
            set({ myDashConsolidatedStats: response?.data.data });
        } catch (err) {
            console.error(err);
        }
    },
    getMyInternalConsolidatedStats: async (jdid,id) => {
        try {
           userData = JSON.parse(localStorage.getItem("userDetails"));
           const duration = "24"
           const response = await axios.get(`/getMyInternalConsolidated/${userData.account_type}/${userData.qhoid}/${userData.qhuid}/${duration}`);
           console.log(response.data.name); 
           console.log(response.data.data);
           set({ myInternalConsolidatedStats: response?.data.data });
        } catch (err) {
            console.error(err);
        }
    },
    
    getInterviewSchedule: async () => {
        try {
           userData = JSON.parse(localStorage.getItem("userDetails"));
           const response = await axios.get(`/getInterviewSchedule/${userData.account_type}/${userData.qhoid}/${userData.qhuid}`);
           console.log(response.data.name); 
            console.log(response.data.data);
            set({ interviewSchedules: response?.data.data });
        } catch (err) {
            console.error(err);
        }
    },

    
    getAutoResumeMatching: async (jdid,duration,data) => {
        try {
           userData = JSON.parse(localStorage.getItem("userDetails"));
           console.log(data);
           const response = await axios.post(`/autoresumematching/${userData.account_type}/${userData.qhoid}/${userData.qhuid}/${jdid}/${duration}`,data);
           console.log(response.data.name);  
           console.log(response.data.data);
           set({autoResumeMatchingStats: response?.data.data });
        } catch (err) {
            console.error(err);
        }
    },      
    markNotificationAsRead: () => set({ unreadNotifications: 0 }),

    clearNotificationIndices: () => set({ newNotificationIndices: null }),

}));

export default useStatsStore;
