import React, { useState } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { actions } from "../../actions";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Empty, Card,Carousel } from "antd";
import workflow from "../../assets/landingFeatures/workflow.png";
import explorer from "../../assets/landingFeatures/explorer.png";
import vendor from "../../assets/landingFeatures/vendorconnect.png";
import qhrflow from "../../assets/landingFeatures/qhrFlow.png";

import "./features.css";


function Features (props)  {
  const [loader, SetLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [error,setError] = useState("")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleSubmit } = props;
  const { Meta } = Card;
  const contentStyleQhrFlow = {
   margin:20,
   padding:"5px",
   height: "300px",
   width:"auto",
   background: "#d0ece7 "
  };   
  
  const contentStyle = {
   margin:20,
   padding:"5px",
   height: "300px",
   width:"auto",
   background: "#f5eef8"
  }; 
  
  const contentStyleJobListing = {
   margin:20,
   padding:"5px",
   height: "300px",
   width:"auto",
   background: " #c2d1f0"
  };  

  const contentStyleERL = {
   margin:20,
   padding:"5px",
   height: "300px",
   width:"auto",
   background: "#f2d7d5"
  }; 
  const onSubmit = () => {
    console.log("on submit called");
    let dataToSend = { email: email };
    let inputData = { email: email };
    console.log("email", email);
    SetLoader(true);

    dispatch(actions.SignIn(dataToSend, (error, status) => {
      if (!error) {
        navigate("/otp", { state: { inputData, type: "login" } });
        SetLoader(false);
      } else {
        // Handle the error case
        SetLoader(false);
        console.error("API Error:", error);
        let e = error.split("or")
        setError(e[0] + "signup first")
       // setErrorMessage(error); // Set the error message in state
      }
    }));
  };

  const handleSignin = () => {
    navigate('/login')
  };

  const handleSignup = () => {
    navigate('/signup')
  };

  const handleClick = () => {

 };

const settings = {
   dots: false,
   speed: 17000,
   slidesToShow: 5,
   slidesToScroll: 1,
   infinite: true,
   autoplay: true,
   autoplaySpeed: 100,
 };

 const settingsJobListing = {
   dots: false,
   speed: 17000,
   slidesToShow: 5,
   slidesToScroll: 1,
   infinite: true,
   autoplay: true,
   autoplaySpeed: 100,
 };

 const settingsERL = {
    dots: false,
    speed: 10000,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10,
  };
  return (
     <div className="features-wrapper">
        <div className="feature-heading">
           Products and Features
        </div>
        <div className="feature-subheading">
           QuallyHire ATS and WorkFlow Tool 
        </div>
        <div className="feature-contents" style = {{color:"#464948"}}>
           Whether you're a recent graduate, an experienced professional, or looking for a career change, we have the resources to help you succeed.
        </div>
        <div className="feature-img2-wrapper"> 
            <div className="feature-card" style = {{background:"#d0ece7"}}>
                QHRFlow
            </div>
            <div>
                <img
                        src={qhrflow}
                        alt="Explorer"
                        width="200" height="200" 
                /> 
            </div>            
        </div>
                
        <div className="feature-carousil-wrapper">
           <div className="container">
                 <Slider {...settings}>
                     <div>
                     <Card style ={contentStyleQhrFlow} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Work Flow" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Software solution designed to automate and optimize recruitment processes.
                         </div>                      
                         
                     </Card>
                     </div>
                     <div>
                     <Card style ={contentStyleQhrFlow} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Task Management" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Manage Teams productivity and tasks
                         </div>                                               
                     </Card>                     
                     </div>
                     <div>
                     <Card style ={contentStyleQhrFlow} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Improved ATS" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              AI Enabled Application tracking System
                         </div>                                               
                     </Card>                      
                     </div>
                     <div>
                     <Card style ={contentStyleQhrFlow} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Bulk Communications" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Communicate to Multiple Candidates at once.
                         </div>                                               
                     </Card>                      
                     </div>
                     <div>
                     <Card style ={contentStyleQhrFlow} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Bulk Forms" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Send Any Forms to Multiple Candidates at once.
                         </div>                                               
                     </Card>                      
                     </div>
                     <div>
                     <Card style ={contentStyleQhrFlow} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Bulk Evaluations" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Evaluate Multiple Candidates at once.
                         </div>                                               
                     </Card>                      
                     </div>
                     <div>
                     <Card style ={contentStyleQhrFlow} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Interview Management" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Interview Scheduling and Reminders
                         </div>                                               
                     </Card>                      
                     </div>                                            
                 </Slider>
           </div>
        </div>

        <div className="feature-subheading" style = {{color:"#555758"}}>
           QHRFlow Vendor Management Tool
        </div>
        <div className="feature-contents" style = {{color:"#464948"}}>
           Whether you're a recent graduate, an experienced professional, or looking for a career change, we have the resources to help you succeed.
        </div>
        <div className="feature-img2-wrapper"> 
            <div className="feature-card" style = {{background:"#f5eef8"}}>
                QHRFlow VendorConnect
            </div>
            <div>
                <img
                        src={vendor}
                        alt="Explorer"
                        width="200" height="200" 
                /> 
            </div>            
        </div>        
        <div className="feature-carousil-wrapper">
           <div className="container">
                 <Slider {...settings}>
                     <div>
                     <Card style ={contentStyle} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Vendor Management" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Software solution designed to automate and optimize recruitment processes.
                         </div>                      
                         
                     </Card>
                     </div>
                     <div>
                     <Card style ={contentStyle} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Resume Quality Analysis" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Manage Teams productivity and tasks
                         </div>                                               
                     </Card>                     
                     </div>
                     <div>
                     <Card style ={contentStyle} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Improved ATS" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              AI Enabled Application tracking System
                         </div>                                               
                     </Card>                      
                     </div>
                     <div>
                     <Card style ={contentStyle} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Bulk Communications" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Communicate to Multiple Candidates at once.
                         </div>                                               
                     </Card>                      
                     </div>                    
                 </Slider>
           </div>
        </div>

        <div className="feature-subheading" style = {{color:"#555758"}}>
            Job Portal
        </div>
        
        <div className="feature-contents" style = {{color:"#464948"}}>
           Whether you're a recent graduate, an experienced professional, or looking for a career change, we have the resources to help you succeed.
        </div>
        <div className="feature-img2-wrapper"> 
            <div className="feature-card" style = {{background:"#c2d1f0",marginRight:50}}>
                Quallyhire Explorer
            </div>
            <div>
                <img
                        src={explorer}
                        alt="Explorer"
                        width="150" height="150" 
                /> 
            </div>            
        </div>
        <div className="feature-carousil-wrapper">
           <div className="container">
                 <Slider {...settingsJobListing}>
                     <div>
                     <Card style ={contentStyleJobListing} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Post, Search and Apply" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                                Browse through a vast database of jobs, from entry-level positions to executive roles
                         </div>                      
                         
                     </Card>
                     </div>
                     <div>
                     <Card style ={contentStyleJobListing} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Multiple Categories" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                            Jobs across Multiple Domains and Categories  
                         </div>                                               
                     </Card>                     
                     </div>
                     <div>
                     <Card style ={contentStyleJobListing} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="ATS Ready" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                            Works with or without QHRFlow ATS
                         </div>                                               
                     </Card>                     
                     </div>   
                     <div>
                     <Card style ={contentStyleJobListing} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Professional Profile" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                            Create your professional profile, do self assessment and be interview ready  
                         </div>                                               
                     </Card>                     
                     </div>                       
                     <div>
                     <Card style ={contentStyleJobListing} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Job Alerts" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                            Register and Get notified of new job openings that match your interests  
                         </div>                                               
                     </Card>                     
                     </div>                                                                                 
                 </Slider>
           </div>
        </div>

        <div className="feature-subheading" style = {{color:"#555758"}}>
           QuallyHire Employee Reigster and Leave management Tool
        </div>
        <div className="feature-contents" style = {{color:"#464948"}}>
           Whether you're a recent graduate, an experienced professional, or looking for a career change, we have the resources to help you succeed.
        </div>
        <div className="feature-img2-wrapper"> 
            <div className="feature-card" style = {{background:"#f2d7d5"}}>
                Quallyhire ERL
            </div>           
        </div>
        <div className="feature-carousil-wrapper">
           <div className="container">
                 <Slider {...settingsERL}>
                     <div>
                     <Card style ={contentStyleERL} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Employee Time Sheet" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                             Capture daily/monthly/yearly Login/Logout details of employees
                         </div>                      
                         
                     </Card>
                     </div>
                     <div>
                     <Card style ={contentStyleERL} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Leaves Management" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Leave Management System without Payroll
                         </div>                                               
                     </Card>                     
                     </div>
                     <div>
                     <Card style ={contentStyleERL} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Holidays" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Editable Holiday List
                         </div>                                               
                     </Card>                     
                     </div> 
                     <div>
                     <Card style ={contentStyleERL} hoverable bordered={true}cover={<img style = {{marginLeft:"20px",marginTop:"30px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Geo Tags" description="www.instagram.com/demo" />  
                         <div style = {{marginTop:10}}>
                              Geoloaction Tagging
                         </div>                                               
                     </Card>                     
                     </div>                                                           
                 </Slider>
           </div>
        </div>

        <div className="feature-endHeading-wrapper">
            <div className="feature-endHeading">
                Experience the <span style={{background:"#f4d03f", padding:"10px",fontWeight:"300"}}> difference</span> 
                <br> 
                </br>
                    Try <span style={{background:"#f4d03f", padding:"10px",fontWeight:"500"}}> Quallyhire AI Product Suits</span>
            </div>

            <div className="feature-Button-wrapper">
                <Button type="primary" shape="round" size="large">
                    Talk to Sales 
                </Button>
            </div> 

        </div>
                                                                              
     </div>
  );
};

export default Features;
