import React, { useState,useRef } from "react";
import { Empty, Input, Button } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import editIcon from "../../assets/edit.svg";
import PieChart from "../PieChart";
import StackChart from "../StackChart";

import "./rtable.scss";


export default function RTable({
    isClickable = false,
    titleList,
    data,
    isResumePage = false,
    isEditField = false,
    editFieldHandler,
    setCurrentView,
    resumeProfileHandler,
    jdID, 
    emptyString
}) {
    const navigate = useNavigate();
    const [isEditActive, setIsEditActive] = useState(false);
    const [activeFieldID, setActiveFieldID] = useState();
    const [activeRowID, setActiveRowID] = useState();
    const [editInput, setEditInput] = useState(""); // State to hold input value
    const [editInputKey, setEditInputKey] = useState("");
    const inputRef = useRef(null);


    console.log(" In RTable data",data)
    if (!data) {
        return <Empty style={{ margin: "60px" }}>
                 {emptyString}
        </Empty> ;
    }

    const focusInputField = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    const handleResumeDetailClick = (id,qhID) => {
        resumeProfileHandler(jdID,id, qhID,)
        setCurrentView("profile")
        navigate(`candidateProfile/${id}/${qhID}`, { state: { jdID: jdID } });
        
    };
   const handleResumeStatsClick = (id)=>{
    navigate(id)
   }
    const handleEditBtnClick = (id, rowId) => {
        setIsEditActive(true);
        setActiveFieldID(id);
        setActiveRowID(rowId);
        focusInputField();
    };

    const handleFieldEditConfirm = (id) => {
        setIsEditActive(false);
        setEditInput("");;
        editFieldHandler(editInputKey, editInput, id);
    };
    const handleFieldEditCancel = () => {
        setIsEditActive(false);
        setEditInput("");
    };

    const handleEditInputChange = (name, value) => {
        setEditInput(value);
        setEditInputKey(name);
    };

    const showPieChart = (tableInfo) =>{
        if( tableInfo === ""){
            return false
        }
        else{
            return false
        }
    }
    const EditInputField = ({ tableInfo, table }) => (
        <div className="edit-field-active">
            <Input
                value={editInput}
                onChange={(event) =>
                    handleEditInputChange(tableInfo, event.target.value)
                }
                className="field-input"
            />
            <div className="btn-wrapper">
                <Button
                    type="primary"
                    className="btn-confirm"
                    onClick={() => handleFieldEditConfirm(table.id)}
                >
                    <CheckOutlined />
                </Button>
                <Button
                    danger
                    className="btn-cancel"
                    onClick={() => handleFieldEditCancel()}
                >
                    <CloseOutlined />
                </Button>
            </div>
        </div>
    );

    return (
        <div className="rtable-wrapper">
            <div
                className="rtable-title-wrapper"
                style={{
                    gridTemplateColumns: `50px 1fr 2fr repeat(${titleList.length-3}, 1fr)`
                }}
            >
                {titleList.map((title) => (
                    <div className="rtable-title">{title}
                    </div>
                ))}
            </div>
            {data?.map((table, rowIndex) => (
                <div
                    onClick={isResumePage ? () => handleResumeDetailClick(table.id, table.qhid) : () => handleResumeStatsClick(table.team_member_id ) }
                    key={table.id}
                    className="rtable-info-wrapper"
                    style={{
                        gridTemplateColumns: `50px 1fr 2fr repeat(${titleList.length-3}, 1fr)`,
                        cursor: isClickable && "pointer",
                    }}
                >
                    {Object.keys(table)?.map((tableInfo, idx) => (
                        <div className="rtable-info">
                            {isEditField ? (
                                <div className="edit-field-wrapper">
                                    {isEditActive &&
                                    idx === activeFieldID &&
                                    rowIndex === activeRowID ? (
                                        <EditInputField
                                            tableInfo={tableInfo}
                                            table={table}
                                        />
                                    ) : (
                                        <>
                                            {tableInfo === "id"
                                                ? rowIndex + 1
                                                : table[tableInfo]}

                                            {tableInfo === "downloadResume" ||
                                            tableInfo === "id" ||
                                            tableInfo === "share_to_client" ? (
                                              <></>
                                            ) :
                                            showPieChart(tableInfo) ? (
                                                <StackChart stackData={null} />
                                            ) :                                           
                                            (
                                                <img
                                                    className="edit-icon"
                                                    src={editIcon}
                                                    alt="edit"
                                                    onClick={() =>
                                                        handleEditBtnClick(
                                                            idx,
                                                            rowIndex
                                                        )
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            ) : (
                                table[tableInfo]==""?"--":table[tableInfo]
                            )}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}
