import { create } from "zustand";

import axios from "../helper/axios";

var userData = JSON.parse(localStorage.getItem("userDetails"));

const useSuperUserInfoStore = create((set) => ({
    superUserInfo: null,
    superAdminInfo:null,
    superUserUpdateflag:true,

    resetSuperUserUpdateFlag: () => {
        set({ superUserUpdateflag: false });
    },
    getSuperUserInfo: async (duration) => {
        try {
           userData = JSON.parse(localStorage.getItem("userDetails"));
           const response = await axios.get(`/getSuperuserInfo/${userData.qhoid}/${userData.qhuid}/${duration}`);
            console.log(response.data.name); 
            console.log(response.data.data);
            set({ superUserInfo: response.data.data});
        } catch (err) {
            console.error(err);
        }
    }, 
 
    updateSuperUserSubscriptionStatus: async (body) => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));
            console.log(body);
            const response = await axios.post(
                `/candidatestatus/${userData.qhoid}/${userData.qhuid}/jds/${body.qhjid}`,
                body.jasonValues
            );
            console.log(response.data);
        } catch (err) {
            console.error(err);
        }
    },   
    updateSuperUserOfferPlans: async (body) => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));
            console.log(body);
            const response = await axios.post(
                `/updateSuperuserQhrPlan/${userData.qhoid}/${userData.qhuid}`,
                body.jasonValues
            );
            console.log(response.data.name); 
            console.log(response.data.status);
        } catch (err) {
            console.error(err);
        }
    }, 
    updateSuperUserDocumentStatus: async (body) => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));
            console.log(body);
            const response = await axios.post(
                `/updateSuperuserDocumentStatus/${userData.qhoid}/${userData.qhuid}`,
                body.jasonValues
            );
            console.log(response.data.name); 
            console.log(response.data.status);
            set({ superUserUpdateflag: true});
        } catch (err) {
            console.error(err);
        }
    },   
    
    getSuperAdminInfo: async (duration) => {
        try {
           userData = JSON.parse(localStorage.getItem("userDetails"));
           const response = await axios.get(`/getSuperadminInfo/${userData.qhoid}/${userData.qhuid}/${duration}`);
            console.log(response.data.name); 
            console.log(response.data.data);
            set({ superAdminInfo: response.data.data});
        } catch (err) {
            console.error(err);
        }
    }, 
}));

export default useSuperUserInfoStore;
