import React, { useState,useRef } from "react";
import { Empty, Input, Button } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router-dom";

import editIcon from "../../assets/edit.svg";
import PieChart from "../PieChart";
import StackChart from "../StackChart";

import "./customerTable.scss";

export default function CustomerTable({
    isClickable = false,
    titleList,
    data,
    isJDPage = false,
    isEditField = false,
    editFieldHandler,
}) {
    const navigate = useNavigate();

    const [isEditActive, setIsEditActive] = useState(false);
    const [activeFieldID, setActiveFieldID] = useState();
    const [activeRowID, setActiveRowID] = useState();
    const [editInput, setEditInput] = useState(""); // State to hold input value
    const [editInputKey, setEditInputKey] = useState("");
    const inputRef = useRef(null);
    console.log(data)
    if (!data) {
        return <Empty style={{ margin: "60px" }} />;
    }
    const focusInputField = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    const handleJDClick = (name) => {
        navigate(`/specificCustomer/${name}`);
    };
  const handleResumeStatsClick = (id) => {};
    const handleEditBtnClick = (id, rowId) => {
        setIsEditActive(true);
        setActiveFieldID(id);
        setActiveRowID(rowId);
        focusInputField();
    };

    const handleFieldEditConfirm = (id) => {
        setIsEditActive(false);
    setEditInput("");
        editFieldHandler(editInputKey, editInput, id);
    };
    const handleFieldEditCancel = () => {
        setIsEditActive(false);
        setEditInput("");
    };

    const handleEditInputChange = (name, value) => {
        setEditInput(value);
        setEditInputKey(name);
    };

    const showPieChart = (tableInfo) =>{
        if( tableInfo === "available_resumes"){
            return true
        }
        else{
            return false
        }
    }    
    const EditInputField = ({ tableInfo, table }) => (
        <div className="edit-field-active">
            <Input
                value={editInput}
                onChange={(event) =>
                    handleEditInputChange(tableInfo, event.target.value)
                }
                className="field-input"
            />
            <div className="btn-wrapper">
                <Button
                    type="primary"
                    className="btn-confirm"
                    onClick={() => handleFieldEditConfirm(table.id)}
                >
                    <CheckOutlined />
                </Button>
                <Button
                    danger
                    className="btn-cancel"
                    onClick={() => handleFieldEditCancel()}
                >
                    <CloseOutlined />
                </Button>
            </div>
        </div>
    );

  function capitalizeFirstLetter(str) {
    if (!str) return ""; // Check if the string is empty or null
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
    return (
        <div className="table-wrapper">
            <div
                className="table-title-wrapper"
                style={{
                    gridTemplateColumns: `50px 2fr 2fr 2fr 2fr repeat(${titleList.length-5}, 2fr)`
                }}
            >
                {titleList.map((title) => (
                    <div className="table-title">{title}</div>
                ))}
            </div>
            {data?.map((table, rowIndex) => (
                <div
                    onClick={isClickable ? () => handleJDClick(table.name) : () => handleResumeStatsClick(table.name)  }
                    key={table.id}
                    className="table-info-wrapper"
                    style={{
                        gridTemplateColumns: `50px 2fr 2fr 2fr 2fr repeat(${titleList.length-5}, 2fr)`,
                        cursor: isClickable && "pointer",
                    }}
                >
                    {Object.keys(table)?.map((tableInfo, idx) => (
                        <div className="table-info">
                            {isEditField ? (
                                <div className="edit-field-wrapper">
                                    {isEditActive &&
                                    idx === activeFieldID &&
                                    rowIndex === activeRowID ? (
                                        <EditInputField
                                            tableInfo={tableInfo}
                                            table={table}
                                        />
                                    ) : (
                                        <>
                                            {tableInfo === "id"
                                                ? rowIndex + 1
                                                : table[tableInfo]}
                                            {tableInfo === "downloadResume" ||
                                            tableInfo === "id" ||
                                            tableInfo === "share_to_client" ? (
                                                <></>
                                            ) :
                                            showPieChart(tableInfo) ? (
                                                <div className="stackChart-wrapper">
                                                      <StackChart stackData={null} stack = {true} />
                                                </div>
                                               
                                            ) :                                                
                                            (
                                                <img
                                                    className="edit-icon"
                                                    src={editIcon}
                                                    alt="edit"
                                                    onClick={() =>
                                                        handleEditBtnClick(
                                                            idx,
                                                            rowIndex
                                                        )
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
              ) : showPieChart(tableInfo) ? (
                                    <div className="stackChart-wrapper">
                                        <StackChart stackData={null} stack = {true} />
                                    </div>
              ) : typeof table[tableInfo] === "string" &&
                tableInfo !== "email" ? (
                tableInfo === "role" ? (
                  table[tableInfo].toUpperCase()
                ) : (
                  capitalizeFirstLetter(table[tableInfo])
                )
              ) : (
                table[tableInfo]
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
