import axios from "../helper/axios";
import { create } from "zustand";

const useJDStore = create((set) => ({
    jdData: [],
    selectedJD: null,
    jdCreationError: null,
    isJDCreated: false,
    getAllJds: async () => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));
            const statusvalue = JSON.parse(localStorage.getItem("status"));
            const response = await axios.get(
                `/jds?account_type=${userData.account_type}&owner_id=${userData.ID}&status=${statusvalue.status} `
            );
            set({ jdData: response.data });
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    },
    getJdByID: async (id) => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));
            const response = await axios.get(
                `/${userData.account_type}/${userData.ID}/jds/${id}`
            );
            set({ selectedJD: response.data });
            console.log("Seeting responseData");
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    },
    createJd: async (body) => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));

            const response = await axios.post(
                `/${userData.account_type}/${userData.ID}/jds`,
                body
            );

            console.log(response.data);

            if (response.status === 200) {
                set({ isJDCreated: true });
        return response.data.qhcompanyjid;
            }
        } catch (error) {
            set({ jdCreationError: error });
        }
    },
    updateJd: async (body, jdId) => {
        try {
            console.log("called", body)
            const userData = JSON.parse(localStorage.getItem("userDetails"));

            await axios.patch(
                `/${userData.account_type}/${userData.ID}/jds/${jdId}`,
                body
            );
        } catch (error) {
            set({ jdCreationError: error });
        }
    },
    postAssignment: async (id, value) => {
        console.log(value);
        var vendors = []
        if (value.vendors) {
            vendors = [...value.vendors]
        }

        //var teamMembers = []
        //if (value.team_members) {
           // teamMembers = {...value}
        //}
        const userData = JSON.parse(localStorage.getItem("userDetails"));
        console.log(userData);
        //console.log(teamMembers);
        try {

            //await axios.put(`/${userData.ID}/jds/${id}/assign/team_member`, {
            //    vendors: vendors,
            //    team_members: teamMembers,
            //    spoc_TA: value.spoc_TA,
            //    TA_manager: value.TA_manager,
            //});

            const response = await axios.put(`/assign/team_member/${userData.qhoid}/${userData.qhuid}/jds/${id}`, value);
            
            if (response.status === 200) {
                return true
            }else{
                return false
            }

        } catch (error) {
            console.log(error);
        }
    },

    clearJD: () => {
        console.log("clearJD");
        set({ selectedJD: null });
    }
}));

export default useJDStore;
