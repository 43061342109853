import React, { useState } from "react";
import { Link } from "react-router-dom";
import arrowIcon from "../../assets/right-arrow.png";

import "./sidebar.scss";

function Sidebar() {
  const [isCollapsedTeam, setIsCollapsedTeam] = useState(true);
  const [isCollapsedMy, setIsCollapsedMy] = useState(true);
  const [isCollapsedAllResume, setIsCollapsedAllResume] = useState(true);
  const [isCollapsedInterviews, setIsCollapsedInterviews] = useState(true);
  const [isCollapsedSourcing, setIsCollapsedSourcing] = useState(true);
  const [isCollapsedJD, setIsCollapsedJD] = useState(true);

  const [isCollapsedJDrs, setIsCollapsedJDrs] = useState(true);

  const handleToggleCollapseTeam = () => {
    setIsCollapsedTeam(!isCollapsedTeam);
  };
  const handleToggleCollapseMy = () => {
    setIsCollapsedMy(!isCollapsedMy);
  };
  const handleToggleCollapseAllResume = () => {
    setIsCollapsedAllResume(!isCollapsedAllResume);
  };
  const handleToggleCollapseInterviews = () => {
    setIsCollapsedInterviews(!isCollapsedInterviews);
  };
  const handleToggleCollapseSourcing = () => {
    setIsCollapsedSourcing(!isCollapsedSourcing);
  };
  const handleToggleCollapsejd = () => {
    setIsCollapsedJD(!isCollapsedJD);
  };

  const handleToggleCollapsers = () => {
    setIsCollapsedJDrs(!isCollapsedJDrs);
  };

  const setStatus = (data) => {
    console.log(data);
    localStorage.setItem("status", JSON.stringify({ status: data }));
  };

  function getTimeNow(){
    const today = new Date();
    const month = today.getMonth()+1;
    const year = today.getFullYear();
    const date = today. getDate();
    const currentDate = date + "-" + month + "-" + year;
    return currentDate
  }

  function getDay(){
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]; 
    const today = new Date();
    const day = today.getDay();
    return weekday[day]
  }

  return (
    <div className="sidebar-wrapper">

      {/* <Link className="sidebar-link" to="/interviews">
                Job Descriptions
            </Link> */}
      <Link className="sideFont" to="/TeamAssignments " style={{ marginLeft: "20px" }}>
        {getDay()}<br></br> {getTimeNow()} 
        <hr></hr>
      </Link>

      {/* <Link className="sidebar-link" to="/My Assignments">
                Dashboard
            </Link> */}
      <div>
        <button
          className={`btn btn-toggle d-inline-flex align-items-center rounded border-0 ${
            isCollapsedTeam ? "" : "active" // Add the active class conditionally
          } sideFont`}
          onClick={handleToggleCollapseTeam}
          data-bs-toggle="collapse"
          data-bs-target="#home-collapse"
          aria-expanded={isCollapsedTeam ? "false" : "true"}
        >
          <img
            src={arrowIcon}
            alt="Arrow"
            width="8" height="8" 
            className={`arrow-icon me-2 ${isCollapsedTeam ? "" : "rotate"}`}
          />
          Team Assignment
        </button>

        {!isCollapsedTeam && (
          <ul id="home-collapse" className="listShow">
            <li className="list-group-item">
              <Link
                className="sidebar-link"
                to={{ pathname: "/TeamAssignments", state: { status: "active" } }}
                onClick={() => setStatus("active")}
              >
                Dashboard (24Hrs)
              </Link>
            </li>    
            <li className="list-group-item">
              <Link
                className="sidebar-link"
                to={{ pathname: "/teamTaskUpdates", state: { status: "active" } }}
                onClick={() => setStatus("active")}
              >
                Task Updates (24Hrs)
              </Link>
            </li>                    

            <li className="list-group-item">
              <Link
                className="sidebar-link listShow"
                onClick={() => setStatus("active")}
                to={{ pathname: "/assignedJds", state: { status: "active" } }}
              >
                Assigned Jds
              </Link>
            </li>                                  
            {/* Add more list items as needed */}
          </ul>
        )}
        <hr></hr>
      </div>
      {/* <Link className="sidebar-link" to="/My Assignments">
                Dashboard
            </Link> */}
      <div>
        <button
          className={`btn btn-toggle d-inline-flex align-items-center rounded border-0 ${
            isCollapsedJD ? "" : "active" // Add the active class conditionally
          } sideFont`}
          onClick={handleToggleCollapseMy}
          data-bs-toggle="collapse"
          data-bs-target="#home-collapse"
          aria-expanded={isCollapsedMy ? "false" : "true"}
        >
          <img
            src={arrowIcon}
            alt="Arrow"
            width="8" height="8" 
            className={`arrow-icon me-2 ${isCollapsedMy ? "" : "rotate"}`}
          />
          My Recruitments
        </button>

        {!isCollapsedMy && (
          <ul id="home-collapse" className="listShow">
            <li className="list-group-item">
              <Link
                className="sidebar-link"
                to={{ pathname: "/myDashboard", state: { status: "active" } }}
                onClick={() => setStatus("active")}
              >
                My dashboard (24Hrs)
              </Link>
            </li> 
            <li className="list-group-item">
              <Link
                className="sidebar-link"
                to={{ pathname: "/myInternalTaskUpdates", state: { status: "active" } }}
                onClick={() => setStatus("active")}
              >
                My Task (24Hrs)
              </Link>
            </li>                         
            <li className="list-group-item">
              <Link
                className="sidebar-link listShow"
                onClick={() => setStatus("active")}
                to={{ pathname: "/myJDs", state: { status: "active" } }}
              >
                Assigned Jds
              </Link>
            </li>            
            {/* Add more list items as needed */}
          </ul>
        )}
        <hr></hr>
      </div>

      {/* <Link className="sidebar-link" to="/jds">
                Dashboard
            </Link> */}
      <div>
        <button
          className={`btn btn-toggle d-inline-flex align-items-center rounded border-0 ${
            isCollapsedJD ? "" : "active" // Add the active class conditionally
          } sideFont`}
          onClick={handleToggleCollapsejd}
          data-bs-toggle="collapse"
          data-bs-target="#home-collapse"
          aria-expanded={isCollapsedJD ? "false" : "true"}
        >
          <img
            src={arrowIcon}
            alt="Arrow"
            width="8" height="8" 
            className={`arrow-icon me-2 ${isCollapsedJD ? "" : "rotate"}`}
          />
          JDs
        </button>

        {!isCollapsedJD && (
          <ul id="home-collapse" className="listShow">
            <li className="list-group-item">
              <Link
                className="sidebar-link listShow"
                onClick={() => setStatus("active")}
                to={{ pathname: "/jds", state: { status: "active" } }}
              >
                Dashboard
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                className="sidebar-link listShow"
                onClick={() => setStatus("active")}
                to={{ pathname: "/jdsConsolidated", state: { status: "active" } }}
              >
                All JDs  
              </Link>
            </li>           
            <li className="list-group-item">
              <Link
                className="sidebar-link listShow"
                onClick={() => setStatus("active")}
                to={{ pathname: "/jdsPriority", state: { status: "active" } }}
              >
                Jds Priority
              </Link>
            </li>            
            {/* Add more list items as needed */}
          </ul>
        )}
        <hr></hr>
      </div>

      {/* <Link className="sidebar-link" to="/resumes">
                Dashboard
            </Link> */}
      <div>
        <button
          className={`btn btn-toggle d-inline-flex align-items-center rounded border-0 ${
            isCollapsedAllResume ? "" : "active" // Add the active class conditionally
          } sideFont`}
          onClick={handleToggleCollapseAllResume}
          data-bs-toggle="collapse"
          data-bs-target="#home-collapse"
          aria-expanded={isCollapsedAllResume ? "false" : "true"}
        >
          <img
            src={arrowIcon}
            alt="Arrow"
            width="8" height="8" 
            className={`arrow-icon me-2 ${isCollapsedAllResume ? "" : "rotate"}`}
          />
          All Resumes
        </button>

        {!isCollapsedAllResume && (
          <ul id="home-collapse" className="listShow">
            <li className="list-group-item">
              <Link
                className="sidebar-link listShow"
                onClick={() => setStatus("active")}
                to={{ pathname: "/resumes", state: { status: "active" } }}
              >
                Resume Search
              </Link>
            </li>

            {/* Add more list items as needed */}
          </ul>
        )}
        <hr></hr>
      </div>

      {/* <Link className="sidebar-link" to="/interviews">
                Dashboard
            </Link> */}
      <div>
        <button
          className={`btn btn-toggle d-inline-flex align-items-center rounded border-0 ${
            isCollapsedInterviews ? "" : "active" // Add the active class conditionally
          } sideFont`}
          onClick={handleToggleCollapseInterviews}
          data-bs-toggle="collapse"
          data-bs-target="#home-collapse"
          aria-expanded={isCollapsedInterviews ? "false" : "true"}
        >
          <img
            src={arrowIcon}
            alt="Arrow"
            width="8" height="8" 
            className={`arrow-icon me-2 ${isCollapsedInterviews ? "" : "rotate"}`}
          />
          Interviews & Selections
        </button>

        {!isCollapsedInterviews && (
          <ul id="home-collapse" className="listShow">
            <li className="list-group-item">
              <Link
                className="sidebar-link listShow"
                onClick={() => setStatus("active")}
                to={{ pathname: "/jds", state: { status: "active" } }}
              >
                Top View JD Wise
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                className="sidebar-link"
                to={{ pathname: "/InterviewCalendar", state: { status: "closed" } }}
                onClick={() => setStatus("closed")}
              >
                Interview Calender
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                className="sidebar-link"
                to={{ pathname: "/teamSelection", state: { status: "active" } }}
                onClick={() => setStatus("active")}
              >
                Selections
              </Link>
            </li>
            {/* Add more list items as needed */}
          </ul>
        )}
        <hr></hr>
      </div>



      {/* <Link className="sidebar-link" to="/resume_source">
                Resume Sourcing
            </Link> */}
      <div>
        <button
          className={`btn btn-toggle d-inline-flex align-items-center rounded border-0 ${
            isCollapsedSourcing ? "" : "active" // Add the active class conditionally
          } sideFont`}
          onClick={handleToggleCollapseSourcing}
          data-bs-toggle="collapse"
          data-bs-target="#home-collapse"
          aria-expanded={isCollapsedSourcing ? "false" : "true"}
        >
          <img
            src={arrowIcon}
            alt="Arrow"
            width="10" height="10" 
            className={`arrow-icon me-2 ${isCollapsedSourcing ? "" : "rotate"}`}
          />
          Sourcing
        </button>

        {!isCollapsedSourcing && (
          <ul id="home-collapse" className="">
            <li className="list-group-item">
            <Link className="sidebar-link" to="/resume_source">
               All
              </Link>
              <Link className="sidebar-link" to="/From_My_Team">
                Team
              </Link>
            </li>
            <li className="list-group-item">
              <Link className="sidebar-link" to="/From_Vendors">
                Vendors
              </Link>
            </li>

            {/* Add more list items as needed */}
          </ul>
        )}
        <hr></hr>
      </div>
    </div>
  );
}

export default Sidebar;
