import React, { useState, useEffect } from "react";
import { Outlet, useParams, useNavigate } from "react-router-dom";
import { Button, Empty } from "antd";

import RTable from "../RTable";
import RTableSelectable from "../RTableSelectable";
import InterviewTableSelectable from "../InterviewTableSelectable";
import CandidateAddModal from "../CandidateAddModal";
import CommTableSelectable from "../CommTableSelectable";
import "./superuser.scss";
import PieChart from "../PieChart";
import StackChart from "../StackChart";
import useMembersStore from "../../store/members.store";
import superUseStatsStore from "../../store/superuser.store";
import SuperUserTableSelectable from "../SuperUserTableSelectable";
import SuperUserPlanTableSelectable from "../SuperUserPlanTableSelecable";
import SuperUserPriceTableSelectable from "../SuperUserPriceTableSelectable";
import SuperAdminPriceTableSelectable from "../SuperAdminPriceTableSelectable";

const summaryListTable = [
    "Select",
    "Sr. No.",         
    "Company Name",  
    "Company Type", 
    "Super Admin Name",
    "Super Admin Phone",     
    "Super Admin Email",
    "Onboarding Date",
    "Plan Name",
    "Onboarding Status",
    "Document Status", 
    "Trial Status",  
    "Subscription Status", 
    "Payment Status",     
    "Address",
    "Pin",
    "City",          
    "Country",
];

const documentsListTable = [
    "Select",    
    "Sr. No.",
    "Company Name",
    "Company Type",
    "Super Admin Name",
    "Super Admin Phone",     
    "Super Admin Email",
    "Onboarding Date",
    "Status",
    "Document Type",
    "Document",
];

const freeListTable = [
    "Select",
    "Sr. No.",
    "Company Name",
    "SuperAdmin Name",
    "Company Type",
    "Plan Name",
    "Trial Period",
    "Trial Period Start Date",
    "Trial Period End Date",
    "Status" // INACTIVE, TP_ACTIVE,TP_EXPIRED,SP_ACTIVE,SP_EXPIRED
];

const subscriptionListTable = [
    "Select",
    "Sr. No.",
    "Company Name",
    "SuperAdmin Name",
    "Plan Name",
    "Duration",
    "Plan Amount",
    "Normal Discount",
    "Referral Discount",
    "Final Price",
    "Start Date",
    "End Date",
    "Payment Status",  
    "Subscription Status", 
];

const paymentListTable = [
    "Select",
    "Sr. No.",
    "Company Name",
    "SuperAdmin Name",
    "Plan Name",
    "Subscription Status", 
    "Payment Status",     
    "Amount Due",
    "Amount Paid",
    "Reference Number",
    "Bank Name",
    "Payment Date",
    "Download Invoice",
    "Generate Invoice"
];

const planListTable = [
    "Select",
    "Sr. No.",
    "Plan Name",  
    "Product",
    "Trial Period",
    "No of Users",
    "No of Resumes",
    "No of JDs/Post",
    "Vendor Connect",
    "Q Plan Price",
    "HY Plan Price",
    "Y Plan Price", 
    "Additional User Price" ,  
    "Additional Resumes Price",    
];

const mySubscriptionTable = [
    "Select",
    "Sr. No.",
    "Plan Name", 
    "Type", 
    "Status",
    "Duration",
    "Start Date",
    "End Date",
    "Due Amount",
    "Payment Status",
    "Payment Date",
    "Bank Name",
    "Reference Number"    
];
const myplanTable = [
    "Select",
    "Sr. No.",
    "Plan Name",  
    "Product",
    "No of Users",
    "No of Resumes",
    "No of Jb Posts",
    "Vendor Connect",    
];

export default function SuperUser() {
    const [currentView, setCurrentView] = useState("summary");
    const [selectedResumeID, setResumeID] = useState("");
    const navigate = useNavigate();
    const { value } = useParams();
    const { superUserInfo,getSuperUserInfo ,superAdminInfo,getSuperAdminInfo,superUserUpdateflag,resetSuperUserUpdateFlag} = superUseStatsStore();
    useEffect(() => {
        if(superUserUpdateflag){
            getSuperUserInfo("all");
            getSuperAdminInfo("all");
            resetSuperUserUpdateFlag();
        }
    }, [superUserUpdateflag]);

    const statsData = superUserInfo?.stats?.map((comp, idx) => {
        return {
            select:"",
            id: idx+1,
            name: comp?.companyname,
            jds:  comp?.totaljds,
            resumes: comp?.totalresumes,
            users: comp?.totalusers,
            vendors:  comp?.totalvendors,
            customers: comp?.totalcustomers,
        };
    });  

    const getSubscriptionStatus =(status)=>{
        if(status === "subscription_inactive"){return "INACTIVE"}
        if(status === "subscription_new_req"){return "NEW REQ "}
        if(status === "subscription_configured"){return "CONFIGURED"}
        if(status === "subscription_active"){return "ACTIVE"}
        if(status === "subscription_expired"){return "EXPIRED"}
        if(status === "subscription_stopped"){return "STOPPED"} 
        if(status === "subscription_aborted"){return "ABORTED"} 
        if(status === "subscription_activation_in_progress"){return "ACTIVATION IN PROGRESS"}
    } 

    const getDocumentStatus =(status)=>{
        if(status === "doc_verification_pending"){return "VERIFICATION PENDING"}
        if(status === "doc_rejected"){return "REJECTED"}
        if(status === "doc_verified"){return "VERIFIED"}       
    } 

    const getTrialStatus =(status)=>{
        if(status === "trial_inactive"){return "INACTIVE"}
        if(status === "trial_active"){return "ACTIVE"}
        if(status === "trial_expired"){return "EXPIRED"}   
        if(status === "trial_stop"){return "STOP"}
        if(status === "trial_activation_in_progress"){return "ACTIVATION IN PROGRESS"}
        if(status === "trial_expired"){return "EXPIRED"}     
    } 
    const getOnBoardingStatus =(status)=>{
        if(status === "account_created"){return "ACCOUNT CREATED"}
        if(status === "signup_otp_verification_pending"){return "OTP VERIFICATION PENDING"}
        if(status === "signup_account_verified"){return "ACCOUNT VERIFIED"}
        if(status === "account_deleted"){return "ACCOUNT DELETED"}
        if(status === "account_suspended"){return "ACCOUNT SUSPENDED"}           
    } 
    const getPaymentStatus =(status)=>{
        if(status === "payment_none"){return "NA"}
        if(status === "payment_req"){return "REQUEST PAYMENT"}
        if(status === "payment_revise"){return "REVISE PAYMENT"} 
        if(status === "payment_declined"){return "DECLINED PAYMENT"}
        if(status === "payment_done"){return "PAYMENT DONE"}        
    }
    const getSubType =(status)=>{
        const version_array = status.split('_')
        if(version_array[0] === "trial"){return "TRIAL"}
        if(version_array[0] === "subscription"){return "SUBSCRIPTION"}     
    }
    const summaryData = superUserInfo?.adminstats?.map((comp, idx) => {
        var onstatus = getOnBoardingStatus(comp?.accountstatus)
        var docstatus = getDocumentStatus(comp?.docstatus)
        var trialstatus = getTrialStatus(comp?.trialstatus)
        var substatus = getSubscriptionStatus(comp?.subscriptionstatus)
        var paystatus = getPaymentStatus(comp?.paymentstatus)
        return {
            select:"",
            id: idx+1,
            name: comp?.companyname,
            type: comp?.companytype, 
            aname:  comp?.name,
            aphone: comp?.phone,            
            aemail: comp?.email,
            date: comp?.onboardingdate,
            pname: comp?.planname,
            ostatus:onstatus, 
            dstatus:docstatus, 
            tstatus:trialstatus, 
            sstatus:substatus, 
            pstatus:paystatus,             
            address:  comp?.address,
            pin: comp?.pin,
            city: comp?.city,
            country:  comp?.country            
        };
    });  
    
    const documentsListData = superUserInfo?.adminstats?.map((comp, idx) => {
        var docstatus = getDocumentStatus(comp?.docstatus)
        return {
            select:"",            
            id: idx+1,
            name: comp?.companyname,
            type: comp?.companytype,
            aname:  comp?.name,
            aphone: comp?.phone,            
            aemail: comp?.email,
            date: comp?.onboardingdate,  
            status:docstatus,          
            documenttype: comp?.documenttype,
            document: comp?.document,
        };
    });  
    
    const getfreeListData = () =>{
        var stats
        var index =0
        const freeListData=[{}]
        for(let idx = 0; idx < superUserInfo?.freestats?.length; idx++){
            if(getDocumentStatus(superUserInfo?.adminstats?.[idx].docstatus)==="VERIFIED"){
                stats = superUserInfo?.freestats[idx] 
                freeListData[index].select = ""
                freeListData[index].id = idx+1
                freeListData[index].name = stats?.companyname
                freeListData[index].aname = superUserInfo?.adminstats?.[idx]?.name
                freeListData[index].type = superUserInfo?.adminstats?.[idx]?.companytype
                freeListData[index].planname = stats?.shistory?.[0].planname
                freeListData[index].trialperiod = stats?.shistory?.[0].trialperiod==0?"--":stats?.shistory?.[0].trialperiod
                freeListData[index].startdate = stats?.shistory?.[0].startdate
                freeListData[index].enddate = stats?.shistory?.[0].enddate
                freeListData[index].trialstatus =getTrialStatus(stats?.shistory?.[0].type)
                index= index+1
            }
        }
        return freeListData
    }
    const getSubListData = () =>{
        var stats
        var index=0
        const subListData=[{}]

        for(let idx = 0; idx < superUserInfo?.subscriptionstats?.length; idx++){
            if(getDocumentStatus(superUserInfo?.adminstats?.[idx].docstatus)=="VERIFIED"){
                stats = superUserInfo?.subscriptionstats[idx] 
                subListData[index].select = ""
                subListData[index].id = idx+1
                subListData[index].name = stats?.companyname
                subListData[index].aname = superUserInfo?.adminstats?.[idx]?.name
                subListData[index].planname = stats?.shistory?.[0].planname
                subListData[index].duration = stats?.shistory?.[0].duration + " Months"
                subListData[index].startdate = stats?.shistory?.[0].startdate
                subListData[index].enddate = stats?.shistory?.[0].enddate
                subListData[index].paymentstatus =getPaymentStatus(stats?.shistory?.[0].paymentstatus)
                subListData[index].substatus =getSubscriptionStatus(stats?.shistory?.[0].type)
                subListData[index].planamount = stats?.shistory?.[0].planamount
                subListData[index].ndiscount = stats?.shistory?.[0].discount
                subListData[index].rdiscount = stats?.shistory?.[0].discount
                subListData[index].finalprice = stats?.shistory?.[0].planamount

                index = index+1
            }
        }
        return subListData
    }
    const freeListData = superUserInfo?.freestats?.map((stats, idx) => {
        var trialstatus = getTrialStatus(stats?.shistory?.[0].type)
        return {
            select:"",
            id:idx+1,
            name: stats?.companyname,
            aname:  superUserInfo?.adminstats?.[idx]?.name,
            type: superUserInfo?.adminstats?.[idx]?.companytype,
            planname: stats?.shistory?.[0].planname,
            trialperiod: stats?.shistory?.[0].trialperiod==0?"--":stats?.shistory?.[0].trialperiod,
            startdate: stats?.shistory?.[0].startdate,
            enddate: stats?.shistory?.[0].enddate,
            trialstatus:trialstatus
        };
    });   

    const subscriptionListData = superUserInfo?.subscriptionstats?.map((stats, idx) => {
        var sstatus = getSubscriptionStatus(stats?.shistory?.[0].type)
        var pstatus = getPaymentStatus(stats?.shistory?.[0].paymentstatus)
        return {
            select:"",
            id:idx+1,
            name: stats?.companyname,
            aname:  superUserInfo?.adminstats?.[idx]?.name,
            planname: stats?.shistory?.[0].planname,
            duration: stats?.shistory?.[0].duration + " Months",
            planamount:10000,
            ndiscount:0,
            rdiscount:0,
            finalprice:10000,
            startdate: stats?.shistory?.[0].startdate,
            enddate: stats?.shistory?.[0].enddate,
            paymentstatus: pstatus,
            substatus: sstatus,
        };
    });

    const paymentListData = superUserInfo?.subscriptionstats?.map((stats, idx) => {
        var sstatus = getSubscriptionStatus(stats?.shistory?.[0].type)
        var pstatus = getPaymentStatus(stats?.shistory?.[0].paymentstatus)
        return {
            select:"",
            id:idx+1,
            name: stats?.companyname,
            aname:  superUserInfo?.adminstats?.[idx]?.name,
            planname: stats?.shistory?.[0].planname,
            substatus: sstatus,
            paymentstatus: pstatus,
            planamount:stats?.shistory?.[0].planamount,
            paymentamount: stats?.shistory?.[0].paymentamount,
            referencenumber: stats?.shistory?.[0].paymentmethod,
            bankname:"",
            paymentdate: stats?.shistory?.[0].paymentdate,
            generate: stats?.shistory?.[0].invoice,
            invoice: stats?.shistory?.[0].invoice,
        };        
    });

    const planListData = superUserInfo?.planlist?.map((plan, idx) => {
        return {
            select:"",
            id: idx+1,
            name: plan.planname,
            product: plan.product==""?"QHRFlow":plan.product,
            trialperiod: plan.trialperiod,
            maxusers: plan.maxusers,
            maxresumes: plan.maxresumes,
            maxjds: plan.maxjds,
            vendorconnect: plan.vendorconnect,
            quarterly: plan.quarterly,            
            hyearly: plan.hyearly,
            yearly: plan.yearly,
            addonuser: plan.addonuser,
            addonresumes: plan.addonresumes,           
        };
    });    


    const mySubscriptionListData = superAdminInfo?.subscriptionstats?.map((stats, idx) => {
        var sstatus = getSubscriptionStatus(stats?.shistory?.[0].type)
        var pstatus = getPaymentStatus(stats?.shistory?.[0].paymentstatus)
        var stype = getSubType(stats?.shistory?.[0].type)
        var sduration = stype=="TRIAL"?stats?.shistory?.[0].trialperiod :stats?.shistory?.[0].duration + " Months"
        return {
            select:"",
            id:idx+1,
            planname: stats?.shistory?.[0].planname,
            type:stype,
            substatus: sstatus,
            duration: sduration,
            startdate: stats?.shistory?.[0].startdate,
            enddate: stats?.shistory?.[0].enddate,
            paymentstatus: pstatus,
            planamount:stats?.shistory?.[0].planamount,
            paymentdate: stats?.shistory?.[0].paymentdate,
            referencenumber: stats?.shistory?.[0].paymentmethod,
            bankname:"",
        };
    });   

    const handleCurrentViewProfile = () =>{
        setCurrentView("profile")
    }
    const handleCurrentViewResumeQuality = () =>{
        setCurrentView("resume_analysis") 
    }

    return (

        <div className="jd-summary-wrapper">
      {currentView !== "profile" && (
        <>
            <div className="section-header">
                <h3 className="title">Super User Analytics  - Version {superUserInfo?.version}</h3>

            </div>
            <div className="jd-summary-buttons-wrapper">
                <Button
                    className={`jd-summary-btn ${
                        currentView === "summary" && "jd-summary-btn-active"
                    }`}
                    onClick={() => setCurrentView("summary")}
                >
                    Companies & Users
                </Button>
                <Button
                    className={`jd-summary-btn ${
                        currentView === "documents" && "jd-summary-btn-active"
                    }`}
                    onClick={() => setCurrentView("documents")}
                >
                    Step 1: Document Verification
                </Button>
                <Button
                    className={`jd-summary-btn ${
                        currentView === "ctc" && "jd-summary-btn-active"
                    }`}
                    onClick={() => setCurrentView("free")}
                >
                    Step 2: Configure Trial Users
                </Button>
                <Button
                    className={`jd-summary-btn ${
                        currentView === "skills" && "jd-summary-btn-active"
                    }`}
                    onClick={() => setCurrentView("paid")}
                >
                   Step 2: Configure Subscriptions
                </Button> 
                <Button
                    className={`jd-summary-btn ${
                        currentView === "skills" && "jd-summary-btn-active"
                    }`}
                    onClick={() => setCurrentView("payments")}
                >
                  Step 3: Payment Details
                </Button>                 
                <Button className="jd-summary-btn"
                    onClick={() => setCurrentView("plans")}>
                    QHRFLOW Subscription Plan
                </Button>  
                
                <Button className="jd-summary-btn"
                    onClick={() => setCurrentView("mysubscription")}>
                    My Subscription
                </Button>                                                                             
            </div>
        </>
      )}
            {superUserInfo != null? (
                <div className="jd-summary-wrapper">
                    {
                    currentView === "documents" ?(
                        <SuperUserTableSelectable
                        isClickable
                        titleList={documentsListTable}
                        data={documentsListData}
                        isEditField = {true}
                        setCurrentView={handleCurrentViewProfile}
                        currentView = {currentView}
                    />
                    )
                    :currentView === "free" ? (
                        <SuperUserTableSelectable
                            isClickable
                            titleList={freeListTable}
                            data={getfreeListData()}
                            isEditField = {true}
                            isResumePage
                            setCurrentView={setCurrentView}
                            currentView = {currentView}
                        />
                    ):currentView === "paid" ? (
                          <SuperUserTableSelectable
                                titleList={subscriptionListTable}
                                data={getSubListData()}
                                isEditField = {true}
                                setCurrentView={handleCurrentViewResumeQuality}
                                currentView = {currentView}
                          /> 
                    ):currentView === "payments" ? (
                          <SuperUserPriceTableSelectable
                                titleList={paymentListTable}
                                data={paymentListData}
                                setCurrentView={handleCurrentViewResumeQuality}
                                currentView = {currentView}
                                isEditField = {true}
                          />                     
                    ):currentView === "plans" ? (
                           <SuperUserPlanTableSelectable
                                isClickable
                                titleList={planListTable}
                                isEditField = {true}
                                data={planListData}
                                setCurrentView={setCurrentView}
                                currentView = {currentView}
                                version ={superUserInfo?.version}
                          />                                                                                                                      
                    ):currentView === "mysubscription" ? (
                        <>
                          <SuperAdminPriceTableSelectable
                                isClickable
                                titleList={mySubscriptionTable}
                                data={mySubscriptionListData}
                                isResumePage
                                setCurrentView={setCurrentView}
                                currentView = {currentView}
                          />
                        </>                                                                                             
                    ) : (                        
                        <>
                            <SuperUserTableSelectable
                                isResumePage
                                titleList={summaryListTable}
                                data={summaryData}
                                selectable= "C Only"
                            />                            
                        </>
                    )}
                    
                </div>
            ) : (
                <Empty style={{ margin: "60px" }} >
                    <h4>SuperUser Dash Board</h4>
                </Empty>
            )}         
        </div>
    );
}
