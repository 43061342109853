import React from "react";
import { useEffect } from "react"

import "./activeSummary.scss";
import StackBarChart from "../StackBarChart";
import useStatsStore from "../../store/stats.store";  

export default function ActiveSummary({ inputData, buttonName, title }) {

  const { graphsGroupUserStats} = useStatsStore();

  const resumelabels = ['Geetha', 'Shivani', 'Anusha', 'Amruta', 'Aniket', 'Arpitha', 'Vinay'];
  const stackBarChartResumes = {
    labels : graphsGroupUserStats?.useractivity?.map((user) => user?.username ),
    datasets: [
      {
        label: 'JDs Created',
        data: graphsGroupUserStats?.useractivity?.map((user) => user?.numjdscreated ),
        backgroundColor: 'rgb(169, 204, 227  )',
      },
      {
        label: 'JDs Worked On',
        data:  graphsGroupUserStats?.useractivity?.map((user) => user?.numassigned ),
        backgroundColor: 'rgb(210, 180, 222)',
      },
      {
        label: 'New Uploaded',
        data: graphsGroupUserStats?.useractivity?.map((user) => user?.numuploaded ),
        backgroundColor: 'rgb(53, 162, 235)',
      },
      {
        label: 'Available',
        data: graphsGroupUserStats?.useractivity?.map((user) => user?.NumAvailable ),
        backgroundColor: 'rgb(255, 195, 0,0.5)',
      },
      {
        label: 'Shared',
        data: graphsGroupUserStats?.useractivity?.map((user) => user?.numshared ),
        backgroundColor: 'rgb(133, 193, 233 )',
      },
      {
        label: 'NP/B/JD/CB',
        data: graphsGroupUserStats?.useractivity?.map((user) => user?.numothers ),
        backgroundColor: 'rgba(128, 0, 128, 0.4)',
      },
    ],
  };   

  const stackBarChartResumeStatus = {
      labels : graphsGroupUserStats?.useractivity?.map((user) => user?.username ),
      datasets: [
        {
          label: 'ToBeShared',
          data: graphsGroupUserStats?.useractivity?.map((user) => user?.numtobeshared ),
          backgroundColor: 'rgba(247, 220, 111  )',
        },
        {
          label: 'FBPending',
          data: graphsGroupUserStats?.useractivity?.map((user) => user?.numfbpending ),
          backgroundColor: 'rgb(133, 193, 233)',
        },
        {
          label: 'Screen Rejected',
          data: graphsGroupUserStats?.useractivity?.map((user) => user?.numscreenrejected ),
          backgroundColor: 'rgb(236, 112, 99,0.6)',
        },
        {
          label: 'Screen Selected',
          data: graphsGroupUserStats?.useractivity?.map((user) => user?.numscreenselected),
          backgroundColor: 'rgba(34, 153, 84 , 0.7)',
        },
        {
          label: 'Duplicate',
          data: graphsGroupUserStats?.useractivity?.map((user) => user?.numduplicates),
          backgroundColor: 'rgba(128, 128, 128,0.5)',
        }, 
        {
          label: 'Review Pending',
          data: graphsGroupUserStats?.useractivity?.map((user) => user?.numreviewpending),
          backgroundColor: 'rgba(84, 153, 199)',
        },      
      ],
    }; 

 const stackBarChartResumeQuality = {
      labels : graphsGroupUserStats?.useractivity?.map((user) => user?.username ),
      datasets: [
        {
          label: 'Quality Resumes',
          data:  graphsGroupUserStats?.useractivity?.map((user) => user?.numgoodmatch ),
          backgroundColor: 'rgba(75, 192, 192)',
        },
        {
          label: 'Above Average',
          data: graphsGroupUserStats?.useractivity?.map((user) => user?.numaboveaverage ),
          backgroundColor: 'rgb(125, 206, 160)',
        },
        {
          label: 'Average',
          data: graphsGroupUserStats?.useractivity?.map((user) => user?.numaverage ),
          backgroundColor: 'rgb(133, 193, 233)',
        },
        {
          label: 'Below Average',
          data: graphsGroupUserStats?.useractivity?.map((user) => user?.numbelowaverage ),
          backgroundColor: 'rgba(169, 204, 227 )',
        },
        {
          label: 'Not Suitable',
          data: graphsGroupUserStats?.useractivity?.map((user) => user?.numnotsuitable ),
          backgroundColor: 'rgba(128, 128, 128,0.5)',
        },     
      ],
    }; 

  const stackBarChartInterviewStatus = {
      labels : graphsGroupUserStats?.useractivity?.map((user) => user?.username ),
      datasets: [
        {
          label: 'Final Select',
          data: resumelabels.map(() => 20),
          backgroundColor: 'rgba(133, 193, 233)',
        },
        {
          label: 'L0',
          data: resumelabels.map(() => 0),
          backgroundColor: 'rgba(52, 152, 219)',
        },      
        {
          label: 'L1',
          data: resumelabels.map(() => 30),
          backgroundColor: 'rgb(125, 206, 160)',
        },
        {
          label: 'L2',
          data: resumelabels.map(() => 40),
          backgroundColor: 'rgb(75, 192, 192)',
        },
        {
          label: 'Customer',
          data: resumelabels.map(() => 40),
          backgroundColor: 'rgba( 88, 214, 141   )',
        },
        {
          label: 'HR',
          data: resumelabels.map(() => 40),
          backgroundColor: 'rgba(40, 180, 99)',
        },     
      ],
    }; 

  const alllabels = ['JDs', 'Resumes', 'Vendors', 'Users', 'Interviews', 'Offers', 'Joined'];
  const stackBarChartAll = {
      labels : alllabels,
      datasets: [
        {
          label: 'All',
          data: resumelabels.map(() => 40),
          backgroundColor: 'rgba(34, 153, 84 , 0.7)',
        },  
      ],
    }; 

  const allQualitylabels = ['Suitable', 'Above Average', 'Average', 'Below Average', 'Not Suitable']; 
  const stackBarChartResumeQualityAll = {
    labels : allQualitylabels,
    datasets: [
      {
        label: 'Quality',
        data: resumelabels.map(() => 20),
        backgroundColor: 'rgba(75, 192, 192)',
      },     
    ],
  }; 

  useEffect(() => {

 }, []);

  return (
        <div className="activity-summary-wrapper">
            {(title === 'All') ? <h1 className="activity-summary-title"> {title} Stats Summary </h1>
            : <h1 className="activity-summary-title"> {title} Activity (Last 24 hours)</h1>
            }
            {graphsGroupUserStats != null ?
              <div className="activity-wrapper">
                  {(title === 'Users') ?
                  ( 
                  <>
                    <div className = "activity-bar-stackChart-wrapper">
                            {graphsGroupUserStats != null?
                                    graphsGroupUserStats?.graphcandidatestatus =="true"?
                                    <StackBarChart stackBarData={stackBarChartResumes} stack = {false} showTitle={"Candidate Status"} />:
                                    ""
                                  :""
                            }
                            {graphsGroupUserStats != null?
                                    graphsGroupUserStats?.graphresumestatus =="true"?
                                    <StackBarChart stackBarData={stackBarChartResumeStatus} stack = {false} showTitle={"Resume Status"}/>:
                                    ""
                                  :""
                            } 
                            {graphsGroupUserStats != null?
                                    graphsGroupUserStats?.graphresumescore =="true"?
                                    <StackBarChart stackBarData={stackBarChartResumeQuality} stack = {false} showTitle={"Resume Quality"}/>:
                                    ""
                                  :""
                            }                                                       
                            {graphsGroupUserStats != null?
                                    graphsGroupUserStats?.graphinterviewsch =="true"?
                                    <StackBarChart stackBarData={stackBarChartInterviewStatus} stack = {false} showTitle={"Interview Plans"}/>:
                                    ""
                                  :""
                            } 
                            {graphsGroupUserStats != null?
                                    graphsGroupUserStats?.graphinterviewstatus =="true"?
                                    <StackBarChart stackBarData={stackBarChartInterviewStatus} stack = {false} showTitle={"Interview Status"}/>:
                                    ""
                                  :""
                            }
                            {graphsGroupUserStats != null?
                                    graphsGroupUserStats?.graphoffers =="true"?
                                    <StackBarChart stackBarData={stackBarChartInterviewStatus} stack = {false} showTitle={"Offers and Selections"}/>:
                                    ""
                                  :""
                            } 
                    </div>                               
                  </>

                  
                  )
                  : (title === 'Vendors') ? 
                  ( 
                      <div className = "activity-bar-stackChart-wrapper">
                          <StackBarChart stackBarData={stackBarChartResumes} stack = {false} showTitle={"Candidate Status"} />
                          <StackBarChart stackBarData={stackBarChartResumeStatus} stack = {false} showTitle={"Resume Status"}/>
                          <StackBarChart stackBarData={stackBarChartResumeQuality} stack = {false} showTitle={"Resume Quality"}/>
                          <StackBarChart stackBarData={stackBarChartInterviewStatus} stack = {false} showTitle={"Interview Status"}/>
                      </div>
                      )
                  : 
                  ( 
                    <div className = "activity-bar-stackChart-wrapper">
                        <StackBarChart stackBarData={stackBarChartAll} stack = {false} showTitle={"Major Stats - All"} />
                        <StackBarChart stackBarData={stackBarChartResumeQualityAll} stack = {false} showTitle={"All Resume Quality"} />
                    </div>
                    )
                  }              
              </div>
              :""
            }

        </div>
    );
}
