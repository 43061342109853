import { create } from "zustand";
import axios from "../helper/axios";

const useAuthStore = create((set) => ({
    user: {},

    userLogout: async (body) => {
        try {
            const userData = JSON.parse(localStorage.getItem("userDetails"));
            console.log(body);
            const response = await axios.post(
                `/logout/${userData.qhoid}/${userData.qhuid}`
            );
            console.log(response.data.name); 
            console.log(response.data.status);
        } catch (err) {
            console.error(err);
        }
    },  
}));

export default useAuthStore;
