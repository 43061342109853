import React, { useEffect } from 'react'
import useStatsStore from "../../store/stats.store"; 
import "./notifications.scss";
import {List, Avatar, Badge} from 'antd';

function Notifications() {
    const { graphsGroupUserStats, newNotificationIndices, clearNotificationIndices } = useStatsStore();

    useEffect(() => {
      return () => {
        clearNotificationIndices(); 
      }
    }, []);

        const keyLabelMap = {
            qhuid: "QHUID",
            username: "Username",
            numjdscreated: "Number of JDs Created",
            numassigned: "Number of Assigned",
            numjdsworked: "Number of JDs Worked",
            numuploaded: "Number of Uploaded",
            numavailable: "Number of Available",
            numshared: "Number of Shared",
            numothers: "Number of Others",
            numtobeshared: "Number of To Be Shared",
            numfbpending: "Number of Feedback Pending",
            numscreenrejected: "Number of Screen Rejected",
            numscreenselected: "Number of Screen Selected",
            numduplicates: "Number of Duplicates",
            numreviewpending: "Number of Review Pending",
            numnotsuitable: "Number of Not Suitable",
            numbelowaverage: "Number of Below Average",
            numaverage: "Number of Average",
            numaboveaverage: "Number of Above Average",
            numgoodmatch: "Number of Good Match",
            numl0sch: "Number of L0 Scheduled",
            numl0resch: "Number of L0 Rescheduled",
            numl1sch: "Number of L1 Scheduled",
            numl1resch: "Number of L1 Rescheduled",
            numl2sch: "Number of L2 Scheduled",
            numl2resch: "Number of L2 Rescheduled",
            numhrsch: "Number of HR Scheduled",
            numhrresch: "Number of HR Rescheduled",
            numnoshow: "Number of No Show",
            numl0reject: "Number of L0 Rejected",
            numl1reject: "Number of L1 Rejected",
            numl2reject: "Number of L2 Rejected",
            numhrreject: "Number of HR Rejected",
            numl0select: "Number of L0 Selected",
            numl1select: "Number of L1 Selected",
            numl2select: "Number of L2 Selected",
            numfinalselect: "Number of Final Selected",
            numoffers: "Number of Offers",
          };
          

    const userStats = graphsGroupUserStats?.useractivity?.map((user) => {
        const keyWordsArray = {};
        Object.keys(user).forEach((key) => {
          if(user[key] !== 0 && key !== "qhuid") {
              keyWordsArray[keyLabelMap[key]] = user[key];
          }
        })     
        return keyWordsArray;
    });

    return (
      <>
        <div className='notifications-header'>
          <p>Updates from last 24hrs</p>
        </div>
        <div className="notifications-wrapper">
          <List
            itemLayout="vertical"
            dataSource={userStats}
            renderItem={(user, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                      <Badge dot={newNotificationIndices?.has(index)} color='green'>
                          <Avatar className="user-avatar">{user.Username[0] || '?'}</Avatar>
                      </Badge>
                  }
                  title={<span>{user.Username ? `${user.Username} made following changes` : 'User Notification'}</span>}
                  description={Object.entries(user).map(([key, value]) =>
                    key !== 'Username' ? (
                      <p key={key} style={{ color: 'black', marginBottom: '4px' }}>
                        {key}: {value}
                      </p>
                    ) : null
                  )}
                />
              </List.Item>
              )}
            />
        </div>
      </>
    );      
}

export default Notifications