import React, { useEffect } from "react";
// import { actions } from "../../actions";
// import { useSelector, useDispatch } from "react-redux";

import "./dashboard.scss";
import ActiveSummary from "../ActiveSummaryBlock";
import useStatsStore from "../../store/stats.store";  
import useMembersStore from "../../store/members.store";


function Dashboard() {
    const { getClients } = useMembersStore();
    const { getTeamsDashBoardConsolidatedStats,getTeamsInternalConsolidatedStats,getMyInternalConsolidatedStats,
            getMyDashBoardConsolidatedStats,getJdsConsolidatedStats,getGraphsGroupUserStats} = useStatsStore();
    
    useEffect(() => {
    
        getClients();
        getTeamsDashBoardConsolidatedStats();
        getTeamsInternalConsolidatedStats("24");
        getMyDashBoardConsolidatedStats();
        getMyInternalConsolidatedStats();
    
        let encodedJsonValues ={};
        encodedJsonValues.prio = "all";
        encodedJsonValues.jobstatus = "active";
        encodedJsonValues.client = "all";
        encodedJsonValues.ta = "all";
        getJdsConsolidatedStats({...encodedJsonValues});
    
        let encodedJsonValues1 ={};
        encodedJsonValues1.group = "all";
        getGraphsGroupUserStats("24",{...encodedJsonValues1});
    
     }, []);


    return (
        <div className="dashboard-wrapper">
            <div className="activity-container">
                <ActiveSummary
                    inputData={null}
                    buttonName="See Job"
                    title="Users"
                />
            </div>
            <div className="activity-container">
                <ActiveSummary
                    inputData={null}
                    buttonName="See Interviews"
                    title="Vendors"
                />
            </div>            
            <div className="activity-container">
                <ActiveSummary
                    inputData={null}
                    buttonName="See Resumes"
                    title="All"
                />
            </div>
        </div>
    );
}

export default Dashboard;
