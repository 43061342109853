import { Signup,SignIn,Authorization,Forgetpassword,Logout,Verify_acoount_otp,Verify_signin_otp,Subusersignuop,Getsubsuser} from "./login";
import { Createcompany,Getcompany } from "./company";
import { GetProductPlans } from "./landingpage";
import { Createjd,Getjds,GetJd,updateJD,uploadResume,GetjdResumes ,Downloadresumes,UpdateStatus} from "./jdActions";
export const actions = {
  Signup,
  SignIn,
  Verify_acoount_otp,
  Verify_signin_otp,
  Subusersignuop,
  Getsubsuser, 
  Authorization,
  Createjd,
  Getjds,
  GetJd,
  updateJD,
  Forgetpassword,
  Logout,
  Createcompany,
  Getcompany,
  uploadResume,
  GetjdResumes,
  Downloadresumes,
  UpdateStatus,
  GetProductPlans,
}
