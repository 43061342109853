import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useJDStore from "../../../store/jd.store";
import { useLocation } from "react-router-dom";

import Table from "../../../components/Table";
import LandingHeader from "../../header";
import logo from "../../../assets/landingFeatures/explorer.png";
import workflow from "../../../assets/landingFeatures/workflow.png";
import { Radio, Divider, Form, Input, message, Button, TimePicker, DatePicker,Card,Select } from "antd";
import Footer from "../../footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./jobListing.scss";

export default function JobListings(props) {
  const { Meta } = Card;
  
  const contentStyleJobListing = {
   margin:10,
   padding:"5px",
   height: "170px",
   width:"270px",
   background: "#ebf5fb"
  }; 

  console.log(props);

  const navigate = useNavigate();

  const status = localStorage.getItem("status");

  const { getAllJds, jdData } = useJDStore();

  const handleSignin = () => {
    navigate('/login')
  };

  const handleSignup = () => {
    navigate('/signup')
  };

  const handleJobSeekers = () => {
    navigate('/jobSeekers')
  };

  const handleCareerListing = () => {
    navigate('/jobSeekers')
  };

  const handleUpdateFormSubmit = () => {
    navigate('/jobListingSeachResults')
  };

  const settingsJobListing = {
    dots: false,
    speed: 15000,
    slidesToShow: 4,
    slidesToScroll: 2,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1,
  };  

    const encodeJson = (values) =>{

        let encodedJsonValues ={};

        //now fill the parameter
        encodedJsonValues.jdsearchreq ={};
        encodedJsonValues.jdsearchreq.Keywords= values.keywords;
        encodedJsonValues.jdsearchreq.Location=values.location;
        encodedJsonValues.jdsearchreq.Category=values.category; 
        encodedJsonValues.jdsearchreq.MinExp=values.min_exp; 
        encodedJsonValues.jdsearchreq.MaxExp=values.max_exp; 
        encodedJsonValues.jdsearchreq.duration=values.max_exp;                       
                     
        return encodedJsonValues
    }

    const handleFormSubmit = (values) => {
        const jasonValues = encodeJson(values)
        message.success("Searching....Please Wait");
        navigate('/jobListingSeachResults')
    };  

  const handleChange =()=>{

  }

  useEffect(() => {
    getAllJds();
  }, [status]);

  const jobCategory = [
    { value: 'it', label: 'IT and Software' },
    { value: 'vlsi', label: 'Hardware and VLSI' },
    { value: 'wireless', label: 'Wireless' },
    { value: 'embedded', label: 'Embedded'},
    { value: 'teaching', label: 'Teaching' },
    { value: 'BFSI', label: 'BFSI' },
    { value: 'mechanical', label: 'Mechanical' },
    { value: 'research', label: 'Research'}, 
    { value: 'purchasing', label: 'Purchasing ' },
    { value: 'marketing', label: 'Sales and Marketing' },
    { value: 'production', label: 'Production' },
    { value: 'medical', label: 'Medical '},           
  ];

  const titleList = [
    "Sr. No.",
    "Date Added",    
    "Client",
    "Job ID",
    "Job Title",
    "Available Resumes",
    "Screen Rejected",
    "Interviews",
    "Final Select",
    "Priority",
    "Job Status",
    "Matching Resume",
    "Assigned To",
    "SPOC",
    "Hiring Manager",
    "Date Modified",
  ];

  const jdsDetailData = jdData?.map((jds, idx) => {
    return {
      id: idx + 1,
      date_added: jds?.date_added,      
      client_name: jds?.client_name,
      jobId: jds?.ID,
      title: jds?.title,
      available_resumes: jds?.available_resumes,
      screen_rejected: jds?.screen_rejected,
      interviews: jds?.interviews,
      final_select: jds?.final_select,
      priority: jds?.priority,
      status: jds?.status,
      matching_resume: jds?.matching_resume,
      assigned_to: jds?.assigned_to,
      SPOC: jds?.SPOC,
      hiring_project_team_name: jds?.hiring_project_team_name,
      date_modified: jds?.date_modified,
    };
  });

  return (
    <div className="jobListing-wrapper">
          <div className="landing-header">
              <LandingHeader/>
          </div>
       <div className="jobListing-header"> 
          <div className="jobListing-header-logo">
              <img className="jobListing-logo-img"src={logo} />
              <div className="header-logo-name">
                  Quallyhire Explorer
              </div>
          </div>
          <div className="header-menu">
            <ul className="header-menu">
              <li >
                <a href="/jobSeekers">Explorer Home</a>
              </li>
              <li>
                Blog
              </li>   
              <li>
                Support 
              </li>                                                                        
            </ul>
          </div>
          <div className="header-sign">
            <Button className="sign-button" onClick={() => handleSignin()}>
              Candidate Sign In
            </Button>
            <Button className="job-button" onClick={() => handleJobSeekers()}>
              Post Jobs
            </Button>                                     
          </div>          
       </div>

       <div className="jobListing-title">
         <div className="jobListing-punchLine">
            Jobs from QuallyHire Explorer
         </div>
         <div className="jobListing-puncLineSmall">
            1,80,570 jobs listed here! Your dream job is waiting.
         </div>
         <div className="jobListing-search-wrapper">
            <Form
                className="jobListing-search-wrapper"
                name="jobListing-search"
                autoComplete="off"
                onFinish={handleFormSubmit}
            >
                <div className="jobListing-search">
                     <Form.Item
                        name="keywords"
                    >
                        <Input
                            className="jobListing-search-item"
                            type="text"
                            required= {true}
                            placeholder={"Keywords"}
                        />
                    </Form.Item>
                    <Form.Item
                        name="location"
                    >
                        <Input
                            className="jobListing-search-item"
                            type="text"
                            required={ true}
                            placeholder={"location"}
                        />
                    </Form.Item>
                    <Form.Item
                        name="category"
                    >
                        <Select
                          className="jobListing-search-item"
                          placeholder="category"
                          onChange={handleChange}
                          options={jobCategory}
                        />                        
                    </Form.Item>                        
                </div>  
                <Button
                        className="jobListing-search-button"
                        type="primary"
                        htmlType="submit"
                      >
                          Search Jobs
                </Button>                                
            </Form>
         </div>  

        <div className="jobListing-feature-heading">
            Jobs Category in Our Platform
        </div>
       <div className="jobListing-feature-card-wrapper">
        <div className="jobListing-feature-card">
              <Card title="Experienced" bordered={false} extra={<a href="#">60 Jobs</a>}>
                  Jobs for experienced professionals
              </Card>
          </div>
          <div className="jobListing-feature-card">
              <Card title="Freshers" bordered={false} extra={<a href="#">40 Jobs</a>}>
                Jobs for colleage graduates upto 1 Yrs of exp
              </Card>
          </div> 
          <div className="jobListing-feature-card">
              <Card title="Diversity Jobs" bordered={false} extra={<a href="#"> 300 Jobs</a>}>
                Professional jobs for women and females.
              </Card>
          </div>
          <div className="jobListing-feature-card">
              <Card title="Leadership Roles" bordered={false} extra={<a href="#"> 60 Jobs</a>}>
                Executive,Management level Openings 
              </Card>
          </div>
       </div>

       <div className="jobListing-feature-heading">
            Jobs Domains in Our Platform
        </div>       
       <div className="feature-carousil-wrapper">
           <div className="container">
                 <Slider {...settingsJobListing}>
                     <div>
                     <Card style ={contentStyleJobListing} hoverable bordered={true}cover={<img style = {{marginLeft:"10px",marginTop:"10px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Software and Tech Jobs" description="www.instagram.com/demo" />                          
                     </Card>
                     </div>
                     <div>
                     <Card style ={contentStyleJobListing} hoverable bordered={true}cover={<img style = {{marginLeft:"10px",marginTop:"10px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Hardware and VLSI Jobs" description="www.instagram.com/demo" />                                                 
                     </Card>                     
                     </div>
                     <div>
                     <Card style ={contentStyleJobListing} hoverable bordered={true}cover={<img style = {{marginLeft:"10px",marginTop:"10px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Enbedded Jobs" description="www.instagram.com/demo" />                                                
                     </Card>                     
                     </div> 
                     <div>
                     <Card style ={contentStyleJobListing} hoverable bordered={true}cover={<img style = {{marginLeft:"10px",marginTop:"10px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Teaching Jobs" description="www.instagram.com/demo" />                                                
                     </Card>                     
                     </div> 
                     <div>
                     <Card style ={contentStyleJobListing} hoverable bordered={true}cover={<img style = {{marginLeft:"10px",marginTop:"10px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="BFSI Jobs" description="www.instagram.com/demo" />                                                 
                     </Card>                     
                     </div> 
                     <div>
                     <Card style ={contentStyleJobListing} hoverable bordered={true}cover={<img style = {{marginLeft:"10px",marginTop:"10px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Mechanical Jobs" description="www.instagram.com/demo" />                                                
                     </Card>                     
                     </div> 
                     <div>
                     <Card style ={contentStyleJobListing} hoverable bordered={true}cover={<img style = {{marginLeft:"10px",marginTop:"10px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Research Jobs" description="www.instagram.com/demo" />                                               
                     </Card>                     
                     </div> 
                     <div>
                     <Card style ={contentStyleJobListing} hoverable bordered={true}cover={<img style = {{marginLeft:"10px",marginTop:"10px",height:"40px", width:"40px"}}alt="example" src={workflow} />} >
                         <Meta style = {{marginTop:10}} title="Purchasing Jobs" description="www.instagram.com/demo" />                                                 
                     </Card>                     
                     </div>                                                                                                                                                 
                 </Slider>
           </div>
       </div> 
       <div className="jobListing-feature-heading">
            Top Companies 
        </div>                                                     
       <div className="jobListing-feature-card-wrapper">
        <div className="jobListing-feature-card">
              <Card title="HMW Communications" bordered={false} extra={<a href="#">60 Jobs</a>}>
                  Logo
              </Card>
          </div>
          <div className="jobListing-feature-card">
              <Card title="Proxelera" bordered={false} extra={<a href="#"> 300 Jobs</a>}>
                  Logo
              </Card>
          </div>
          <div className="jobListing-feature-card">
              <Card title="HCL" bordered={false} extra={<a href="#"> 60 Jobs</a>}>
                  Logo
              </Card>
          </div>
       </div>

       </div>

       <div className="landing-footer">
          <Footer/>
       </div>         
    </div>
  );
}
