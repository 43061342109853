import React, { useState, useRef, useEffect } from "react";
import { Empty, Button, Checkbox, Select, Input } from "antd";
import { useNavigate } from "react-router-dom";

import "./superUserPlanTableSelectable.scss";
import useSuperUserInfoStore from "../../store/superuser.store";

export default function SuperUserPlanTableSelectable({
  isClickable = false,
  titleList,
  data,
  isEditField = true,
  editFieldHandler,
  setCurrentView,
  currentView,
  version
}) {

  const navigate = useNavigate();

  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [checkBoxData, setCheckBoxData] = useState([]);
  const [clicked, setClicked] = useState(false);
  const { updateSuperUserOfferPlans } = useSuperUserInfoStore();

  const ResetCheckBox = () => {
    var checkBoxResetArray = data?.map((idx) => {
      return {
        name: idx.name,
        product: idx.product,
        trialperiod: idx.trialperiod,
        maxusers: idx.maxusers,
        maxresumes: idx.maxresumes,
        maxjds: idx.maxjds,
        maxemails: idx.maxemails,
        vendorconnect: idx.vendorconnect,
        monthly: idx.monthly,
        yearly: idx.yearly,
        hyearly: idx.hyearly,
        quarterly: idx.quarterly,
        payasugo: idx.payasugo,
        addonuser: idx.addonuser,
        addonresumes: idx.addonresumes,
        addonemails: idx.addonemail,
        addonjds: idx.addonjds, 
        isSelected: false,                  
      };
    });
    setCheckBoxData(() => [...checkBoxResetArray]);
  };
  useEffect(() => {

    const checkBoxArray = data?.map((idx) => {
      return {
        name: idx.name,
        product: idx.product,
        trialperiod: idx.trialperiod,
        maxusers: idx.maxusers,
        maxresumes: idx.maxresumes,
        maxjds: idx.maxjds,
        maxemails: idx.maxemails,
        vendorconnect: idx.vendorconnect,
        monthly: idx.monthly,
        yearly: idx.yearly,
        payasugo: idx.payasugo,
        hyearly: idx.hyearly,
        quarterly: idx.quarterly,
        addonuser: idx.addonuser,
        addonresumes: idx.addonresumes,
        addonemails: idx.addonemail,
        addonjds: idx.addonjds, 
        isSelected: false, 
      };
    });
    setCheckBoxData(checkBoxArray);
  }, [data]);

  if (!data) {
    return <Empty style={{ margin: "60px" }} />;
  }

  function enableDbUpdate() {
    for (let idx = 0; idx < checkBoxData.length; idx++) {
      console.log(
        "enableDbUpdate checkBoxArray",
        idx,
        checkBoxData[idx].isSelected
      );
      if (checkBoxData[idx].isSelected) {
        return true;
      }
    }
    return false;
  }
  const onCheckBoxChange = (e) => {
    console.log(e.target);
    if (e.target.checked) {
      checkBoxData[e.target.value].isSelected = true;
      console.log("Setting checkBoxData", e.target.value, true, checkBoxData);
    } else {
      checkBoxData[e.target.value].isSelected = false;
      console.log("Setting checkBoxData", e.target.value, false);
    }
    if (enableDbUpdate() == true) {
      setUpdateAvailable(true);
    } else {
      setUpdateAvailable(false);
    }
    setCheckBoxData(() => [...checkBoxData]);
  };

  const OnSelectChange = (value, field) => {
    //console.log(`selected ${value}`);
    const answer_array = value.split("#");
    var designation = answer_array[1];
    var idx = answer_array[0];
    var idxInt = parseInt(idx);
    // checkBoxData[idxInt].Role = designation;
    setCheckBoxData(() => {
      const newArray = [...checkBoxData];
      if (field === "designation") {
        newArray[idxInt].Role = designation;
      } else if (field === "permission") {
        newArray[idxInt].Permission = designation;
      }
      return newArray;
    });
  };

  function getTimeNow() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    const currentDate = date + "-" + month + "-" + year;
    return currentDate;
  }

  const getUpdated = (rowIndex) => {
    return checkBoxData[rowIndex]?.isSelected;
  };

const encodeJson = (updateType,value) =>{

    let encodedJsonValues ={};
    //get company details
    encodedJsonValues._id="Not used"
    const userData = JSON.parse(localStorage.getItem("userDetails"));
    encodedJsonValues.qhoid= userData.ID;
    encodedJsonValues.accounttype= userData.account_type ;


    //now fill the parameters
    encodedJsonValues.planlist =[];
    encodedJsonValues.addlist =[];
    encodedJsonValues.resetlist =[];
    var num=0
    for(let idx = 0; idx < checkBoxData.length; idx++){
        console.log("checkBoxData", idx,checkBoxData[idx])
        if (checkBoxData[idx].isSelected && updateType =="UPDATE_PLAN" ) {
            encodedJsonValues.planlist[num]={};
            encodedJsonValues.planlist[num].planname =checkBoxData[idx].name;
            encodedJsonValues.planlist[num].product = checkBoxData[idx].product;
            encodedJsonValues.planlist[num].trialperiod =parseInt(checkBoxData[idx].trialperiod);
            encodedJsonValues.planlist[num].maxusers = parseInt(checkBoxData[idx].maxusers);
            encodedJsonValues.planlist[num].maxresumes =parseInt(checkBoxData[idx].maxresumes);
            encodedJsonValues.planlist[num].maxjds =parseInt(checkBoxData[idx].maxjds);
            encodedJsonValues.planlist[num].maxemails =parseInt(checkBoxData[idx].maxemails);
            encodedJsonValues.planlist[num].vendorconnect =checkBoxData[idx].vendorconnect;
            encodedJsonValues.planlist[num].monthly =parseFloat(checkBoxData[idx].monthly);
            encodedJsonValues.planlist[num].yearly =parseFloat(checkBoxData[idx].yearly);
            encodedJsonValues.planlist[num].hyearly =parseFloat(checkBoxData[idx].hyearly);
            encodedJsonValues.planlist[num].quarterly =parseFloat(checkBoxData[idx].quarterly);
            encodedJsonValues.planlist[num].payasugo =parseFloat(checkBoxData[idx].payasugo);
            encodedJsonValues.planlist[num].addonuser =parseFloat(checkBoxData[idx].addonuser);
            encodedJsonValues.planlist[num].addonresumes =parseFloat(checkBoxData[idx].addonresumes);
            encodedJsonValues.planlist[num].addonemail =parseFloat(checkBoxData[idx].addonemail);
            encodedJsonValues.planlist[num].addonjds =parseFloat(checkBoxData[idx].addonjds);
            num++;
        } 
        if (checkBoxData[idx].isSelected && updateType =="RESET_PLAN") {
            encodedJsonValues.resetlist[num]={};
            encodedJsonValues.resetlist[num].planname =data[idx].name;
            num++;
        }          
    }

    num=0
    if ( updateType =="ADD_PLAN") {
      encodedJsonValues.addlist[num]={};
      encodedJsonValues.addlist[num].planname ="New plan";
      encodedJsonValues.addlist[num].product ="";
      encodedJsonValues.addlist[num].trialperiod =30;
      encodedJsonValues.addlist[num].maxusers =0;
      encodedJsonValues.addlist[num].maxresumes =0;
      encodedJsonValues.addlist[num].maxjds =0;
      encodedJsonValues.addlist[num].maxemails =0;
      encodedJsonValues.addlist[num].vendorconnect ="Yes";
      encodedJsonValues.addlist[num].monthly =0;
      encodedJsonValues.addlist[num].yearly =0;
      encodedJsonValues.addlist[num].quarterly =0;
      encodedJsonValues.addlist[num].hyearly =0; 
      encodedJsonValues.addlist[num].payasugo =0;
      encodedJsonValues.addlist[num].addonuser =0;
      encodedJsonValues.addlist[num].addonresumes =0;
      encodedJsonValues.addlist[num].addonemail =0;
      encodedJsonValues.addlist[num].addonjds =0;
      num++;
    }             
    //get the current date
    let newDate = getTimeNow()
    encodedJsonValues.updatetype = updateType
    encodedJsonValues.updatedby= userData.qhuid;
    encodedJsonValues.updatedbyname= userData.username;
    encodedJsonValues.updatedon    = newDate;

    const today = new Date();
    const year = today.getFullYear();

    const version_array = version.split('/')
    encodedJsonValues.version = parseFloat(version_array[0])+1
    encodedJsonValues.version =  encodedJsonValues.version + "/"+ year ;
    localStorage.setItem("version", encodedJsonValues.version);
         
    return encodedJsonValues
}

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    const updatedValues = [...checkBoxData];
    updatedValues[index] = { ...updatedValues[index], [name]: value };
    console.log("handle change", updatedValues, event.target)
    setCheckBoxData(updatedValues);
  };

  const handleReset = (value) => {
    var jasonValues= encodeJson("RESET_PLAN",value)
    console.log("Encoded data for reseting the plan", jasonValues)
    updateSuperUserOfferPlans({
        jasonValues
    });
    ResetCheckBox();
    setUpdateAvailable(false);
}
const handleAddPlan = (value) => {
    var jasonValues= encodeJson("ADD_PLAN",value)
    console.log("Encoded data for adding Resume", jasonValues)
    updateSuperUserOfferPlans({
        jasonValues
    });
    ResetCheckBox();
    setUpdateAvailable(false);
}

const handleUpdatePlan = (value) => {
    var jasonValues= encodeJson("UPDATE_PLAN",value)
    console.log("Encoded data for upddating plan", jasonValues)
    updateSuperUserOfferPlans({
        jasonValues
    });
    ResetCheckBox();
    setUpdateAvailable(false);
}
const handleUpdateStatus= (value) => {
    var jasonValues= encodeJson("UPDATE_PLAN",value)
    console.log("Encoded data for updating plan", jasonValues)
    updateSuperUserOfferPlans({
        jasonValues
    });
    ResetCheckBox();
    setUpdateAvailable(false);
} 
  return (
    <div className="superuserplan-selectable-wrapper">
        <div className="rtable-selectable-btn-wrapper"  >
            {currentView == "documents" ?
               <>
               <Button
                  className="rtable-selectable-btn"
                  disabled={!updateAvailable}
                  onClick={handleUpdateStatus}
                >
                  Update Status
               </Button>                
               </>
               :<></>}
  
            {currentView == "plans"?
               <>
                <Button
                    className="rtable-selectable-btn"
                    disabled={!updateAvailable}
                    onClick={handleUpdatePlan}
                >
                  Update Plan
                </Button>
                <Button
                    className="rtable-selectable-btn"
                    disabled={!updateAvailable}
                    onClick={handleReset}
                >
                  Reset Plan
                </Button>   
                <Button
                    className="rtable-selectable-btn"
                    onClick={handleAddPlan}
                >
                  Add Plan
                </Button>              
            </> :<></> }                 
         </div>        
      <div
        className="rtable-title-wrapper"
        style={{
          gridTemplateColumns: `50px 50px 2fr 2fr repeat(${
            titleList.length - 4
          }, 2fr)`,
        }}
      >
        {titleList.map((title) =>
          title === "Select" ? (
            <div
              className="superuserPlanTableSelectable-title"
              onClick={() => {
                if (!clicked) {
                  const checkAllBox = checkBoxData.map((item) => ({
                    ...item,
                    isSelected: true,
                  }));
                  setCheckBoxData(checkAllBox);
                  setClicked(true);
                  setUpdateAvailable(true);
                } else {
                  const checkAllBox = checkBoxData.map((item) => ({
                    ...item,
                    isSelected: false,
                  }));
                  setCheckBoxData(checkAllBox);
                  setClicked(false);
                  setUpdateAvailable(false);
                }
              }}
            >
              {title}
            </div>
          ) : (
            <div className="superuserPlanTableSelectable-title">{title}</div>
          )
        )}
      </div>
      {data?.map((table, rowIndex) => (
        <div
          key={table.id}
          className="rtable-info-wrapper"
          style={{
            gridTemplateColumns: `50px 50px 2fr 2fr repeat(${
              titleList.length - 4
            }, 2fr)`,
            cursor: isClickable && "pointer",
          }}
        >
          {Object.keys(table)?.map((tableInfo, idx) => (
            <div className="rtable-info">
              {isEditField ? (
                <div className="edit-field-wrapper">
                  <>
                    {tableInfo === "id" ? rowIndex + 1 : <></>}
                    {/* {tableInfo != "id" &&
                    tableInfo != "select" &&
                    tableInfo != "candidateStatus" &&
                    tableInfo != "resumeStatus" &&
                    tableInfo != "interviewAction" &&
                    tableInfo != "interviewType" &&
                    tableInfo != "interviewStatus" ? (
                      table[tableInfo]
                    ) : (
                      <></>
                    )} */}

                    {tableInfo === "select" ? (
                      <Checkbox
                        value={rowIndex}
                        onChange={onCheckBoxChange}
                        checked={getUpdated(rowIndex)}
                      ></Checkbox>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "name" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: "auto",
                              height: 24,
                              textAlign: "center",
                            }}
                            name="name"
                            value={checkBoxData[rowIndex]?.name}
                            placeholder={checkBoxData[rowIndex]?.name}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "product" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            name="product"
                            value={checkBoxData[rowIndex].product}
                            placeholder={checkBoxData[rowIndex].product}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "trialperiod" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            type ="number"
                            name="trialperiod"
                            value={checkBoxData[rowIndex].trialperiod}
                            placeholder={checkBoxData[rowIndex].trialperiod}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "maxusers" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            name="maxusers"
                            type ="number"
                            value={checkBoxData[rowIndex].maxusers}
                            placeholder={checkBoxData[rowIndex].maxusers}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "maxresumes" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            name="maxresumes"
                            type ="number"
                            value={checkBoxData[rowIndex].maxresumes}
                            placeholder={checkBoxData[rowIndex].maxresumes}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "maxjds" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            name="maxjds"
                            type ="number"
                            value={checkBoxData[rowIndex].maxjds}
                            placeholder={checkBoxData[rowIndex].maxjds}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "maxemails" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            name="maxemails"
                            type ="number"
                            value={checkBoxData[rowIndex].maxemails}
                            placeholder={checkBoxData[rowIndex].maxemails}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "vendorconnect" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            name="vendorconnect"
                            type ="number"
                            value={checkBoxData[rowIndex].vendorconnect}
                            placeholder={checkBoxData[rowIndex].vendorconnect}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "monthly" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            name="monthly"
                            type ="number"
                            value={checkBoxData[rowIndex].monthly}
                            placeholder={checkBoxData[rowIndex].monthly}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "yearly" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            name="yearly"
                            type ="number"
                            value={checkBoxData[rowIndex].yearly}
                            placeholder={checkBoxData[rowIndex].yearly}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "hyearly" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            name="hyearly"
                            type ="number"
                            value={checkBoxData[rowIndex].hyearly}
                            placeholder={checkBoxData[rowIndex].hyearly}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}   
                    {tableInfo === "quarterly" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            name="quarterly"
                            type ="number"
                            value={checkBoxData[rowIndex].quarterly}
                            placeholder={checkBoxData[rowIndex].quarterly}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}                                              
                    {tableInfo === "payasugo" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            name="payasugo"
                            type ="number"
                            value={checkBoxData[rowIndex].payasugo}
                            placeholder={checkBoxData[rowIndex].payasugo}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}   
                    {tableInfo === "addonuser" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            name="addonuser"
                            type ="number"
                            value={checkBoxData[rowIndex].addonuser}
                            placeholder={checkBoxData[rowIndex].addonuser}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}                                                               
                    {tableInfo === "addonresumes" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            name="addonresumes"
                            type ="number"
                            value={checkBoxData[rowIndex].addonresumes}
                            placeholder={checkBoxData[rowIndex].addonresumes}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}   
                    {tableInfo === "addonemail" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            name="addonemail"
                            type ="number"
                            value={checkBoxData[rowIndex].addonemail}
                            placeholder={checkBoxData[rowIndex].addonemail}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {tableInfo === "addonjds" ? (
                      <>
                        {getUpdated(rowIndex) === true ? (
                          <Input
                            style={{
                              width: 200,
                              height: 24,
                              textAlign: "center",
                            }}
                            name="addonjds"
                            type ="number"
                            value={checkBoxData[rowIndex].addonjds}
                            placeholder={checkBoxData[rowIndex].addonjds}
                            onChange={(event) => handleChange(event, rowIndex)}
                          />
                        ) : (
                          table[tableInfo]
                        )}
                      </>
                    ) : (
                      <></>
                    )}                                            
                    {tableInfo === "onboardingDate" ? table[tableInfo] : <></>}

                  </>
                </div>
              ) : (
                table[tableInfo]
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
