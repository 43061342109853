import React, { useState } from "react";

import { actions } from "../../actions";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Empty, Card ,Statistic} from "antd";

import "./forWho.css";


function ForWho (props)  {
  const [loader, SetLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [error,setError] = useState("")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleSubmit } = props;
  const onSubmit = () => {
    console.log("on submit called");
    let dataToSend = { email: email };
    let inputData = { email: email };
    console.log("email", email);
    SetLoader(true);

    dispatch(actions.SignIn(dataToSend, (error, status) => {
      if (!error) {
        navigate("/otp", { state: { inputData, type: "login" } });
        SetLoader(false);
      } else {
        // Handle the error case
        SetLoader(false);
        console.error("API Error:", error);
        let e = error.split("or")
        setError(e[0] + "signup first")
       // setErrorMessage(error); // Set the error message in state
      }
    }));
  };

  const handleSignin = () => {
    navigate('/login')
  };

  const handleSignup = () => {
    navigate('/signup')
  };
  return (
     <div className="forWho-wrapper">
        <div className="forWho-heading">
           Who should use Quallyhire AI
        </div>
        <div className="forWho-card-wrapper">
        <div className="forWho-card">
            <Card title="Startup/MNC Companies" bordered={false}>
                Get the best talent without wasteing too much time on Hiring Process. 
            </Card>
        </div>
        <div className="forWho-card">
            <Card title="Govt/Large Organization" bordered={false}>
              Manage Large number of applations and process them simulatenously. 
            </Card>
        </div>
        <div className="forWho-card">
            <Card title="Staffing/Recruitment Companies" bordered={false}>
               Improve your teams productivity and selection ratios
            </Card>
        </div>                                  
        </div>
        <div className="forWho-stats-wrapper">
          <Statistic title="Companies" value={112893} />
          <Statistic title="Recruiters" value={112893} />
          <Statistic title="Vendors" value={112893} />
          <Statistic title="Resumes" value={112893} />
          <Statistic title="Forms" value={112893} />
          <Statistic title="Evaluations" value={112893} />
        </div>                                                                    
     </div>
  );
};

export default ForWho;
