import React, { useState,useRef,useEffect } from "react";

import { useNavigate } from "react-router-dom";
import "./teamAssignmentCard.scss";
import TeamAssignmentCardStatsView from "../teamAssignmentCardStatsView";

export default function TeamAssignmentCard({
    data,
    all,
    index
}) {
    const navigate = useNavigate();
    const inputRef = useRef(null);
    console.log("Team Assignment Card",data)

    const focusInputField = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const getResumeQuality =(qualityStatus)=>{
        const candidateMap2 = new Map([
            ["Resumes Suitable", qualityStatus?.numgoodmatch],
            ["Resumes Not Suitable", qualityStatus?.numnotsuitable],
            ["Resume Average", qualityStatus?.numaverage],
            ["Resumes Below Average", qualityStatus?.numbelowaverage],
            ["Resumes Above Average", qualityStatus?.numaboveaverage],
        ]);
        return candidateMap2
    }
    const getCandidateStatusMap1 =(candidateStatus)=>{
        var candidateMap1;
        if(all){
            candidateMap1 = new Map([
                ["Total Candidates", candidateStatus?.NoOfResumesSourced],
                ["To Be Called", candidateStatus?.NoOfResumesToBeCalled],
                ["Not Picking", candidateStatus?.NoOfResumesNotPicking],
                ["Disconnected", candidateStatus?.NoOfResumesDisconnected],
                ["Busy", candidateStatus?.NoOfResumesBusy],
                ["OOS", candidateStatus?.NoOfResumesOOS],
                ["Call Later", candidateStatus?.NoOfResumesCallLater],
                ["Not Interested", candidateStatus?.NoOfResumesNotInterested],
                ["Interview within 6 months", candidateStatus?.NoOfResumesInterviewdIn6Months]
            ]);
        }else{
            candidateMap1 = new Map([
                ["Sourced", candidateStatus?.NoOfResumesSourced],
                ["To Be Called", candidateStatus?.NoOfResumesToBeCalled],
                ["Not Picking", candidateStatus?.NoOfResumesNotPicking],
                ["Disconnected", candidateStatus?.NoOfResumesDisconnected],
                ["Busy", candidateStatus?.NoOfResumesBusy],
                ["OOS", candidateStatus?.NoOfResumesOOS],
                ["Call Later", candidateStatus?.NoOfResumesCallLater],
                ["Not Interested", candidateStatus?.NoOfResumesNotInterested],
                ["Interview within 6 months", candidateStatus?.NoOfResumesInterviewdIn6Months]
            ]);
        }

        return candidateMap1
    } 
    const getCandidateStatusMap2 =(candidateStatus)=>{
        const candidateMap2 = new Map([
            ["JD Needed", candidateStatus?.NoOfResumesJDNeeded],
            ["Available", candidateStatus?.NoOfResumesAvailable],
            ["Available_HCTC", candidateStatus?.NoOfResumesAvailableHCTC],
            ["Available_HNP", candidateStatus?.NoOfResumesAvailableHNP],
            ["Direct Payroll", candidateStatus?.NoOfResumesClientDirectPayroll],
            ["Already Applied", candidateStatus?.NoOfResumesAlreadyApplied],
            ["Already Got Call", candidateStatus?.NoOfResumesAlreadyGotCall],
            ["Interview before 6 months", candidateStatus?.NoOfResumesInterviewdBefore6Months],
            ["Location Issue", candidateStatus?.NoOfResumesLocationIssue]
        ]);
        return candidateMap2
    }

    const getResumeStatus =(resumeStatus)=>{
        const resumeMap = new Map([
            ["Shared", resumeStatus?.NoOfShared],
            ["ReviewPending", resumeStatus?.NoOfReviewPending],
            ["To Be Shared", resumeStatus?.NoOfToBeShared],
            ["FB Pending", resumeStatus?.NoOfFBPending],
            ["Client FB Pending", resumeStatus?.NoOfClientFBPending],
            ["Screen Rejected", resumeStatus?.NoOfScreenRejected],
            ["Duplicate", resumeStatus?.NoOfDuplicate],
            ["Interview Rejected", resumeStatus?.NoOfInterviewRejected],
            ["Screen Selected", resumeStatus?.NoOfScreenSelected]
        ]);
        return resumeMap
    }

    const getInterviewType =(interviewType)=>{
        const typeMap = new Map([
            ["L0 Interview", interviewType?.NoOfInterviewTypeL0],
            ["L1 Interview", interviewType?.NoOfInterviewTypeL1],
            ["L2 Interview", interviewType?.NoOfInterviewTypeL2],
            ["Customer Interview", interviewType?.NoOfInterviewTypeCustomer],
            ["HR Interview", interviewType?.NoOfInterviewTypeHR],
            ["MgMT Interview", interviewType?.NoOfInterviewTypeMGMT]
        ]);
        return typeMap
    } 
    const getInterviewAction =(interviewAction)=>{
        const actionMap = new Map([
            ["To Schedule", interviewAction?.NoOfInterviewActionToSchedule],
            ["Scheduled", interviewAction?.NoOfInterviewActionScheduled],
            ["To Rescheduled", interviewAction?.NoOfInterviewActionToReSchedule],
            ["Rescheduled", interviewAction?.NoOfInterviewActionRescheduled],
            ["Cancelled", interviewAction?.NoOfInterviewActionCancelled],
            ["Done", interviewAction?.NoOfInterviewActionDone]
        ]);
        return actionMap
    }     
    
    const getInterviewStatus =(interviewStatus)=>{
        const statusMap = new Map([
            ["Planned", interviewStatus?.NoOfInterviewStatusPlanned],
            ["No Panel", interviewStatus?.NoOfInterviewStatusNoPanel],
            ["Candidate Drop", interviewStatus?.NoOfInterviewStatusCandidateDrop],
            ["Candidate NP", interviewStatus?.NoOfInterviewStatusCandidateNP],
            ["Panel No Joined", interviewStatus?.NoOfInterviewStatusPanelNotJoined],
            ["Done", interviewStatus?.NoOfInterviewStatusDone],
            ["No Show", interviewStatus?.NoOfInterviewStatusNoShow],
            ["Selected", interviewStatus?.NoOfInterviewStatusSelected],
            ["Hold", interviewStatus?.NoOfInterviewStatusHold],
            ["Rejected", interviewStatus?.NoOfInterviewStatusRejected],
            ["Final Select", interviewStatus?.NoOfInterviewStatusFinalSelect]
        ]);
        return statusMap
    } 

    const getSelectionStatus =(selectionStatus)=>{
        const statusMap = new Map([
            ["Selected", selectionStatus?.numfinalselected],
            ["Offered", selectionStatus?.numoffered],
            ["Offer Declined", selectionStatus?.numdeclined],
            ["Joined", selectionStatus?.numjoined],
            ["Joined Elsewhere", selectionStatus?.numjoinedelsewhere],
            ["Offer On Hold", selectionStatus?.numofferonhold],
            ["BGV Ongoing", selectionStatus?.numdocumentationongoing],
            ["BGV Fail", selectionStatus?.numdocumentproblems]           
        ]);
        return statusMap
    }       

    useEffect(() => {
    }, []);

    const handleJDClick = (id) => {
        navigate(`/jds/${id}`);
    };
   
    return (

    <div className= "teamAssignmentCard-wrapper">
        <div className="teamAssignmentCard-JD-title-wrapper">
            <div  className="param_wrapper_0"> 
                <div className="param_wrapper_0_0">
                    {index+1} <span style={{padding:"5px",marginRight:"5px"}}>{ '< '+data.client+' / '}{data.ta +' > '}</span> 
                    <span style={{padding:"5px",marginRight:"5px"}}>{' ['+ data.qhcompanyjid+']  :     '} </span> {data.title}
                </div>
                <div className="param_wrapper_0_1">
                    <span style={{backgroundColor: "#fff",padding:"5px",marginRight:"5px"}}>{ data.spoc == ""?"SPOC : Not Assigned":'SPOC : '+data.spoc}</span> 
                </div>
            </div>              
        </div>
        
        {data.numapplication == 0 ?<></>:
            <div className="teamAssignmentCard-Stats-wrapper">
                <TeamAssignmentCardStatsView data={ getResumeQuality(data?.resumequalitystatus)} type={"Resume Quality"} analyticsAction={"IMPROVE"}/>
                <TeamAssignmentCardStatsView data={ getCandidateStatusMap1(data?.candidatestatus)} type={"Candidate Status"} analyticsAction={"IMPROVE"}/>
                <TeamAssignmentCardStatsView data={ getCandidateStatusMap2(data?.candidatestatus)} type={"Candidate Status"} analyticsAction={"IMPROVE"}/>
                <TeamAssignmentCardStatsView data={ getResumeStatus(data?.resumestatus)} type={"Resume Status"} analyticsAction={"IMPROVE"}/>
                <TeamAssignmentCardStatsView data={ getInterviewType(data?.interviewtype)} type={"Interview Type"} analyticsAction={"IMPROVE"}/>
                <TeamAssignmentCardStatsView data={ getInterviewAction(data?.interviewAction)} type={"Interview Action"} analyticsAction={"IMPROVE"}/>
                <TeamAssignmentCardStatsView data={ getInterviewStatus(data?.interviewStatus)} type={"Interview Status"} analyticsAction={"IMPROVE"}/>
                <TeamAssignmentCardStatsView data={ getSelectionStatus(data?.offers)} type={"Selections"} analyticsAction={"IMPROVE"}/>
    
            </div>  
        }         
    </div>
    );
}
