import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "../../assets/QuallyHireGreen.png";
import { Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";

import "./header.css";


function LandingHeader (props)  {

  const navigate = useNavigate();

  const items = [
    {
      label: (
        <Link className="header-left-link" to="/qhrflow">
          QHRFlow
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <Link className="header-left-link" to="/qhrflowvendorconnect">
          VendorConnect
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <Link className="header-left-link" to="/jobSeekers">
          Explorer
        </Link>
      ),
      key: "2",
    },
    {
      label: (
        <Link className="header-left-link" to="/careerWebsites">
          CareerLinks
        </Link>
      ),
      key: "3",
    },
    {
      label: (
        <Link className="header-left-link" to="">
          BenchHire Platform
        </Link>
      ),
      key: "4",
    },
    {
      label: (
        <Link className="header-left-link" to="">
          Employee Register and Leave Mgmt.
        </Link>
      ),
      key: "5",
    },
  ];


  const handleContactSales = () => {

  };

  const handleNews = () => {
  };

  const handleResumeAnalysisFree = () => {
    navigate('/resumeAnalysisFree')
  };

  return (
    <div className="landing-wrapper">
      <div className="landing-header">
        <div className="landing-header-left">
          <div className="header-logo">
              <img className="header-logo-img"src={logo} />
              <div className="header-logo-name">
                  Quallyhire AI
              </div>
          </div>
          <div className="header-menu">
            <ul className="header-menu">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <Dropdown
                  menu={{
                    items,
                  }}
                >
                  <a
                    onClick={(e) => e.preventDefault()}
                    style={{ color: "black" }}
                  >
                    <Space>
                      Products
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
              </li>
              <li>
                Support
              </li>                                          
            </ul>
          </div>
        </div>
        <div className="header-sign">
          <Button className="sign-button" onClick={() => handleResumeAnalysisFree()}>
            Resume Analysis
          </Button>
          <Button className="sign-button" onClick={() => handleNews()}>
            News
          </Button>
          <Button className="sign-button" onClick={() => handleContactSales()}>
            Contact Sales For Pricing
          </Button>          
        </div>
      </div>     
     </div>
  );
};

export default LandingHeader;
