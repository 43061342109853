import React, { useState,useRef ,useEffect} from "react";
import { Empty, Button,Checkbox,Select,Input,DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
import moment from 'moment'
import "./superuserPriceTableSelectable.scss";
import useSuperUserInfoStore from "../../store/superuser.store";

export default function SuperUserPriceTableSelectable({
    isClickable = false,
    titleList,
    data,
    isEditField = false,
    editFieldHandler,
    setCurrentView,
    selectable,
    currentView
}) {
  const navigate = useNavigate();
  // Requiring the module
  const moment = require('moment');
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [checkBoxData, setCheckBoxData] = useState([]);
  const { updateSuperUserDocumentStatus } = useSuperUserInfoStore();
  useEffect(() => {
    
    const checkBoxArray = data?.map((item,idx) => {
      return {
        type: "",
        selected: false,
        status: "",
        startdate:item.startdate,
        trialperiod:item.tiralperiod,
        duration:item.duration,
        endate:item.enddate,
        ndiscount:item.ndiscount,
        rdiscount:item.rdiscount,
        finalprice:item.finalprice,
        trialstatus:item.trialstatus,
        substatus:item.substatus,
        paymentstatus:item.paymentstatus,
        planamount:item.planamount
      };
    });
    setCheckBoxData(checkBoxArray);
  }, [data]);


  const ResetCheckBox = () => {
    var checkBoxResetArray = checkBoxData?.map((item,idx) => {
      return {
        type: "",
        selected: false,
        status: "",
        startdate:item.startdate,
        enddate:item.enddate,
        trialperiod:item.trialperiod,
        duration:item.duration,
        ndiscount:item.ndiscount,
        rdiscount:item.rdiscount,
        finalprice:item.finalprice,
        trialstatus:item.trialstatus,
        substatus:item.substatus,
        paymentstatus:item.paymentstatus,
        planamount:item.planamount
      };
    });
    setCheckBoxData(() => [...checkBoxResetArray]);
  };

    if (!data) {
        return <Empty style={{ margin: "60px" }} />;
    }

    function enableDbUpdate (){
        for(let idx = 0; idx < checkBoxData.length; idx++){
            console.log("enableDbUpdate checkBoxArray", idx,checkBoxData[idx].selected)      
            if (checkBoxData[idx].selected ) {
                return true
            } 
        }
        return false
    }
    const onCheckBoxChange = (e) => {
        if (e.target.checked) {
            checkBoxData[e.target.value].selected = true; 
            console.log("Setting checkBoxData", e.target.value,true,checkBoxData)      
        }else{
            checkBoxData[e.target.value].selected = false; 
            console.log("Setting checkBoxData", e.target.value,false) 
        }
        if (enableDbUpdate() == true){
            setUpdateAvailable(true)

        }else{
            setUpdateAvailable(false)
        } 
        setCheckBoxData(()=>([...checkBoxData]))
      }

    const subStatusStateMachine = (rowIndex) =>{
        var statusDropDown

        if (data?.[rowIndex]?.substatus == "INACTIVE") {
            statusDropDown= [
                { value: rowIndex + '#' +'subscription_activation_in_progress', label: 'ACTIVATE' },
            ]   
        }else if (data?.[rowIndex]?.substatus == "ACTIVATION IN PROGRESS") {
            statusDropDown= [
               { value: rowIndex + '#' +'subscription_stopped', label: 'STOP' },
            ] 
        }else if (data?.[rowIndex]?.substatus == "ACTIVE") {
            statusDropDown= [
               { value: rowIndex + '#' +'subscription_stopped', label: 'STOP' },
            ] 
        }

        console.log("Sub Status",data?.[rowIndex]?.substatus);
        return statusDropDown
    }

    const paymentStatusStateMachine = (rowIndex) =>{
        var statusDropDown

        if (data?.[rowIndex]?.paymentstatus == "NA") {
            statusDropDown= [
                { value: rowIndex + '#' +'payment_req', label: 'REQUEST PAYMENT' },
            ]   
        }else if (data?.[rowIndex]?.paymentstatus == "payment_revise") {
            statusDropDown= [
                { value: rowIndex + '#' +'payment_req', label: 'REQUEST PAYMENT' },
            ]   
        }
        console.log("payment Status",data?.[rowIndex]?.paymentstatus);
        return statusDropDown
    }

    const OnSelectChangeSubStatus = (value) => {
        //console.log(`selected ${value}`);
        const answer_array = value.split('#');
        var status = answer_array[1]
        var idx = answer_array[0]
        var idxInt = parseInt(idx)
        checkBoxData[idxInt].status = status
        checkBoxData[idxInt].type = "SUB_STATUS"
        //if(status === "Available"){
        //    handleResumeDetailClick(data[idxInt].qhid)
        //}
    }
    const OnSelectChangePaymentStatus = (value) => {
        //console.log(`selected ${value}`);
        const answer_array = value.split('#');
        var status = answer_array[1]
        var idx = answer_array[0]
        var idxInt = parseInt(idx)
        checkBoxData[idxInt].status = status
        checkBoxData[idxInt].type = "PAYMENT_STATUS"
        //if(status === "Available"){
        //    handleResumeDetailClick(data[idxInt].qhid)
        //}

        const updatedValues = [...checkBoxData];
        updatedValues[idxInt] = { ...updatedValues[idxInt], ["paymentstatus"]: status };
        setCheckBoxData(updatedValues);
    }


    function getTimeNow(){
        const today = new Date();
        const month = today.getMonth()+1;
        const year = today.getFullYear();
        const date = today. getDate();
        const currentDate = date + "-" + month + "-" + year;
        return currentDate
    }
    const encodeJson = (updateType) =>{

        let encodedJsonValues ={};
        
        //get company details
        encodedJsonValues._id="Not used"
        const userData = JSON.parse(localStorage.getItem("userDetails"));
        encodedJsonValues.qhoid= userData.ID;
        encodedJsonValues.accounttype= userData.account_type ;


        //now fill the parameters
        encodedJsonValues.documentlist =[];
        var num=0
        for(let idx = 0; idx < checkBoxData.length; idx++){
            console.log("checkBoxData", idx,checkBoxData[idx].selected)
            if (checkBoxData[idx].selected && updateType =="DOCUMENT_STATUS" ) {
                encodedJsonValues.documentlist[num]={};
                encodedJsonValues.documentlist[num].status =checkBoxData[idx].status;
                encodedJsonValues.documentlist[num].name =data[idx].name;
                encodedJsonValues.documentlist[num].type =data[idx].type;
                num++;
            }          
        }        
        //get the current date
        let newDate = getTimeNow()
        encodedJsonValues.updatetype = updateType
        encodedJsonValues.updatedby= userData.qhuid;
        encodedJsonValues.updatedbyname= userData.username;
        encodedJsonValues.updatedon    = newDate;
             
        return encodedJsonValues
    }

    const getUpdated=(rowIndex)=>{
        return checkBoxData?.[rowIndex]?.selected 
    }
    const checkStatus=(rowIndex)=>{
        if(currentView == "free"){
            if(data?.[rowIndex]?.trialstatus === "ACTIVE")
                return false
        }else if (currentView == "paid"){
            if(data?.[rowIndex]?.substatus === "ACTIVE")
                return false
        }
        return true
    }
    const checkPaymentStatus=(rowIndex)=>{
        if (currentView === "paid"){
            if(data?.[rowIndex]?.paymentstatus === "PAYMENT DONE")
                return true
        }
        return false
    }
    
    const handleUpdateStatus = (value) => {
        var jasonValues= encodeJson("DOCUMENT_STATUS")
        console.log("Encoded data for updating the status", jasonValues)
        updateSuperUserDocumentStatus({
            jasonValues
        });
        ResetCheckBox();
        setUpdateAvailable(false);
    }

    return (
        <div className="superusetable-selectable-wrapper">
            <div className="rtable-selectable-btn-wrapper"  >
                {currentView == "payments" ?
                <>
                    <Button
                        className="rtable-selectable-btn"
                        disabled={!updateAvailable}
                        onClick={handleUpdateStatus}
                    >
                    Save Changes
                    </Button>                
                </>
                :<></>}                
            </div>
            <div
                className="rtable-title-wrapper"
                style={{
                    gridTemplateColumns: `50px 50px 5fr repeat(${titleList.length-3}, 5fr)`
                }}
            >
                {titleList.map((title) => (
                    <div className="superuser-table-title">{title}
                    </div>
                ))}
            </div>
            {data?.map((table, rowIndex) => (
                <div
                    key={table.id}
                    className="rtable-info-wrapper"
                    style={{
                        gridTemplateColumns: `50px 50px 5fr repeat(${titleList.length-3}, 5fr)`,
                        cursor: isClickable && "pointer",
                    }}
                >
                    {Object.keys(table)?.map((tableInfo, idx) => (
                        <div className="rtable-info">
                            {isEditField ? (
                                <div className="edit-field-wrapper">
                                        <>
                                            {tableInfo === "id"
                                                ? rowIndex + 1
                                                : <></>}
                                                
                                            {(tableInfo != "id") &&(tableInfo != "select")
                                             &&(tableInfo != "paymentstatus") 
                                                ? table[tableInfo]
                                                : <></>}

                                             { tableInfo === "select" ?checkStatus(rowIndex)===true?
                                                <Checkbox value = {rowIndex} onChange={onCheckBoxChange}></Checkbox>
                                               : <></>:<></>
                                             }  
                                        
                                             { tableInfo === "paymentstatus" ? (
                                                    <>
                                                        {getUpdated(rowIndex) === true?data?.[rowIndex].substatus=="configured"?
                                                        <Select
                                                            style={{ width: 130,height: 24 }}
                                                            onChange={OnSelectChangePaymentStatus}
                                                            placeholder={table[tableInfo]}                                                             
                                                            options={paymentStatusStateMachine(rowIndex)}
                                                        /> :table[tableInfo]:
                                                        table[tableInfo]==""?"--":table[tableInfo]}    
                                                    </> 
                                               )                                             
                                               : <></>
                                             }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
                                        </>

                                </div>
                            ) : (
                                table[tableInfo]==""?"--":table[tableInfo]
                            )}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}
