import React, { useState,useRef ,useEffect} from "react";
import { Empty, Button,Checkbox,Select } from "antd";
import { useNavigate } from "react-router-dom";

import "./commTableSelectable.scss";
import useResumeStore from "../../store/resume.store";

export default function CommTableSelectable({
    isClickable = false,
    titleList,
    data,
    isResumePage = false,
    isEditField = false,
    editFieldHandler,
    setCurrentView,
    resumeProfileHandler,
    qhjid,
    selectable
}) {
    const navigate = useNavigate();
    const checkBoxArray=data?.map((idx)=>{
        return {
            type:"",
            selected: false,
            status: "To Be Called"  }
    })
    const [updateAvailable, setUpdateAvailable] = useState(false);
    const [checkBoxData, setCheckBoxData] = useState(checkBoxArray);
    const { CandidateCommunication } =
    useResumeStore();


    const handleResumeDetailClick = (id,qhID) => {
        resumeProfileHandler(qhjid,id, qhID,)
        setCurrentView()    
    };

    const ResetCheckBox = () => {
        var checkBoxResetArray=data?.map((idx)=>{
            return {
                type:"",
                selected: false,
                status: "To Be Called"  }
        })
        setCheckBoxData(()=>([...checkBoxResetArray]))
    };
    useEffect(() => {
        console.log("Selected JD and Data", qhjid,data)   
        setCheckBoxData(checkBoxArray)
    }, []);

    if (!data) {
        return <Empty style={{ margin: "60px" }} />;
    }

    function enableDbUpdate (){
        for(let idx = 0; idx < checkBoxData.length; idx++){
            console.log("enableDbUpdate checkBoxArray", idx,checkBoxData[idx].selected)      
            if (checkBoxData[idx].selected ) {
                return true
            } 
        }
        return false
    }
    const onCheckBoxChange = (e) => {
        if (e.target.checked) {
            checkBoxData[e.target.value].selected = true; 
            console.log("Setting checkBoxData", e.target.value,true,checkBoxData)      
        }else{
            checkBoxData[e.target.value].selected = false; 
            console.log("Setting checkBoxData", e.target.value,false) 
        }
        if (enableDbUpdate() == true){
            setUpdateAvailable(true)

        }else{
            setUpdateAvailable(false)
        } 
        setCheckBoxData(()=>([...checkBoxData]))
      }

    const OnSelectChange = (value) => {
        //console.log(`selected ${value}`);
        const answer_array = value.split('#');
        var status = answer_array[1]
        var idx = answer_array[0]
        var idxInt = parseInt(idx)
        checkBoxData[idxInt].status = status
        checkBoxData[idxInt].type = "CANDIDATE_STATUS"
        //if(status === "Available"){
        //    handleResumeDetailClick(data[idxInt].qhid)
        //}
    }

    const OnSelectChangeResume = (value) => {
        //console.log(`selected ${value}`);
        const answer_array = value.split('#');
        var status = answer_array[1]
        var idx = answer_array[0]
        var idxInt = parseInt(idx)
        checkBoxData[idxInt].status = status
        checkBoxData[idxInt].type = "RESUME_STATUS"
        //if(status === "Available"){
        //    handleResumeDetailClick(data[idxInt].qhid)
        //}
    }

    function getTimeNow(){
        const today = new Date();
        const month = today.getMonth()+1;
        const year = today.getFullYear();
        const date = today. getDate();
        const currentDate = date + "-" + month + "-" + year;
        return currentDate
    }
    const encodeJson = (type) =>{

        let encodedJsonValues ={};
        var updateType=""

        //get company details
        encodedJsonValues._id="Not used"
        encodedJsonValues.qhglobaljid="Not USed"
        const userData = JSON.parse(localStorage.getItem("userDetails"));
        encodedJsonValues.qhoid= userData.ID;
        encodedJsonValues.accounttype= userData.account_type ;
        encodedJsonValues.qhjid= qhjid;

        encodedJsonValues.candidatelist =[]
        var num=0
        for(let idx = 0; idx < checkBoxData.length; idx++){
            console.log("checkBoxData", idx,checkBoxData[idx].selected,data[idx].candidatestatus)
            if (checkBoxData[idx].selected && type =="EMAIL_INTEREST" ) {
                if((data[idx].candidatestatus != "AVAILABLE")&& (data[idx].candidatestatus != "AVAILABLE_HCTC")&&(
                    data[idx].candidatestatus != "AVAILABLE_HNP")){
                    encodedJsonValues.candidatelist[num]={};
                    encodedJsonValues.candidatelist[num].qhrid =data[idx].qhid;
                    num++;
                    updateType = "EMAIL_INTEREST" 
                }                
            }
            if (checkBoxData[idx].selected && type =="EMAIL_SLOT_REQ" ) {
                //currently not supported
                encodedJsonValues.candidatelist[num]={};
                encodedJsonValues.candidatelist[num].qhrid =data[idx].qhid;

                num++;
                updateType = "EMAIL_SLOT_REQ" 
            } 
            if (checkBoxData[idx].selected && type =="EMAIL_INTERVIEW_REMINDER" ) {
                if( data[idx].interviewaction == "INTERVIEW_ACTION_SCHEDULED"){
                    encodedJsonValues.candidatelist[num]={};
                    encodedJsonValues.candidatelist[num].qhrid =data[idx].qhid;
                    num++;
                    updateType = "EMAIL_INTERVIEW_REMINDER"
                }
            }
            if (checkBoxData[idx].selected && type =="EMAIL_FEEDBACK_REQ" ) {
                encodedJsonValues.feedbacklist[num]={};
                if(( data[idx].interviewaction == "Interview Feedback Pending")
                    ||(data[idx].interviewstatus == "Feedback Pending")){
                        encodedJsonValues.candidatelist[num]={};
                        encodedJsonValues.candidatelist[num].qhrid =data[idx].qhid;
                        num++;
                        updateType = "EMAIL_FEEDBACK_REQ"
                }
            }            
        }        
        //get the current date
        let newDate = getTimeNow()

        encodedJsonValues.updatetype = updateType
        encodedJsonValues.updatedby = {}
        encodedJsonValues.updatedby.type = "team"
        encodedJsonValues.updatedby.id= userData.qhuid;
        encodedJsonValues.updatedby.name= userData.username;
        encodedJsonValues.updatedby.on    = newDate;
             
        return encodedJsonValues
    }

    const getUpdated=(rowIndex)=>{
        return checkBoxData?.[rowIndex]?.selected 
    }
    const handleEmailInterest = (value) => {
        var jasonValues= encodeJson("EMAIL_INTEREST")
        CandidateCommunication({
            jasonValues,qhjid
        });
        ResetCheckBox();
    }
    const handleSlotMail = (value) => {
        var jasonValues= encodeJson("EMAIL_SLOT_REQ")
        CandidateCommunication({
            jasonValues,qhjid
        });
        ResetCheckBox();
    }

    const handleInterviewReminder = (value) => {
        var jasonValues= encodeJson("EMAIL_INTERVIEW_REMINDER")
        CandidateCommunication({
            jasonValues,qhjid
        });
        ResetCheckBox();
    }

    const handlefeedback = (value) => {
        var jasonValues= encodeJson("EMAIL_FEEDBACK_REQ")
        CandidateCommunication({
            jasonValues,qhjid
        });
        ResetCheckBox();
    }
    return (
        <div className="rtable-selectable-wrapper">
            <div className="rtable-selectable-btn-wrapper"  >
                <Button
                    className="rtable-selectable-btn"
                    disabled={!updateAvailable}
                    onClick={handleEmailInterest}
                >
                  Interest Mail
                </Button>
                <Button
                    className="rtable-selectable-btn"
                    disabled={!updateAvailable}
                    onClick={handleInterviewReminder}
                >
                  Interview Reminder Mail
                </Button>
                <Button
                    className="rtable-selectable-btn"
                    disabled={!updateAvailable}
                    onClick={handleInterviewReminder}
                >
                  Feedback Status Mail
                </Button>                               
            </div>
            <div
                className="rtable-title-wrapper"
                style={{
                    gridTemplateColumns: `50px 50px 1fr 2fr 1fr 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr`
                }}
            >
                {titleList.map((title) => (
                    <div className="rtable-title">{title}
                    </div>
                ))}
            </div>
            {data?.map((table, rowIndex) => (
                <div
                    key={table.id}
                                    
                    className="rtable-info-wrapper"
                    style={{
                        gridTemplateColumns: `50px 50px 1fr 2fr 1fr 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr`,
                        cursor: isClickable && "pointer",
                    }}
                >
                    {Object.keys(table)?.map((tableInfo, idx) => (
                        <div className="rtable-info"
                               onClick={tableInfo === "resumeSuitability" ? () => handleResumeDetailClick(table.id, table.qhid) : null }  
                          >
                            {isEditField ? (
                                <div className="edit-field-wrapper">
                                        <>
                                            {tableInfo === "id"
                                                ? rowIndex + 1
                                                : <></>}
                                                
                                            {(tableInfo != "id") &&(tableInfo != "select")&&
                                             (tableInfo != "candidateStatus")&&(tableInfo != "resumeStatus")&&
                                             (tableInfo != "interested")&&(tableInfo != "slot")&&
                                             (tableInfo != "interview")
                                                ? table[tableInfo]
                                                : <></>}
                                             { tableInfo === "select" ?
                                                <Checkbox value = {rowIndex} onChange={onCheckBoxChange}></Checkbox>
                                               : <></>
                                             }                                                                                                                                                                                         
                                        </>

                                </div>
                            ) : (
                                table[tableInfo]==""?"--":table[tableInfo]
                            )}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}
