import React, { useState,useRef ,useEffect} from "react";
import { Empty, Button,Checkbox,Select,Input,DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
import moment from 'moment'
import "./superuserTableSelectable.scss";
import useSuperUserInfoStore from "../../store/superuser.store";

export default function SuperUserTableSelectable({
    isClickable = false,
    titleList,
    data,
    isEditField = false,
    editFieldHandler,
    setCurrentView,
    selectable,
    currentView
}) {
  const navigate = useNavigate();
  // Requiring the module
  const moment = require('moment');
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [checkBoxData, setCheckBoxData] = useState([]);
  const { updateSuperUserDocumentStatus } = useSuperUserInfoStore();
  useEffect(() => {
    console.log("data", data)
    const checkBoxArray = data?.map((item,idx) => {
      return {
        type: "",
        selected: false,
        status: "",
        startdate:item.startdate,
        trialperiod:item.tiralperiod,
        duration:item.duration,
        endate:item.enddate,
        ndiscount:item.ndiscount,
        rdiscount:item.rdiscount,
        finalprice:item.finalprice,
        trialstatus:item.trialstatus,
        substatus:item.substatus,
        paymentstatus:item.paymentstatus,
        planamount:item.planamount
      };
    });
    setCheckBoxData(checkBoxArray);
  }, [data]);


  const ResetCheckBox = () => {
    var checkBoxResetArray = checkBoxData?.map((item,idx) => {
      return {
        type: "",
        selected: false,
        status: "",
        startdate:item.startdate,
        enddate:item.enddate,
        trialperiod:item.trailperiod,
        duration:item.duration,
        ndiscount:item.ndiscount,
        rdiscount:item.rdiscount,
        finalprice:item.finalprice,
        trialstatus:item.trialstatus,
        substatus:item.substatus,
        paymentstatus:item.paymentstatus,
        planamount:item.planamount
      };
    });
    setCheckBoxData(() => [...checkBoxResetArray]);
  };

    if (!data) {
        return <Empty style={{ margin: "60px" }} />;
    }

    function enableDbUpdate (){
        for(let idx = 0; idx < checkBoxData.length; idx++){
            console.log("enableDbUpdate checkBoxArray", idx,checkBoxData[idx].selected)      
            if (checkBoxData[idx].selected ) {
                return true
            } 
        }
        return false
    }
    const onCheckBoxChange = (e) => {
        if (e.target.checked) {
            checkBoxData[e.target.value].selected = true; 
            console.log("Setting checkBoxData", e.target.value,true,checkBoxData)      
        }else{
            checkBoxData[e.target.value].selected = false; 
            console.log("Setting checkBoxData", e.target.value,false) 
        }
        if (enableDbUpdate() == true){
            setUpdateAvailable(true)

        }else{
            setUpdateAvailable(false)
        } 
        setCheckBoxData(()=>([...checkBoxData]))
      }

    const trialStatusStateMachine = (rowIndex) =>{
        var statusDropDown

        if (data?.[rowIndex]?.trialstatus == "INACTIVE") {
            statusDropDown= [
                { value: rowIndex + '#' +'trial_activation_in_progress', label: 'ACTIVATE' },
                { value: rowIndex + '#' +'trial_stop', label: 'STOP' },
            ]   
        }else if (data?.[rowIndex]?.trialstatus == "ACTIVATION IN PROGRESS") {
            statusDropDown= [
               { value: rowIndex + '#' +'trial_stop', label: 'STOP' },
            ] 
        }else if (data?.[rowIndex]?.trialstatus == "ACTIVE") {
            statusDropDown= [
               { value: rowIndex + '#' +'trial_stop', label: 'STOP' },
            ] 
        }

        console.log("Trial Status",data?.[rowIndex]?.trialstatus);
        return statusDropDown
    }

    const subStatusStateMachine = (rowIndex) =>{
        var statusDropDown

        if (data?.[rowIndex]?.substatus == "INACTIVE") {
            statusDropDown= [
                { value: rowIndex + '#' +'subscription_configured', label: 'CONFIGURED' },
            ]   
        }else if (data?.[rowIndex]?.substatus == "CONFIGURED") {
            statusDropDown= [
                { value: rowIndex + '#' +'subscription_activation_in_progress', label: 'ACTIVATE' },
            ]   
        }else if (data?.[rowIndex]?.substatus == "ACTIVATION IN PROGRESS") {
            statusDropDown= [
               { value: rowIndex + '#' +'subscription_stopped', label: 'STOP' },
            ] 
        }else if (data?.[rowIndex]?.substatus == "ACTIVE") {
            statusDropDown= [
               { value: rowIndex + '#' +'subscription_stopped', label: 'STOP' },
            ] 
        }
        console.log("Sub Status",data?.[rowIndex]?.substatus);
        return statusDropDown
    }

    const paymentStatusStateMachine = (rowIndex) =>{
        var statusDropDown

        if (data?.[rowIndex]?.paymentstatus == "NA") {
            statusDropDown= [
                { value: rowIndex + '#' +'payment_req', label: 'REQUEST PAYMENT' },
            ]   
        }else if (data?.[rowIndex]?.paymentstatus == "payment_revise") {
            statusDropDown= [
                { value: rowIndex + '#' +'payment_req', label: 'REQUEST PAYMENT' },
            ]   
        }
        console.log("payment Status",data?.[rowIndex]?.paymentstatus);
        return statusDropDown
    }

    const OnSelectChangeDoCStatus = (value) => {
        //console.log(`selected ${value}`);
        const answer_array = value.split('#');
        var status = answer_array[1]
        var idx = answer_array[0]
        var idxInt = parseInt(idx)
        checkBoxData[idxInt].status = status
        checkBoxData[idxInt].type = "DOCUMENT_STATUS"
        //if(status === "Available"){
        //    handleResumeDetailClick(data[idxInt].qhid)
        //}
    }

    const OnSelectChangeSubStatus = (value) => {
        //console.log(`selected ${value}`);
        const answer_array = value.split('#');
        var status = answer_array[1]
        var idx = answer_array[0]
        var idxInt = parseInt(idx)
        checkBoxData[idxInt].status = status
        checkBoxData[idxInt].type = "SUB_STATUS"
        //if(status === "Available"){
        //    handleResumeDetailClick(data[idxInt].qhid)
        //}
    }
    const OnSelectChangePaymentStatus = (value) => {
        //console.log(`selected ${value}`);
        const answer_array = value.split('#');
        var status = answer_array[1]
        var idx = answer_array[0]
        var idxInt = parseInt(idx)
        checkBoxData[idxInt].status = status
        checkBoxData[idxInt].type = "PAYMENT_STATUS"
        //if(status === "Available"){
        //    handleResumeDetailClick(data[idxInt].qhid)
        //}

        const updatedValues = [...checkBoxData];
        updatedValues[idxInt] = { ...updatedValues[idxInt], ["paymentstatus"]: status };
        setCheckBoxData(updatedValues);
    }
    const handleChange = (event, index) => {
        const { name, value } = event.target;
        const updatedValues = [...checkBoxData];
        updatedValues[index] = { ...updatedValues[index], [name]: value };
        console.log("handle change", updatedValues, event.target)

        var event = checkBoxData[index].startdate
        if((event != null)&&(event!="")){
            
            const month = event?.$M+1;
            const year = event?.$y;
            const date = event?.$D;
            const valueDate = year + " " + month + " " + date;            
            var unixTimestamp = moment(valueDate).unix();

            var endDateTimeStamp = unixTimestamp + value * 24 * 60 * 60
    
            const enddate = new Date(endDateTimeStamp * 1000);
            updatedValues[index] = { ...updatedValues[index], ["enddate"]: moment(enddate).format('YYYY.MM.DD') };
            console.log("Unix Timestamp date2", index, unixTimestamp, moment(enddate).format('YYYY.MM.DD'))
            console.log("Duration Change Startdate EndDate Duration", event,enddate,checkBoxData[index].trialperiod )
        }else{
            updatedValues[index] = { ...updatedValues[index], ["enddate"]: "" };
        } 

        setCheckBoxData(updatedValues);
    };
    const handleChangeDate = (event, index) => {
        //const { name, value } = event.target;
        var name = "startdate"

        const dateFormat = 'YYYY/MM/DD';
        const month = event?.$M+1;
        const year = event?.$y;
        const date = event?.$D;
        const value = year + " " + month + " " + date;

        const updatedValues = [...checkBoxData];
        updatedValues[index] = { ...updatedValues[index], [name]: event };
        if(event != null){
            var unixTimestamp = moment(value).unix();
            var endDateTimeStamp = unixTimestamp + checkBoxData[index].trialperiod * 24 * 60 * 60
            if(currentView === "paid"){
                //console.log(`selected ${value}`);
                const answer_array = checkBoxData[index].duration.split(' ')
                var months = answer_array[1]
                var duration = parseInt(answer_array[0])
                endDateTimeStamp = unixTimestamp + duration * 30 * 24 * 60 * 60
                console.log("UComaing here", endDateTimeStamp, unixTimestamp)
            }
            const enddate = new Date(endDateTimeStamp * 1000);
            updatedValues[index] = { ...updatedValues[index], ["enddate"]: moment(enddate).format('YYYY.MM.DD') };
            console.log("Unix Timestamp date2", index, unixTimestamp, moment(enddate).format('YYYY.MM.DD'))
        }else{
            updatedValues[index] = { ...updatedValues[index], ["enddate"]: "" };
        } 

        setCheckBoxData(updatedValues);
    };

    
    const handleChangeDiscount = (event, index) => {
        const { name, value } = event.target;
        const updatedValues = [...checkBoxData];
        updatedValues[index] = { ...updatedValues[index], [name]: value };

        //update final price
        var finalPrice = checkBoxData[index].planamount
        if(name === "rdiscount"){
            finalPrice = finalPrice - (finalPrice*value/100) - ((finalPrice*checkBoxData[index].ndiscount/100))
        }else if (name =="ndiscount"){
            finalPrice = finalPrice - (finalPrice*value/100) - ((finalPrice*checkBoxData[index].rdiscount/100))
        }
        
        finalPrice = Math.floor(finalPrice)
        updatedValues[index] = { ...updatedValues[index], "finalprice": finalPrice };

        console.log("handleChangeDiscount",updatedValues, event.target)
        setCheckBoxData(updatedValues);
    };
    const handlePaymentStatus = (event, index) => {
        const { name, value } = event.target;
        const updatedValues = [...checkBoxData];
        updatedValues[index] = { ...updatedValues[index], [name]: value };
        setCheckBoxData(updatedValues);
    };

    function getTimeNow(){
        const today = new Date();
        const month = today.getMonth()+1;
        const year = today.getFullYear();
        const date = today. getDate();
        const currentDate = date + "-" + month + "-" + year;
        return currentDate
    }
    const encodeJson = (updateType) =>{

        let encodedJsonValues ={};
        
        //get company details
        encodedJsonValues._id="Not used"
        const userData = JSON.parse(localStorage.getItem("userDetails"));
        encodedJsonValues.qhoid= userData.ID;
        encodedJsonValues.accounttype= userData.account_type ;


        //now fill the parameters
        encodedJsonValues.documentlist =[];
        var num=0
        for(let idx = 0; idx < checkBoxData.length; idx++){
            console.log("checkBoxData", idx,checkBoxData[idx].selected)
            if (checkBoxData[idx].selected && updateType =="DOCUMENT_STATUS" ) {
                encodedJsonValues.documentlist[num]={};
                encodedJsonValues.documentlist[num].status =checkBoxData[idx].status;
                encodedJsonValues.documentlist[num].name =data[idx].name;
                encodedJsonValues.documentlist[num].type =data[idx].type;
                num++;
            }          
        }        
        //get the current date
        let newDate = getTimeNow()
        encodedJsonValues.updatetype = updateType
        encodedJsonValues.updatedby= userData.qhuid;
        encodedJsonValues.updatedbyname= userData.username;
        encodedJsonValues.updatedon    = newDate;
             
        return encodedJsonValues
    }

    const getUpdated=(rowIndex)=>{
        return checkBoxData?.[rowIndex]?.selected 
    }
    const checkStatus=(rowIndex)=>{
        if(currentView == "free"){
            if(data?.[rowIndex]?.trialstatus === "ACTIVE")
                return false
        }else if (currentView == "paid"){
            if(data?.[rowIndex]?.substatus === "ACTIVE")
                return false
        }
        return true
    }
    const checkPaymentStatus=(rowIndex)=>{
        if (currentView === "paid"){
            if(data?.[rowIndex]?.paymentstatus === "PAYMENT DONE")
                return true
        }
        return false
    }
    const enableSubStatusChange=(rowIndex)=>{
        if (currentView === "paid"){
            if((data?.[rowIndex]?.paymentstatus === "NA")
                ||(data?.[rowIndex]?.paymentstatus === "PAYMENT DONE"))
                return true
        }
        return false
    }

    const handleUpdateStatus = (value) => {
        var jasonValues= encodeJson("DOCUMENT_STATUS")
        console.log("Encoded data for updating the status", jasonValues)
        updateSuperUserDocumentStatus({
            jasonValues
        });
        ResetCheckBox();
        setUpdateAvailable(false);
    }

    const handleUpdateStatusFree = (value) => {
        var jasonValues= encodeJson("DOCUMENT_STATUS")
        console.log("Encoded data for updating the status", jasonValues)
        updateSuperUserDocumentStatus({
            jasonValues
        });
        ResetCheckBox();
        setUpdateAvailable(false);
    }
    const handleUpdateStatusPaid = (value) => {
        var jasonValues= encodeJson("DOCUMENT_STATUS")
        console.log("Encoded data for updating the status", jasonValues)
        updateSuperUserDocumentStatus({
            jasonValues
        });
        ResetCheckBox();
        setUpdateAvailable(false);
    }

    return (
        <div className="superusetable-selectable-wrapper">
            <div className="rtable-selectable-btn-wrapper"  >
                {currentView == "documents" ?
                <>
                    <Button
                        className="rtable-selectable-btn"
                        disabled={!updateAvailable}
                        onClick={handleUpdateStatus}
                    >
                    Update Status
                    </Button>                
                </>
                :<></>}
                {currentView == "free" ?
                <>
                    <Button
                        className="rtable-selectable-btn"
                        disabled={!updateAvailable}
                        onClick={handleUpdateStatusFree}
                    >
                    Save Changes
                    </Button>                
                </>
                :<></>}
                {currentView == "paid" ?
                <>
                    <Button
                        className="rtable-selectable-btn"
                        disabled={!updateAvailable}
                        onClick={handleUpdateStatusPaid}
                    >
                    Save Changes
                    </Button>                
                </>
                :<></>}                
            </div>
            <div
                className="rtable-title-wrapper"
                style={{
                    gridTemplateColumns: `50px 50px 5fr repeat(${titleList.length-3}, 5fr)`
                }}
            >
                {titleList.map((title) => (
                    <div className="superuser-table-title">{title}
                    </div>
                ))}
            </div>
            {data?.map((table, rowIndex) => (
                <div
                    key={table.id}
                    className="rtable-info-wrapper"
                    style={{
                        gridTemplateColumns: `50px 50px 5fr repeat(${titleList.length-3}, 5fr)`,
                        cursor: isClickable && "pointer",
                    }}
                >
                    {Object.keys(table)?.map((tableInfo, idx) => (
                        <div className="rtable-info">
                            {isEditField ? (
                                <div className="edit-field-wrapper">
                                        <>
                                            {tableInfo === "id"
                                                ? rowIndex + 1
                                                : <></>}
                                                
                                            {(tableInfo != "id") &&(tableInfo != "select")&&
                                             (tableInfo != "status")&&(tableInfo != "trialstatus")
                                             &&(tableInfo != "enddate")&&(tableInfo != "startdate")
                                             &&(tableInfo != "trialperiod")&&(tableInfo != "substatus")
                                             &&(tableInfo != "ndiscount")&&(tableInfo != "finalprice") 
                                             &&(tableInfo != "rdiscount")&&(tableInfo != "paymentstatus") 
                                                ? table[tableInfo]
                                                : <></>}

                                             { tableInfo === "select" ?checkStatus(rowIndex)===true?
                                                <Checkbox value = {rowIndex} onChange={onCheckBoxChange}></Checkbox>
                                               : <></>:<></>
                                             }  
                                             { tableInfo === "status" ? (
                                                    <>
                                                        {getUpdated(rowIndex) === true?

                                                        <Select
                                                            style={{ width: 130,height: 24 }}
                                                            onChange={OnSelectChangeDoCStatus}
                                                            placeholder={table[tableInfo]}                                                             
                                                            options={[
                                                                { value: rowIndex + '#' +'doc_rejected', label: 'DOCS REJECTED' },
                                                                { value: rowIndex + '#' +'doc_verified', label: 'DOCS VERIFIED' },
                                                            ]}
                                                        /> :
                                                        table[tableInfo]==""?"--":table[tableInfo]}    
                                                    </> 
                                               )                                             
                                               : <></>
                                             }                                           
                                             { tableInfo === "trialstatus" ? (
                                                    <>
                                                        {getUpdated(rowIndex) === true?

                                                        <Select
                                                            style={{ width: 130,height: 24 }}
                                                            onChange={OnSelectChangeDoCStatus}
                                                            placeholder={table[tableInfo]}                                                             
                                                            options={trialStatusStateMachine(rowIndex)}
                                                        /> :
                                                        table[tableInfo]==""?"--":table[tableInfo]}    
                                                    </> 
                                               )                                             
                                               : <></>
                                             } 
                                             { tableInfo === "paymentstatus" ? (
                                                    <>
                                                        {getUpdated(rowIndex) === true?currentView=="payment"?
                                                        <Select
                                                            style={{ width: 130,height: 24 }}
                                                            onChange={OnSelectChangePaymentStatus}
                                                            placeholder={table[tableInfo]}                                                             
                                                            options={paymentStatusStateMachine(rowIndex)}
                                                        /> :table[tableInfo]:
                                                        table[tableInfo]==""?"--":table[tableInfo]}    
                                                    </> 
                                               )                                             
                                               : <></>
                                             }                                              
                                             { tableInfo === "substatus" ?(
                                                    <>
                                                        {getUpdated(rowIndex) === true? enableSubStatusChange(rowIndex) ===true?

                                                        <Select
                                                            style={{ width: 150,height: 24 }}
                                                            onChange={OnSelectChangeSubStatus}
                                                            placeholder={table[tableInfo]}                                                             
                                                            options={subStatusStateMachine(rowIndex)}
                                                        /> :table[tableInfo]:
                                                        table[tableInfo]==""?"--":table[tableInfo]}    
                                                    </> 
                                               )                                             
                                               :<></>
                                             } 
                                            {tableInfo === "ndiscount" ? (
                                            <>
                                                {getUpdated(rowIndex) === true ? (
                                                <Input
                                                    style={{
                                                    width: 200,
                                                    height: 24,
                                                    textAlign: "center",
                                                    }}
                                                    name="ndiscount"
                                                    type ="number"
                                                    value={checkBoxData[rowIndex].ndiscount}
                                                    placeholder={checkBoxData[rowIndex].ndiscount}
                                                    onChange={(event) => handleChangeDiscount(event, rowIndex)}
                                                />
                                                ) : (
                                                table[tableInfo]
                                                )}
                                            </>
                                            ) : (
                                            <></>
                                            )}
                                            {tableInfo === "rdiscount" ? (
                                            <>
                                                {getUpdated(rowIndex) === true ? (
                                                <Input
                                                    style={{
                                                    width: 200,
                                                    height: 24,
                                                    textAlign: "center",
                                                    }}
                                                    name="rdiscount"
                                                    type ="number"
                                                    value={checkBoxData[rowIndex].rdiscount}
                                                    placeholder={checkBoxData[rowIndex].rdiscount}
                                                    onChange={(event) => handleChangeDiscount(event, rowIndex)}
                                                />
                                                ) : (
                                                table[tableInfo]
                                                )}
                                            </>
                                            ) : (
                                            <></>
                                            )}                                            
                                            {tableInfo === "finalprice" ? (
                                            <>
                                                {getUpdated(rowIndex) === true ? checkStatus(rowIndex)===true?(
                                                <>
                                                  {checkBoxData[rowIndex].finalprice}
                                                </>
                                                ) : (
                                                table[tableInfo]
                                                ):(
                                                    table[tableInfo]
                                                    )}
                                            </>
                                            ) : (
                                            <></>
                                            )}                                                                                           
                                            {tableInfo === "trialperiod" ? (
                                            <>
                                                {getUpdated(rowIndex) === true ? (
                                                <Input
                                                    style={{
                                                    width: 200,
                                                    height: 24,
                                                    textAlign: "center",
                                                    }}
                                                    name="trialperiod"
                                                    type ="number"
                                                    value={checkBoxData[rowIndex].trialperiod}
                                                    placeholder={checkBoxData[rowIndex].trialperiod}
                                                    onChange={(event) => handleChange(event, rowIndex)}
                                                />
                                                ) : (
                                                table[tableInfo]
                                                )}
                                            </>
                                            ) : (
                                            <></>
                                            )} 
                                            {tableInfo === "startdate" ? (
                                            <>
                                                {getUpdated(rowIndex) === true ?checkStatus(rowIndex)===true? (
                                                <DatePicker 
                                                    style={{
                                                    width: 200,
                                                    height: 24,
                                                    textAlign: "center",
                                                    }}
                                                    name="startdate"                                                    
                                                    placeholder={checkBoxData[rowIndex].startdate}
                                                    onChange={(event) => handleChangeDate(event, rowIndex)} />
                                                ) : (
                                                table[tableInfo]
                                                ):(table[tableInfo])}
                                            </>
                                            ) : (
                                            <></>
                                            )} 
                                            {tableInfo === "enddate" ? (
                                            <>
                                                {getUpdated(rowIndex) === true ? checkStatus(rowIndex)===true?(
                                                <>
                                                  {checkBoxData[rowIndex].enddate}
                                                </>
                                                ) : (
                                                table[tableInfo]
                                                ):(
                                                    table[tableInfo]
                                                    )}
                                            </>
                                            ) : (
                                            <></>
                                            )}                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                                        </>

                                </div>
                            ) : (
                                table[tableInfo]==""?"--":table[tableInfo]
                            )}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}
