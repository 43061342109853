import React, { useEffect, useState } from "react";
import { Button ,Dropdown, Menu  } from "antd";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {  useDispatch } from "react-redux";


import JDCreationForm from "../JDCreationForm";
import JDAssignmentForm from "../JDAssignmentForm";
import JDSummary from "../JDSummary";

import useJDStore from "../../store/jd.store";
import useResumeStore from "../../store/resume.store";
import useMembersStore from "../../store/members.store";
import { actions } from "../../actions";

import "./createJd.scss";

function CreateJD() {

    const [currentView, setCurrentView] = useState("description");
  const navigate = useNavigate();
  const location = useLocation();
  const newView = location.state?.newView || "";
    const { value } = useParams();

    const { getJdByID, selectedJD } = useJDStore();
    const dispatch = useDispatch();
    const { getResumesByJD, getAllApplicationsByJD } =
        useResumeStore();    
    const { getVendors,  getTeamMembers, getAssignedMembers } = useMembersStore();

    useEffect(() => {
        const fetchData = async (value) => {
          try {
    
            if (value) {
                getJdByID(value);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        fetchData(value);
        getVendors();
        getTeamMembers();
        getAssignedMembers(value);
        getResumesByJD(value);
        getAllApplicationsByJD(value)        
      }, [getJdByID]);

  useEffect(() => {
    if (newView) {
      setCurrentView(newView);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

    const view = {
        description: JDCreationForm,
        assignment: JDAssignmentForm,
        summary: JDSummary,
    };
    
    const handleUpdateView = (view) => {
        setCurrentView(view)
    };

    const handleMenuClick = (e) => {
        console.log('Clicked on menu item:', e);
        var userData = JSON.parse(localStorage.getItem("userDetails"));
        dispatch(actions.UpdateStatus(userData.account_type,userData.ID,value,e.key))
        // You can handle the menu item click event here
    };
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const menu = (
        <Menu onClick={handleMenuClick}>
            {/* Define menu items here */}
            <Menu.Item key="active" >active </Menu.Item>
            <Menu.Item key="hold" >hold</Menu.Item>
            <Menu.Item key="closed" >close</Menu.Item>
        </Menu>
    );

    const CurrentView = view[currentView];

    return (
        <div className="create-jd-wrapper">
            {value ? (
                <div className="jd-primary-info-wrapper" style={{ textAlign: "center" }}>
                    <div className="jd-primary-info">
                        <div className="job-id-tag"> {selectedJD?.JDClient?.ClientName} &nbsp;&gt;&nbsp; {selectedJD?.JDClient?.TaName} &nbsp; &gt;&nbsp;                       
                        <p style={{marginLeft:"20px",marginTop : "15px", fontWeight:"600",fontSize : "14px", color :"#000000"}}>{selectedJD?.JDDetails.Title}</p>
                        </div>
                      
                    </div>
                    <div className="jd-primary-info">
            <Dropdown
                overlay={menu}
                trigger={['click']}
                visible={dropdownVisible}
                onVisibleChange={(visible) => setDropdownVisible(visible)}
            >
                <Button className="jd-primary-btn">
                  <span style={{color : "#008fff", textTransform: "uppercase"}}> {selectedJD?.JDProject.ReqStatus} </span> {/* Display selected status */}
                </Button>
            </Dropdown>
        </div>
                </div>
            ) : null}
            <div className="jd-creation-form-options-wrapper">
                <div className="jd-creation-form-first-options-wrapper">
                    {value ? (
                        <>
                            <Button
                onClick={() => {
                  setCurrentView("description");
                  navigate(`/jds/${value}`);
                }}
                type="primary"
                                className={`jd-form-option-btn ${
                                    currentView === "description" &&
                                    "jd-form-option-btn-active"
                                }`}
                            >
                                JD
                            </Button>                        
                            <Button
                onClick={() => {
                  setCurrentView("assignment");
                  navigate(`/jds/${value}`);
                }}
                                type="primary"
                                className={`jd-form-option-btn ${
                                    currentView === "assignment" &&
                                    "jd-form-option-btn-active"
                                }`}
                            >
                                Assignment
                            </Button>
                            <Button
                                onClick={() => setCurrentView("summary")}
                                type="primary"
                                className={`jd-form-option-btn ${
                                    currentView === "summary" &&
                                    "jd-form-option-btn-active"
                                }`}
                            >
                                Resumes
                            </Button>

                            <Button
                                type="primary"
                                className="jd-form-option-btn"
                            >
                                Interviews
                            </Button> 
                            <Button
                                type="primary"
                                className="jd-form-option-btn"
                            >
                                Selection and Onboarding
                            </Button>                                                       
                            <Button
                                type="primary"
                                className="jd-form-option-btn"
                            >
                                Skill Matrix
                            </Button>
                        </>
                    ) : null}
                </div>
            </div>

            <CurrentView setCurrentView={handleUpdateView} />

        </div>
    );
}

export default CreateJD;
