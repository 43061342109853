import React, { useState, useEffect } from "react";
import { Link,useNavigate,useLocation } from "react-router-dom";
import { Radio, Form, Input,InputNumber, message, Button, Steps,Modal } from "antd";
import qhrflow from "../../assets/landingFeatures/qhrFlow.png";
import { actions } from "../../actions";
import "./otpprocess.css";
import { useDispatch } from "react-redux";

export default function OPTProcess() {
    const [loader, SetLoader] = useState(false);
    const [formKey1, setFormKey1] = useState(1);
    const [nextState, setNextState] = useState(0)
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const[error,setError] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false);

    let location = useLocation();
    const { reason, trigger,signupemail } = location.state || {};
    var value = reason;
    var localTrigger = trigger;

    const [inputData, setFormData] = useState({
      otp:"",
      username: "",
      contact: "",
      email: "",
    });

    useEffect(() => {
        console.log("location", location.state);
    }, []);

    const handleFormSubmit = (values) => {
        
    };

    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const dispatchSignIn = (values) =>{

      SetLoader(true);
      dispatch(actions.SignIn(inputData, (error, status) => {
        if (!error) {
          navigate("/otpprocess", { state: {  reason: "verifyotp" ,trigger: localTrigger } });
          SetLoader(false);       
        } else {
          // Handle the error case
          SetLoader(false);
          console.error("API Error:", error);
         
          if( error == "decode error"){
            setError("Kindly fill right details." ); // Set the error message in state
          }else if( error == "please sign in"){
            setError("Account already created, kindly sign in."); // Set the error message in state
          }else if( error == "verification pending"){
            setError("OTP Verification pending, Kindly verify your email id."); // Set the error message in state
          }else {
            setError(error)
          }
          showModal()
          console.log("status", status);
        }
      }));

      if (nextState) {
        localTrigger = "signin"
        value ="verifyotp" 
        setNextState(1)
      }
       
    }
    const dispatchAlreadygotOtp = (values) =>{

        SetLoader(true);
        dispatch(actions.Signup(inputData, (error, status) => {
          if (!error) {
            navigate("/otp", { state: { inputData, type: "signup" } });
            SetLoader(false);
          } else {
            // Handle the error case
            SetLoader(false);
            console.error("API Error:", error);
           
            if( error == "decode error"){
              setError("Kindly fill right details." ); // Set the error message in state
            }else if( error == "please sign in"){
              setError("Account already created, kindly sign in."); // Set the error message in state
            }else if( error == "verification pending"){
              setError("OTP Verification pending, Kindly verify your email id."); // Set the error message in state
            }else {
              setError(error)
            }
            showModal()
            console.log("status", status);
          }
        }));        
    }
    const dispatchResendOtp = (values) =>{

        SetLoader(true);
        dispatch(actions.Signup(inputData, (error, status) => {
          if (!error) {
            navigate("/otp", { state: { inputData, type: "signup" } });
            SetLoader(false);
          } else {
            // Handle the error case
            SetLoader(false);
            console.error("API Error:", error);
           
            if( error == "decode error"){
              setError("Kindly fill right details." ); // Set the error message in state
            }else if( error == "please sign in"){
              setError("Account already created, kindly sign in."); // Set the error message in state
            }else if( error == "verification pending"){
              setError("OTP Verification pending, Kindly verify your email id."); // Set the error message in state
            }else {
              setError(error)
            }
            showModal()
            console.log("status", status);
          }
        }));        
    }
    const dispatchVerifyOtp = (values) =>{
        SetLoader(true);
        dispatch(actions.Verify_signin_otp(inputData, (error, status) => {
          if (!error) {
            navigate("/dashboard")
            SetLoader(false);
          } else {
            // Handle the error case
            SetLoader(false);
            console.error("API Error:", error);
           
            if( error == "decode error"){
              setError("Kindly fill right details." ); // Set the error message in state
            }else if( error == "please sign in"){
              setError("Account already created, kindly sign in."); // Set the error message in state
            }else if( error == "verification pending"){
              setError("OTP Verification pending, Kindly verify your email id."); // Set the error message in state
            }else {
              setError(error)
            }
            showModal()
            console.log("status", status);
          }
        }));        
    }
    const dispatchVerifyAccountOtp = (values) =>{
        SetLoader(true);
        inputData.email = signupemail
        dispatch(actions.Verify_acoount_otp(inputData, (error, status) => {
          if (!error) {
            navigate("/dashboard");
            SetLoader(false);
          } else {
            // Handle the error case
            SetLoader(false);
            console.error("API Error:", error);
           
            if( error == "decode error"){
              setError("Kindly fill right details." ); // Set the error message in state
            }else if( error == "please sign in"){
              setError("Account already created, kindly sign in."); // Set the error message in state
            }else if( error == "verification pending"){
              setError("Email Verification pending, Kindly verify."); // Set the error message in state
            }else {
              setError(error)
            }
            showModal()
            console.log("status", status);
          }
        }));        
    }      
    const handleOtpProcess = (values) =>{
      console.log("formData", inputData);
      SetLoader(true);
    
      if(localTrigger =="signin" && value == "start"){
        dispatchSignIn()
      }
      if(localTrigger=="signup"&& (value == "verifyotp")){
        dispatchVerifyAccountOtp()
      }
      if(localTrigger=="signin"&& (value == "verifyotp")){
        dispatchVerifyOtp()
      }      
      if( value == "resend"){
        dispatchResendOtp()
      }
      if( value == "alreadygot"){
        dispatchAlreadygotOtp()
      }      
    }
    const handleChange = (e) =>{
      console.log("handle change", e)
      const { name, value } = e.target;
      setFormData({ ...inputData, [name]: value });
    }

    return (
     <div className="otpprocess-wrapper">
        <div className="otpprocess-form-wrapper">
          <div className="signupnew-leftarea">
              <div>
                <Link to = "/home">
                  <img
                    src={qhrflow}
                    alt="Explorer"
                    width="200" height="200" 
                  />                 
                </Link>
              </div>     
             Discover Talent, Drive Success.     
          </div>

          <div className="signupnew-rightarea"> 
          <div className="signupnew-heading">
               {localTrigger == "signin"?
                reason == "start" ? <>Sign In to QHRFLOW</>:<>Continue Sign In to QHRFLOW</>
                :<>Continue Sign Up to QHRFLOW <br></br></>}
          </div> 
            <Form
              key={formKey1}
              className="profile-form"
              name="signon-form"
              justify="start"
              layout="vertical"
              autoComplete="off"
              onFinish={handleFormSubmit}
             >                    
             {
                value != "verifyotp"?
                <>
                    <Form.Item
                                required = {true}                            
                                className="profile-field-item"
                                label="Email ID"
                                name="email"
                            >
                                <Input
                                    required = {true}
                                    className="profile-input-full"
                                    onChange={handleChange}
                                    name="email"
                                />
                    </Form.Item>   
                    <Form.Item
                            required = {true}
                            className="profile-field-item"
                            label="Mobile Number"
                            name="contact"
                        >
                            <Input
                            required = {true}
                            className="profile-input-full"
                            onChange={handleChange}
                            name="contact"
                            />
                        </Form.Item>                                 
                </>:
                <></>
             }

             {
                value == "alreadygot" || value == "verifyotp"
                ?<>
                   <Form.Item
                      required = {true}
                      className="profile-field-item"
                      label="OTP"
                      name="validotp"
                  >
                      <Input
                          required = {true}
                          className="profile-input-full"
                          onChange={handleChange}
                          name="otp"
                      />
                  </Form.Item>               
                </>
                :<> </>
             } 

                <Button
                    style={{ width: "100%", height: "40px", fontWeight: "600" }}
                    type="primary"
                    htmlType="submit"
                    onClick={handleOtpProcess}
                >
                    {
                        value =="start"?<>Sign In</>:
                        value == "verifyemail"? <>Verify Email ID</>:
                        value =="verifyotp"? <>Verify OTP</>:
                        value =="alreadygot"?<>Verify OTP</>:<>Resend OTP</>
                    }                     
                </Button>           
            </Form>  
            {
              <Modal title="Signup" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                 {error}
              </Modal>
            }
            <div className="signupnew-links-wrapper">
              <div className="signupnew-links">
                <Link
                  to={"/otpprocess"}
                  state= {{ reason: "alreadygot", trigger: localTrigger }} 
                > 
                    Got Valid OTP?
                </Link>             
              </div>
              <div className="signupnew-links">
                <Link
                  to={"/otpprocess"}
                  state= {{ reason: "resend" ,trigger: localTrigger}} 
                > 
                    Resend OTP
                </Link>                
              </div>                        
            </div>               
            <div className="signupnew-links-wrapper">             
              <div className="signupnew-links">
                <Link
                  to={"/otpprocess"}
                  state= {{ reason: "start" ,trigger: "signin"}} 
                > 
                  Sign In
                </Link>
              </div> 
              <div className="signupnew-links">
                <Link
                  to={"/otpprocess"}
                  state= {{ reason: "verifyotp" ,trigger: localTrigger}} 
                > 
                  Verify OTP
                </Link>
              </div>   
              <div className="signupnew-links">
                <Link
                  to={"/otpprocess"}
                  state= {{ reason: "verifyemail" ,trigger: localTrigger}} 
                > 
                    Verify Email
                </Link>                
              </div>                
              <div className="signupnew-links">
                <Link to = "/signup">
                  New Account Sign up
                </Link>
                
              </div>            
            </div>         
          </div>
        </div>

        <div className="otpprocess-footer">
        <div>
          <p>QHRFLOW is an QUALLYHire AI Product Family from HMW Communications Pvt. Ltd.</p>
        </div>
        <div>
          <a>
            <p>
              Dont have an account ? <Link to={'/signup'}> Sign up Now!</Link>
            </p>
          </a>
        </div>
        </div>
     </div>

    );
}
