import React, { useState, useEffect } from "react";
import { useParams,Link,useNavigate } from "react-router-dom";
import { Radio, Form, Input, Select, Button, Steps,Modal } from "antd";
import "./signupnew.scss";
import qhrflow from "../assets/landingFeatures/qhrFlow.png";
import { actions } from "../actions";
import "./signup.css";
import { useDispatch } from "react-redux";
import moment from 'moment'

export default function SignupNew() {
    const [loader, SetLoader] = useState(false);
    const [formKey1, setFormKey1] = useState(1);
    const [currentView, setCurrentView] = useState("signup-1")
    const [currentIdx, setCurrentIdx] = useState(0)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const[error,setError] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const moment = require('moment');
    
    const [inputData, setFormData] = useState({
      username: "",
      organization: " ",
      contact: "",
      email: "",
      account_type: "",
      permission:"superadmin",
      designation:"",
      weburl:"",
      address:"",
      city:"",
      country:"",
      pincode:"",
      planname:"",
      subscriptionperiod:"",
      trialperiod:"",
      amount:"",
      bu_team:"",
    });


    useEffect(() => {
      var timestamp = moment("1976 01 27").unix();
      dispatch(actions.GetProductPlans(timestamp, (error, status) => {
        if (!error) {
        } else {
          // Handle the error case
          console.error("API Error:", error);
          setError(error)
        }
      }));
    }, []);

    const handleFormSubmit = (values) => {
        
    };

    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const handleCreateAccount = (values) =>{
      console.log("formData", inputData);
      SetLoader(true);
    
      dispatch(actions.Signup(inputData, (error, status) => {
        if (!error) {
          navigate("/otpprocess", { state: { reason:"verifyotp", trigger: "signup" ,signupemail:inputData.email} });
          SetLoader(false);
        } else {
          // Handle the error case
          SetLoader(false);
          console.error("API Error:", error);
         
          if( error == "decode error"){
            setError("Kindly fill right details." ); // Set the error message in state
          }else if( error == "please sign in"){
            setError("Account already created, kindly sign in."); // Set the error message in state
          }else if( error == "verification pending"){
            setError("OTP Verification pending, Kindly verify your email id."); // Set the error message in state
          }else {
            setError(error)
          }
          showModal()
          console.log("status", status);
        }
      }));
    }
    const ProductPlansInfo = () =>{
      const planData = JSON.parse(localStorage.getItem("productPlans"));
      const planDropDown = planData?.map((plan,idx) => {
        return {
          value: plan.planname + "+" +plan.trialperiod,  
          label: plan.planname
        };
      });
      console.log("Plan Data",planData);
      console.log("Plan Data",planDropDown);
      return planDropDown
  }
    const handleChangePlan = (value) =>{
      const plan_array = value.split("+");
      var period = plan_array[1];
      var name = plan_array[0];

      console.log("handle change", value)
      setFormData({ ...inputData, ["planname"]: name ,["trialperiod"]:period});
    }
    const handleChange = (event) =>{

      const { name, value } = event.target;     
      console.log("handle change", value)
      setFormData({ ...inputData, [name]: value});
    }

    const handlesignup1 = (values) => {
      setCurrentView("signup-1")
      setCurrentIdx(0)
    };
    const handlesignup2 = (values) => {
      setCurrentView("signup-2")
      setCurrentIdx(1)
    };
    const handlesignup3 = (values) => {
      setCurrentView("signup-3")
      setCurrentIdx(2)
    };
    const handlesignup4 = (values) => {
      setCurrentView("signup-4")
      setCurrentIdx(3)
    };

    const handleStateChange = (value) => {
      console.log("handle State change", value)
      if(value ==0) handlesignup1(0)
      else if(value ==1) handlesignup2(1)
      else if(value ==2) handlesignup3(2)
      else if(value ==3) handlesignup4(3)    
    };

    return (
        <div className="signupnew-form-wrapper">
          <div className="signupnew-lefarea">
              <div>
                <Link to = "/home">
                  <img
                    src={qhrflow}
                    alt="Explorer"
                    width="200" height="200" 
                  />                 
                </Link>
              </div>     
             Discover Talent, Drive Success.     
          </div>

          <div className="signupnew-rightarea"> 
          <div className="signupnew-heading">
                Sign Up to QHRFLOW <br></br>
          </div> 

          <Steps
            size="small"
            current={currentIdx}
            onChange={handleStateChange}
            items={[
              {
                title: 'Signup-Details',
              },
              {
                title: 'Company Details',
              },
              {
                title: 'Upload Documents',
              },
              {
                title: 'Plan Selection',
              },                          
            ]}
          />  
            <Form
              key={formKey1}
              className="profile-form"
              name="signon-form"
              justify="start"
              layout="vertical"
              autoComplete="off"
              onFinish={handleFormSubmit}
             >                     
            {currentView === "signup-1"?
            <>
                  <Form.Item
                      required = {true}
                      className="profile-field-item"
                      label="Your Name"
                      name="username"
                  >
                      <Input
                          required = {true}
                          className="profile-input"
                          onChange={handleChange}
                          name="username"
                      />
                  </Form.Item>

                <div className="signinnew-dual-input-wrapper">
                <Form.Item
                        required = {true}
                        className="profile-field-item"
                        label="Mobile Number"
                        name="contact"
                    >
                        <Input
                           required = {true}
                           className="profile-input"
                           onChange={handleChange}
                           name="contact"
                        />
                    </Form.Item>
                    <Form.Item
                            required = {true}                            
                            className="profile-field-item"
                            label="Email ID"
                            name="email"
                        >
                            <Input
                                required = {true}
                                className="profile-input"
                                onChange={handleChange}
                                name="email"
                            />
                    </Form.Item>                    
                </div>                                 

                <div className="signinnew-dual-input-wrapper">
                    <Form.Item
                            required = {true}
                            className="profile-field-item"
                            label="Designation"
                            name="designation"
                        >
                            <Input                      
                                required = {true}
                                className="profile-input"
                                onChange={handleChange}
                                name="designation"
                            />
                    </Form.Item>
                    <Form.Item
                            required = {true}                            
                            className="profile-field-item"
                            label="BU/Group"
                            name="bu_team"
                        >
                            <Input
                                required = {true}
                                className="profile-input"
                                onChange={handleChange}
                                name="bu_team"
                            />
                    </Form.Item>
                </div>
              
                <Button
                    style={{ width: "100%", height: "40px", fontWeight: "600" }}
                    type="primary"
                    htmlType="submit"
                    onClick={handlesignup2}
                >
                     Save and Go To Next Step
                </Button>
             </>
             : currentView === "signup-2"?
             <>
                 <Form.Item
                     required={ true}                     
                     className="profile-field-item"
                     label="Organization Name"
                     name="organization"
                 >
                     <Input
                         required = {true}
                         className="profile-input"
                         onChange={handleChange}
                         name="organization"
                     />
               </Form.Item> 

                 <Form.Item
                     required = {true}                     
                     className="profile-field-item"
                     label="Organization Website URL"
                     name="weburl"
                 >
                     <Input
                        required = {true}
                         className="profile-input"
                         onChange={handleChange}
                         name="weburl"
                     />
               </Form.Item> 

             <Form.Item
                 required = {true}
                 className="profile-field-item"                 
                 label="Select what describes you Best"
                 name="account_type"
             >
                 <Radio.Group
                     className="profile-radio-wrapper"
                     onClick
                     onChange={handleChange}
                     name="account_type"
                 >
                     <Radio
                         className="radio-option"
                         value="product-company"
                     >
                         Product Company
                     </Radio>
                     <Radio
                         className="radio-option"
                         value="service-company"
                     >
                         Services Company
                     </Radio>

                     <Radio
                         className="radio-option"
                         value="vendor"
                     >
                         Staffing/Recruitment Company
                     </Radio>
                 </Radio.Group>
             </Form.Item>
             <div className="signinnew-dual-input-wrapper">
             <Form.Item
                         required = {true}                         
                         className="profile-field-item"
                         label="Address"
                         name="address"
                     >
                         <Input
                             required = {true}
                             className="profile-input"
                             onChange={handleChange}
                             name="address"
                         />
             </Form.Item>
             <Form.Item
                     required = {true}
                     className="profile-field-item"
                     label="City"
                     name="city"                    
                 >
                     <Input
                        required = {true}
                         className="profile-input"
                         onChange={handleChange}
                          name="city"
                     />
                 </Form.Item>
             </div>

             <div className="signinnew-dual-input-wrapper">
                 <Form.Item
                         required = {true}                         
                         className="profile-field-item"
                         label="Country"
                         name="country"
                     >
                         <Input                      
                             required = {true}
                             className="profile-input"
                             onChange={handleChange}
                             name="country"
                         />
                 </Form.Item>
                 <Form.Item
                         required = {true}
                         name="pincode"                         
                         className="profile-field-item"
                         label="Pin Code"
                     >
                         <Input
                             required = {true}
                             className="profile-input"
                             onChange={handleChange}
                             name="pincode"
                         />
                 </Form.Item>
             </div>              
             <Button
                 style={{ width: "100%", height: "40px", fontWeight: "600" }}
                 type="primary"
                 htmlType="submit"
                 onClick={handlesignup3}
             >
                 Save and Go To Next Step
             </Button>
             </>
             :currentView === "signup-3"?
              <>
              <Form.Item
                  required = {true}
                  className="profile-field-item"
                  label="Documents which you are uploading"
                  name="documenttype"
              >
              <Radio.Group
                      className="profile-radio-wrapper"
                      onClick
                      onChange={handleChange}
                      name="documenttype"
                  >
                      <Radio
                          className="radio-option"
                          value="registration-doc"
                      >
                          Registraion Certificates
                      </Radio>
                      <Radio
                          className="radio-option"
                          value="tax-certificate"
                      >
                          GST/TAX Certificates
                      </Radio>
                  </Radio.Group>
              </Form.Item> 
              <div className="profile-field-item">
              <Button
                  style={{ width: "100%", height: "40px", fontWeight: "600" }}
                  htmlType="submit"
                  onClick={handlesignup4}
              >
                  Upload Document
              </Button>
              </div>               
              <Button
                  style={{ width: "100%", height: "40px", fontWeight: "600" }}
                  type="primary"
                  htmlType="submit"
                  onClick={handlesignup4}
              >
                  Save and Go to Next Step
              </Button>
              </> 
             :currentView === "signup-4"? 
             <>                             
              <Form.Item
                  required = {true}
                  className="profile-field-item"                 
                  label="Plan Name"
                  name="planname"
              >
                  <Select
                      style={{ width: 130,height: 24 }}
                      onChange={handleChangePlan}   
                      placeholder={inputData.planname}                                                          
                      options={ProductPlansInfo()}
                  />
              </Form.Item>
              <Form.Item
                  required = {true}
                  className="profile-field-item"                 
                  label="Subscription Period"
                  name="subscriptionperiod"
              >
                  <Radio.Group
                      className="signup-radio-wrapper"
                      onClick
                      onChange={handleChange}
                      name="subscriptionperiod"
                  >
                      <Radio
                          className="radio-option"
                          value="trialonly"
                      >
                          Trial Only
                      </Radio>                    
                      <Radio
                          className="radio-option"
                          value="3"
                      >
                          3 months
                      </Radio>
                      <Radio
                          className="radio-option"
                          value="6"
                      >
                          6 Months
                      </Radio>

                      <Radio
                          className="radio-option"
                          value="12"
                      >
                          12 Months
                      </Radio>
                  </Radio.Group>
              </Form.Item>
              {inputData.subscriptionperiod == "trialonly"?
              <>
                      <Form.Item
                          required = {true}
                          name="trialperiod" 
                          className="profile-field-item"
                          label="Trial Period"
                      >
                          <Input 
                              disabled                   
                              required = {true}
                              className="profile-input"
                              placeholder={inputData.planname==""?"":inputData.trialperiod}  
                              name="trialperiod"                            
                          />
              </Form.Item>
              </>:
              <></>}

              <Form.Item
                          required = {true}
                          name="amount"
                          className="profile-field-item"
                          label="Amount"
                      >
                          <Input   
                              disabled                   
                              required = {true}
                              className="profile-input"
                              defaultValue={"As Per Your Agreement with Your Account Manager"}
                               name="amount"
                          />
              </Form.Item>
             
              <Button
                  style={{ width: "100%", height: "40px", fontWeight: "600" }}
                  type="primary"
                  htmlType="submit"
                  onClick={handleCreateAccount}
              >
                  Create Account Now
              </Button>
             </>
             :<></>             
            } 
            </Form>  
            {
              <Modal title="Signup" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                 {error}
              </Modal>
            }
            <div className="signupnew-links-wrapper">
              <div className="signupnew-links">
                <Link
                  to={"/otpprocess"}
                  state= {{ reason: "alreadygot" ,trigger:"signup"}} 
                > 
                    Got Valid OTP?
                </Link>                            
              </div>
              <div className="signupnew-links">
                <Link
                  to={"/otpprocess"}
                  state= {{ reason: "resend",trigger:"signup" }} 
                > 
                  Resend OTP
                  </Link>                                                
              </div>                           
            </div> 

            <div className="signupnew-links-wrapper">             
              <div className="signupnew-links">
                <Link
                  to={"/otpprocess"}
                  state= {{ reason: "start" ,trigger: "signin"}} 
                > 
                  Sign In
                </Link>
              </div> 
              <div className="signupnew-links">
                <Link
                  to={"/otpprocess"}
                  state= {{ reason: "verifyotp" ,trigger: "signup"}} 
                > 
                  Verify OTP
                </Link>
              </div>   
              <div className="signupnew-links">
                <Link
                  to={"/otpprocess"}
                  state= {{ reason: "verifyemail" ,trigger:"signup"}} 
                > 
                    Verify Email
                </Link>                
              </div>                
              <div className="signupnew-links">
                <Link to = "/signup">
                  New Account Sign up
                </Link>
                
              </div>            
            </div>                                  
          </div>
        </div>
    );
}
