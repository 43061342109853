import React, { useState } from "react";
import Slider from "react-slick";
import { Card, Collapse } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "../../assets/landingFeatures/qhrFlow.png";
import hiring from "../../assets/landingFeatures/hiring1.png";
import Footer from "../footer";
import { Button, Empty } from "antd";
import workflow from "../../assets/landingFeatures/workflow.png";
import profileIcon from "../../assets/profile.png";

import LandingHeader from "../header";
import "./qhrflowlanding.scss";

function QHRFlowLanding(props) {
  const [loader, SetLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleSubmit } = props;
  const { Meta } = Card;

  const text_org = `
  Efficient Team, Improved Productivity, Save Cost, Increased Revenue.
  `;
  const text_managers = `
    Assign tasks, Monitor Performance of Team and Vendors, Multiple Analytics
  `;
  const text_recruiters = `
      Organize data, simplified End to End Sourcing`;
const text_candidates = `
    Knows each steps of his Job Application and outputs
`;
const text_interviewers = ` Get the filtered Quality candidates and View Interview Schedules`;
const text_vendors = `
      Clean vendor Admin panel to see Application progress
`;
const items = [
  {
    key: '1',
    label: 'For Organizations',
    children: <p>{text_org}</p>,
  },  
  {
    key: '2',
    label: 'For HR Managers',
    children: <p>{text_managers}</p>,
  },
  {
    key: '3',
    label: 'For Recruiters',
    children: <p>{text_recruiters}</p>,
  },
  {
    key: '4',
    label: 'For Candidates',
    children: <p>{text_candidates}</p>,
  },
  {
    key: '5',
    label: 'For Interviewers',
    children: <p>{text_interviewers}</p>,
  },  
  {
    key: '6',
    label: 'For Vendors',
    children: <p>{text_vendors}</p>,
  },
];  


const items_features = [
  {
    key: '1',
    label: 'Recruitment Workflow',
    children: <p>{text_org}</p>,
  },  
  {
    key: '2',
    label: 'Application Tracking',
    children: <p>{text_managers}</p>,
  },
  {
    key: '3',
    label: 'Daily Operations',
    children: <p>{text_recruiters}</p>,
  },
  {
    key: '4',
    label: 'Team Management',
    children: <p>{text_candidates}</p>,
  },
  {
    key: '5',
    label: 'Vendor Management',
    children: <p>{text_interviewers}</p>,
  },  
]; 
  const contentStyleQhrFlow = {
    margin: 20,
    padding: "5px",
    height: "300px",
    width: "auto",
    background: "#d0ece7 ",
  };

  const settings = {
    dots: false,
    speed: 10000,
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10,
  };

  const onChange = (key) => {
    console.log(key);
  };

  const handleSignin = () => {
    navigate("/login");
  };

  const handleSignup = () => {
    navigate("/signup");
  };

  return (
    <div className="qhrflowlanding-wrapper">
      <div className="landing-header">
          <LandingHeader/>
      </div>
      <div className="qhrflowlanding-header">
        <div className="qhrflowlanding-header-left">
          <div className="qhrflowlanding-logo">
            <img className="qhrflowlanding-logo-img" src={logo} />
          </div>
          <div className="qhrflowlanding-menu">
            <ul className="qhrflowlanding-menu">
              <li >
                  <Link to="/qhrflow">
                     QHRFLOW Home
                  </Link>
              </li>
              <li>
                  <Link to="/qhrflowvendorconnect">
                     QHRFLOW VendorConnect
                  </Link>
              </li>
              <li>Blogs</li>
              <li>FAQ /Support</li>
           </ul>
          </div>
          <div className="qhrflowlanding-sign">
              <Button className="qhrflowlanding-sign-button" onClick={() => handleSignin()}>Recruiters Sign In</Button>
          </div>
        </div>
      </div>

      <div className="qhrflowlanding-main"> 
        <div className="qhrflowlanding-main-text">
            One Stop HR Recruitment Solution
        </div>
        <div className="qhrflowlanding-main-slider">
          <div className="punch-heading-wrapper">
            <div>
                  <img src={hiring} alt="hiring" width="400" height="400" />
                </div>
          </div>
          <div className="qhrflowlanding-selectable-wrapper">
            <Collapse items={items_features} defaultActiveKey={['1']} onChange={onChange} />
            <div className="qhrflowlanding-register-button">
                <Button className="qhrflowlanding-register-button" onClick={() => handleSignup()} >Sign Up For Your Company Account</Button>
            </div>
          </div>
        </div>        
      </div>

      <div className="landing-features features-wrapper">
        <div className="feature-heading">Quality Hiring with Cost Saving  </div>
        <div className="qhrflowlanding-features">
          <div className="features-left">
              <div className="qhrflowlanding-main-image-wrapper">
                <Collapse items={items} defaultActiveKey={['1']} onChange={onChange} />
             </div>
          </div>
          <div className="features-right">
          <div className="features-right-image">
                <img
                        src={profileIcon}
                        alt="candidate"
                        width="60" height="60" 
                      />
               </div >
               <div className="features-right-text">
                 "QHRFLOW improved my output tremendously, I can only focus on right Resume, antd
                 QHR flow the rest"
               </div>
               <div className="features-right-text-designation">
                 Amruta Gaikwad Lead Recruiter
               </div >
               <div className="features-right-text-company">
                  HMWComm
               </div>
          </div>          

        </div>

        <div className="feature-endHeading-wrapper">
          <div className="feature-endHeading">
            Experience the{" "}
            <span
              style={{
                background: "#f4d03f",
                padding: "10px",
                fontWeight: "300",
              }}
            >
              {" "}
              difference
            </span>
            <br></br>
            Try{" "}
            <span
              style={{
                background: "#f4d03f",
                padding: "10px",
                fontWeight: "500",
              }}
            >
              {" "}
              Quallyhire AI Product Suits
            </span>
          </div>
          <div className="feature-Button-wrapper">
            <Button type="primary" shape="round" size="large">
              Talk to Sales
            </Button>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="landing-footer">
        <Footer />
      </div>
    </div>
  );
}

export default QHRFlowLanding;
