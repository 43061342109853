import axios from "../helper/axios";

  export function GetProductPlans(password,callback) {
    return async (Dispatch) => {
      axios
        .get(`/getproductPlanInfo/${password}`)  
        .then((res) => {
          console.log(res)
          localStorage.setItem("productPlans",JSON.stringify(res.data.data))
          Dispatch({ type: "Plans", payload: res.data });
          console.log(localStorage)
          callback()
        })
        .catch((e) => console.log(e));
    };
  }