import React, { useState } from "react";
import Slider from "react-slick";
import { Card, Collapse } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "../../assets/landingFeatures/qhrFlow.png";
import Footer from "../footer";
import { Button, Empty } from "antd";
import vendor from "../../assets/landingFeatures/vendorconnect.png";

import LandingHeader from "../header";
import "./qhrflowvendorconnect.scss";

function QHRFVendorConnect(props) {
  const [loader, SetLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleSubmit } = props;
  const { Meta } = Card;

  const text_org = `
  Efficient Team, Improved Productivity, Save Cost, Increased Revenue.
  `;
  const text_managers = `
    Assign tasks, Monitor Performance of Team and Vendors, Multiple Analytics
  `;
  const text_recruiters = `
      Organize data, simplified End to End Sourcing`;
const text_candidates = `
    Knows each steps of his Job Application and outputs
`;
const text_interviewers = ` Get the filtered Quality candidates and View Interview Schedules`;
const text_vendors = `
      Clean vendor Admin panel to see Application progress
`;
const items_features = [
  {
    key: '1',
    label: 'Be Part of Clients Vision',
    children: <p>{text_org}</p>,
  },  
  {
    key: '2',
    label: 'Synched Data',
    children: <p>{text_managers}</p>,
  },
  {
    key: '3',
    label: 'Email Notifications',
    children: <p>{text_recruiters}</p>,
  },
];  


const items = [
  {
    key: '1',
    label: 'Current JDs Database',
    children: <p>{text_org}</p>,
  },  
  {
    key: '2',
    label: 'Your Resumes',
    children: <p>{text_managers}</p>,
  },
  {
    key: '3',
    label: 'View Status and Analytics',
    children: <p>{text_recruiters}</p>,
  }
]; 
  const contentStyleQhrFlow = {
    margin: 20,
    padding: "5px",
    height: "300px",
    width: "auto",
    background: "#d0ece7 ",
  };

  const settings = {
    dots: false,
    speed: 10000,
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10,
  };

  const onChange = (key) => {
    console.log(key);
  };

  const handleSignin = () => {
    navigate("/login");
  };

  const handleSignup = () => {
    navigate("/signup");
  };
//
 const handleOnclick =(e) =>{
    navigate('/qhrflow');
  }
  return (
    <div className="qhrflowlanding-wrapper">
      <div className="landing-header">
          <LandingHeader/>
      </div>
      <div className="qhrflowlanding-header">
        <div className="qhrflowlanding-header-left">
          <div className="qhrflowlanding-logo">
            <img className="qhrflowlanding-logo-img" src={logo}  onClick={() => handleOnclick()}/>
          </div>
          <div className="qhrflowlanding-menu">
            <ul className="qhrflowlanding-menu">
              <li>
                  <Link to="/qhrflowvendorconnect">
                     QHRFLOW VendorConnect Home
                  </Link> 
              </li>
              <li>Blogs</li>
              <li>FAQ /Support</li>
           </ul>
          </div>
          <div className="qhrflowlanding-sign">
              <Button className="qhrflowlanding-sign-button" onClick={() => handleSignin()}>VendorConnect Sign In</Button>
          </div>
        </div>
      </div>

      <div className="qhrflowlanding-main"> 
        <div className="qhrflowlanding-main-text">
            Detailed Analytics of Your Work
        </div>
        <div className="qhrflowlanding-main-slider">
          <div className="punch-heading-wrapper">
            <div>
                  <img src={vendor} alt="hiring" width="400" height="400" />
            </div>
          </div>
          <div className="qhrflowlanding-selectable-wrapper">
            <Collapse items={items_features} defaultActiveKey={['1']} onChange={onChange} />
          </div>
        </div>        
      </div>

      <div className="landing-features features-wrapper">
        <div className="feature-heading">Be in Sync With Recruiter </div>
        <div className="qhrflowvendorconnect-features">
          <div className="features-center">
                <Collapse items={items} defaultActiveKey={['1']} onChange={onChange} />
          </div>       
        </div>

        <div className="feature-endHeading-wrapper">
          <div className="feature-endHeading">
            Experience the{" "}
            <span
              style={{
                background: "#f4d03f",
                padding: "10px",
                fontWeight: "300",
              }}
            >
              {" "}
              difference
            </span>
            <br></br>
            Try{" "}
            <span
              style={{
                background: "#f4d03f",
                padding: "10px",
                fontWeight: "500",
              }}
            >
              {" "}
              Quallyhire AI Product Suits
            </span>
          </div>
          <div className="feature-Button-wrapper">
            <Button type="primary" shape="round" size="large">
              Talk to Sales
            </Button>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="landing-footer">
        <Footer />
      </div>
    </div>
  );
}

export default QHRFVendorConnect;
