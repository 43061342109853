import React, { useState, useEffect } from "react";
import { Button, Empty } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomerTable from "../customerTable";
import TAAddModal from "../taAddModal";

import useMembersStore from "../../store/members.store";
import useClientStore from "../../store/clientModal.store";

import "./specificCustomer.scss";

const taListTitle = [
    "S. No.",
    "TA Name",
    "Contact Number",
    "email id",
    "Role",
    "Group",
    "Lead",
    "Manager",
    "Project",
    "Added On",
    "Action"
];

function SpecificCustomer() {
  const navigate = useNavigate();
    const { getClients,clients } =
        useMembersStore();

    const { setModalOpen, removeClientTA } =
        useClientStore();        

    useEffect(() => {
        getClients();
        getTAListfromClientList();
    }, []);

  // const { clientName,clientID } = useParams();
  const { clientName } = useParams();
  const location = useLocation();
  const { clientID } = location.state || {};
  console.log(clients, "This is Clients");
  const handleAddTeamClick = () => {
    setModalOpen();
  };

    var taList;

    const getTAListfromClientList=() =>{
     var clientTAList
     clients[clientID]?.ClientName == clientName
      ?
      clientTAList = clients[clientID].ClientTAList
      :clientTAList= null;
      taList =
      clientTAList && clientTAList.length > 0
         ? clientTAList.map((ta, idx) => ({
               id: idx + 1,
               name: ta?.TAName,
               contact: ta?.TANumber,
               email: ta?.TAMail,                  
               role: ta?.TARole,
               group: ta?.TAGroup,
               lead: ta?.TALead,
               manager: ta?.TAManager,
               project: ta?.TAProject,
               addedDate: ta?.TAAdditionDate,
               remove: (
                   <div
                       style={{
                           cursor: "pointer",
                           color: "blue",
                           fontWeight: "600",
                       }}
                       onClick={() => removeClientTA(clientName,ta.TAName)}
                   >
                       Remove
                   </div>
               ),
           }))
         : [];
    }

    return (
        <div className="customers-container">
            <div className="customers-btns-wrapper">
                <Button
                    className={"customer-btn"}
                >
                    Customer TA Details
                </Button>
            </div>
            <div className="customer-members-wrapper">
                    <div className="specific-customer-title">
                        {clients[clientID]?.ClientName}
                        {getTAListfromClientList()}
                    </div>
                    <div style={{display:"flex"}}>
                    <h2 className="customer-members-list-title">List of TAs</h2>
                    <div className="customer-members-add-wrapper">
                        <Button
                            onClick={handleAddTeamClick}
                            type="primary"
                            className="customer-members-add-btn"
                        >
                            + Add new TAs
                        </Button>
                    </div>
                    </div>
                     {clients[clientID]?.ClientTAList?.length > 0 ? (
                        <CustomerTable
                            titleList={taListTitle}
                            data={taList}
                        />
                    ) : (
                        <Empty style={{ margin: "60px" }} />
                    )}
            </div>
            
            <TAAddModal clientName ={clientName}/>
        </div>
    );
}

export default SpecificCustomer;
