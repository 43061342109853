/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useState } from "react";
import { Avatar, Dropdown, Badge, Tooltip } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/QuallyHireGreen.png";
import Notifications from "../../assets/notifications.svg";
import Settings from "../../assets/settings.svg";
import {
  DownOutlined,
  UserOutlined,
  EditOutlined,
  LogoutOutlined,
  FileOutlined,
  FormOutlined,
  AntDesignOutlined,
} from "@ant-design/icons";

import "./header.scss";
import useMembersStore from "../../store/members.store";
import useStatsStore from "../../store/stats.store";
import useAuthStore from "../../store/auth.store";

const Header = () => {
    
  const {unreadNotifications, markNotificationAsRead} = useStatsStore();
  const { getTeamMembers, teamMembers } = useMembersStore();
  const [userStats, setUserStats] = useState();
  const {userLogout} = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    getTeamMembers();
  }, []);

  var navigateTo = () => {
    navigate("/settings");
  };

    const userDetails = JSON.parse(localStorage.getItem("userDetails"));

    const handleClickNewJD = () => {
    
        // Perform API call here with the selected option
        navigate(`/createJd`);
      };

    const handleLogOut = () => {
        userLogout();
        localStorage.removeItem("userDetails");
        navigate(`/home`);
    };

    const handleProfile = () => {
        navigate(`/profile`);
      };
    const handleSubscriptions = () => {
        if(userDetails.permission == "superuser")
            navigate(`/superuser`)
        else if(userDetails.permission == "superadmin")
            navigate('/superadmin')
        else
            navigate('/usersubscription')
      };      
    const items = [
        {
            label: (
                <div className="e-mail">
                    {userDetails.email}
                </div>
            ),
            key: "0",
        },        
        {
            label: (
                <div className="log-out" onClick={handleProfile}>
                    Profile
                </div>
            ),
            key: "1",
            icon: <UserOutlined />,
        },
        {
            label: (
                <div className="log-out" onClick={handleProfile}>
                    FAQ
                </div>
            ),
            key: "2",
            icon: <FileOutlined />,            
        },
        {
            label: (
                <div className="log-out" onClick={handleProfile}>
                    Analytics
                </div>
            ),
            key: "3",
            icon: <FormOutlined />,                  
        },
        {
            label: (
                <div className="log-out" onClick={handleSubscriptions}>
                    Subscriptions
                </div>
            ),
            key: "4",
            icon: <FormOutlined />,                  
        },
        {
            label: (
                <div className="log-out" onClick={handleProfile}>
                    Help
                </div>
            ),
            key: "5",
            icon: <FormOutlined />,                  
        },        
        {
            label: (
                <div className="log-out" onClick={handleLogOut}>
                    Logout
                </div>
            ),
            key: "6",
            icon: <LogoutOutlined />,
        },
    ];
  const colors = ["#f56a00", "#87d068", "#1890ff", "#ffc107", "#d32f2f"];

    return (
        <nav className="navbar-wrapper">
            <div className="logo-wrapper">
                <img src={Logo} className="logo" alt="QuallyHire" />
            </div>
            <div className="services-links-wrapper">
            <Link className="links" to="/">
                Home
            </Link>
            <Link className="links" to="/customers">
                Customers
            </Link> 
            <Link className="links" to="/contactBook">
                Contact Book
            </Link>  
            <Link className="links" to="/communication">
                Communication
            </Link>
            <Link className="links" to="/timeSheet">
                Time Sheet
            </Link> 
            <Link className="links" to="/leaves">
                Leaves
            </Link>
            <Link className="links" to="/holidays">
                Holidays
            </Link>                                                                           
        <Link className="links" to="/users">
          Users
        </Link>
      </div>
      <div className="info-wrapper">
        <Avatar.Group
          max={{
            count: 3,
            style: {
              color: "#f56a00",
              backgroundColor: "#fde3cf",
            },
          }}
        >
          {teamMembers.map((member, index) => {
            const bgColor = colors[index % colors.length];

            return (
              <Tooltip
                title={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "10px",
                      gap: "8px",
                    }}
                  >
                    <Avatar
                      style={{
                        backgroundColor: bgColor,
                      }}
                    >
                      {member?.username[0]}
                    </Avatar>
                    <div>{member?.email}</div>
                  </div>
                }
                placement="bottom"
                color="white"
                overlayInnerStyle={{
                  color: "black", // Text color
                  fontWeight: "bold", // Optional: additional styles
                }}
              >
                <Avatar
                  style={{
                    backgroundColor: bgColor,
                  }}
                >
                  {member?.username[0]}
                </Avatar>
              </Tooltip>
            );
          })}
        </Avatar.Group>

        <button className="btn-header">Upload Resume</button>
        <button onClick={handleClickNewJD} className="btn-header">
          + New JD
        </button>
        <button className="btn-header">+ New Interview</button>
        <button className="btn-header">Search</button>

        <img
          onClick={navigateTo}
          className="info-logo"
          src={Settings}
          alt="Settings"
        />
        <Badge
          className="info-logo"
          count={unreadNotifications > 0 ? unreadNotifications : ""}
        >
          <img
            src={Notifications}
            alt="Notifications"
            onClick={() => {
              navigate("/notifications");
              markNotificationAsRead();
            }}
          />
        </Badge>
        <Dropdown
          menu={{
            items,
          }}
          trigger={["click"]}
        >
                    <Avatar className="user-avatar">
                        {userDetails.username[0]}
                    </Avatar>
                </Dropdown>
            </div>
        </nav>
    );
};

export default Header;
