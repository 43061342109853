import React, { useState } from "react";

import { actions } from "../actions";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "../assets/QuallyHireGreen.png";
import mainSlider from "../assets/mainSlider.jpg";
import resume from "../assets/resume.png";
import { Container } from "react-bootstrap";
import { Button, Empty } from "antd";
import Footer from "../landing/footer";
import Features from "../landing/features";
import ForWho from "../landing/forWho";
import LandingHeader from "./header";
import JobListings from "../landing/jobSeekers/jobListing";

import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";

import "./landing.css";


function Landing (props)  {
  const [loader, SetLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [error,setError] = useState("")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleSubmit } = props;

  const items = [
    {
      label: (
        <Link className="header-left-link" to="/qhrflow">
          QHRFlow
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <Link className="header-left-link" to="/qhrflowvendorconnect">
          VendorConnect
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <Link className="header-left-link" to="/jobSeekers">
          Explorer
        </Link>
      ),
      key: "2",
    },
    {
      label: (
        <Link className="header-left-link" to="/careerWebsites">
          CareerLinks
        </Link>
      ),
      key: "3",
    },
    {
      label: (
        <Link className="header-left-link" to="">
          BenchHire Platform
        </Link>
      ),
      key: "4",
    },
    {
      label: (
        <Link className="header-left-link" to="">
          Employee Register and Leave Mgmt.
        </Link>
      ),
      key: "5",
    },
  ];
  const onSubmit = () => {
    console.log("on submit called");
    let dataToSend = { email: email };
    let inputData = { email: email };
    console.log("email", email);
    SetLoader(true);

    dispatch(actions.SignIn(dataToSend, (error, status) => {
      if (!error) {
        navigate("/otp", { state: { inputData, type: "login" } });
        SetLoader(false);
      } else {
        // Handle the error case
        SetLoader(false);
        console.error("API Error:", error);
        let e = error.split("or")
        setError(e[0] + "signup first")
       // setErrorMessage(error); // Set the error message in state
      }
    }));
  };

  const handleSignin = () => {
    navigate('/login')
  };

  const handleSignup = () => {
    navigate('/signup')
  };

  const handleJobSeekers = () => {
    navigate('/jobSeekers')
  };

  const handleResumeAnalysisFree = () => {
    navigate('/resumeAnalysisFree')
  };

  const handleCareerListing = () => {
    navigate('/careerWebsites')
  };
  return (
    <div className="landing-wrapper">
      <div className="landing-header">
          <LandingHeader/>
       </div>

      <div className="landing-main-slider">
        <div className="punch-heading-wrapper">
                <div className="punch-heading">
                   Quality Hiring Tools Family
                </div>
                <div className="punch-sub-heading">
                   Cost effective and efficient AI based tools for End to End HR and recruitment functions.
                </div>
                <Button className="request-button">
                   Request Demo
                </Button>
          </div>
          <div className="main-image-wrapper">
              <img className="main-image"src={mainSlider}/>
          </div>
       </div>
       <div className="landing-forWho">
          <ForWho/>
       </div>       
       <div className="landing-features">
          <Features/>
       </div>        
       <div className="landing-footer">
          <Footer/>
       </div>       
     </div>
  );
};

export default Landing;
