import React, { useState, useEffect, Children } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Radio,RadioChangeEvent, Divider, Form, Input, message, Button, TimePicker, DatePicker,Select, Cascader } from "antd";
import moment from 'moment'
import editIcon from "../../assets/pencil.png";

import useJDStore from "../../store/jd.store";
import useMembersStore from "../../store/members.store";

import "./jdCreationForm.scss";
import TextArea from "antd/es/input/TextArea";
import { Space } from "antd";

export default function JDCreationForm({ setCurrentView }) {
    const [isEditMode, setIsEditMode] = useState(false);
    const [formKey, setFormKey] = useState(1);
    const [isRequired, setIsRequired] = useState(true);

    const { Option } = Select;

  const navigate = useNavigate();
    const { value } = useParams();
    const { createJd, updateJd, selectedJD, clearJD, getJdByID} = useJDStore();
    const { getVendors, vendors, getTeamMembers, teamMembers, assignedTeamMembers, getAssignedMembers } = useMembersStore();
    const { clients } = useMembersStore();
    
    var ClientListDB = clients?.map((client, idx) => {
        return {
          value:idx,
          label:client?.ClientName,
        };
    });
    var clientNames = [{value:"select",label:"Please Select"}]
    var ClientList = [...clientNames, ...ClientListDB];
    const bStream = [
        { value: 'any',label:'Any'},
        { value: 'it', label: 'IT'},
        { value: 'computers', label: 'Computers' },
        { value: 'electronics', label: 'Electronics' },
        { value: 'electrical', label: 'Electrical'},
        { value: 'civil', label: 'Civil' },
        { value: 'mechanical', label: 'Mechanical' }, 
        { value: 'metallurgy', label: 'Metallurgy' }      
      ];
      
       const bDegrees = [
        { value: 'btech', label: 'BE/BTech',children: bStream},
        { value: 'bca', label: 'BCA' },
        { value: 'bcom', label: 'BCom'},
        { value: 'bpharma', label: 'Bpharma' },
        { value: 'bsc', label: 'BSc' }, 
        { value: 'bs', label: 'BS' },
        { value: 'any', label: 'Any' },      
      ];
      
      const mDegrees = [
        { value: 'mtech', label: 'ME/MTech',children: bStream},
        { value: 'mca', label: 'MCA' },
        { value: 'mcom', label: 'MCom'},
        { value: 'mpharma', label: 'Mpharma' },
        { value: 'msc', label: 'MSc' }, 
        { value: 'ms', label: 'MS' },
        { value: 'any', label: 'Any' }       
      ];
      
      const medicalDegrees = [
        { value: 'mbbs', label: 'MBBS',children: bStream},
        { value: 'md', label: 'MS/MD' },
        { value: 'any', label: 'Any'}     
      ];
      
      const businessDegrees = [
        { value: 'mba', label: 'MBA',children: bStream},
        { value: 'bba', label: 'BBA' },
        { value: 'any', label: 'Any'}     
      ];
       const educationList = [
        { value: 'Bachelors', label: 'Bachelors',children: bDegrees},
        { value: 'Masters', label: 'Masters', children: mDegrees },
        { value: 'Medical', label: 'Medical', children: medicalDegrees },
        { value: 'Business', label: 'Business', children: businessDegrees},
        { value: 'Diploma', label: 'Diploma' },
        { value: 'Phd', label: 'Phd' },           
      ];

    useEffect(() => {
        console.log("In JD creating FORM value", value);
        if (value) {
            const fetchData = async (value) => {
                try {
                    if (value) {
                        getJdByID(value);
                        getVendors();
                        getTeamMembers();
                        getAssignedMembers(value);
                    }
                } catch (error) {
                  console.error("Error fetching data:", error);
                }
              };
          
              fetchData(value);  
        } else {
            console.log("clearing JD and selected mode",selectedJD);
            setIsEditMode(true)
            clearJD()
            jdValues = {}

        }
    }, [value]);

    useEffect(() => {
        console.log("user effect of JD Creation Form",selectedJD);
        setFormKey((prevKey) => prevKey + 1);
    }, [selectedJD]);
    var taList=[];
    const handlClient = (val) => {
        //reinit
        taList=[];
        taList.push({value:"select",label:"Please Select"})
        if(val === "select") {
            return;
        }
        var tL =clients[val].ClientTAList 
        var taListDB = tL?.map((ta, idx) => {
        return {
          value:ta?.TAName,
          label:ta?.TAName,
        };
       });
       taList = [...taList, ...taListDB];
      };


    var clientTADB = clients?.map((client, idx) => {

        var tL =client?.ClientTAList 
        var taListDB = tL?.map((ta, idx) => {
        return {
          value:ta?.TAName,
          label:ta?.TAName,
        };
       });
        return {
          value:client?.ClientName,
          label:client?.ClientName,
          children:taListDB
        };
    })    
    const handleClientandTA =(val) => {
         console.log('Client and TA Option Cient DB val', val);
         console.log('Client TA Option Cient DB ', clientTADB);
    }

    const onChange = (e) => {
        console.log('radio checked', e.target.value);       
        setReqType(e.target.value)
      };


    const onChangeEducation = (value) => {
        console.log(value);
      };

      function getTimeNow(){
        const today = new Date();
        const month = today.getMonth()+1;
        const year = today.getFullYear();
        const date = today. getDate();
        const currentDate = date + "-" + month + "-" + year;
        return currentDate
    }

    const encodeJson = (values) =>{

        let encodedJsonValues ={};

        console.log("data to encode", values)
        //FSM to maintain the JD state
        encodedJsonValues.jdstate= "NEW JOB";

        //get company details
        const userData = JSON.parse(localStorage.getItem("userDetails"));
        encodedJsonValues.qhoid= userData.ID;
        encodedJsonValues.accounttype= userData.account_type ;

        //now fill the parameters
        encodedJsonValues.jdproject ={};
        encodedJsonValues.jdproject.reqtype=values.req_type;
        encodedJsonValues.jdproject.buName=values.bu_name;
        encodedJsonValues.jdproject.projectName=values.proj_name;
        encodedJsonValues.jdproject.reqStatus="active";
        encodedJsonValues.jdproject.hmName=values.hm_name;
        encodedJsonValues.jdproject.hmEmail=values.hm_email;
        encodedJsonValues.jdproject.hmNumber=values.hm_number;
        encodedJsonValues.jdproject.numPositions= parseInt(values.number_of_positions);
        encodedJsonValues.jdproject.minctc= values.min_ctc;            
        encodedJsonValues.jdproject.maxctc= values.max_ctc;

        encodedJsonValues.jddetails ={};
        encodedJsonValues.jddetails.Title= values.title;
        encodedJsonValues.jddetails.Skills= values.Skills;
        encodedJsonValues.jddetails.JobPermission=values.job_permission; // private protected public
        encodedJsonValues.jddetails.JobTime=values.job_time; // full time, part time
        encodedJsonValues.jddetails.JobType=values.job_type; // permanent, contract
        encodedJsonValues.jddetails.JobCategory=values.job_category; // many types
        encodedJsonValues.jddetails.Designation=values.designation;
        encodedJsonValues.jddetails.Role=values.designation;
    
        encodedJsonValues.jdrequired ={};
        encodedJsonValues.jdrequired.Skills= values.skills;
        encodedJsonValues.jdrequired.RoleDetails= values.roles_and_responsibility;
        encodedJsonValues.jdrequired.Keywords= values.keywords;
        encodedJsonValues.jdrequired.Education= values.education;
        encodedJsonValues.jdrequired.NoticePeriod= parseInt(values.notice_period); //int
        encodedJsonValues.jdrequired.MinExperience= values.min_exp;
        encodedJsonValues.jdrequired.MaxExperience= values.max_exp;
        encodedJsonValues.jdrequired.WorkLocation= values.work_location;
        encodedJsonValues.jdrequired.WorkMode= values.work_mode;
        encodedJsonValues.jdrequired.Diversity= false; //bool
        encodedJsonValues.jdrequired.Fresher= false; //bool
        encodedJsonValues.jdrequired.AdditionalInfo= "";                          

        //not assigned yet
        encodedJsonValues.jdassignment =null;
        
        encodedJsonValues.jduseraction ={};
        encodedJsonValues.jduseraction.isFavourite = false;//bool
        encodedJsonValues.jduseraction.colorCode="";

        encodedJsonValues.jdclient ={};
        if(values.req_type === "customer-project"){
            encodedJsonValues.jdclient.clientName = values.client_name[0];
            encodedJsonValues.jdclient.TaName = values.client_name[1];
            encodedJsonValues.jdclient.endClient =values.end_client_name;    
        }else{
            encodedJsonValues.jdclient.clientName = "Internal";
            encodedJsonValues.jdclient.TaName = userData.username;;
        }
        //not assinged yet
        //encodedJsonValues.jdclient.taName      ="";
        //encodedJsonValues.jdclient.taContact   ="";
        //encodedJsonValues.jdclient.taMail      ="";
        
        //encodedJsonValues.jdvendorconnect =[];
        //let vendorDetails ={}
        //vendorDetails.qhVID= "";
	    //vendorDetails.vendorName ="";
        //encodedJsonValues.jdvendorconnect.push(vendorDetails);        
        encodedJsonValues.jdother ={}; 
        encodedJsonValues.jdother.valid_until = values.valid_until;
        
        //get the current date
        let newDate = getTimeNow()
        encodedJsonValues.jdother.postingDate= newDate;
        encodedJsonValues.jdother.priority    = values.priority;
        encodedJsonValues.jdother.postedby    = userData.username;
        encodedJsonValues.jdother.qhuid    = userData.qhuid;
        
        //no resume added
        encodedJsonValues.resumelist = null;             
        return encodedJsonValues
    }

  const handleFormSubmit = async (values) => {
    const jasonValues = encodeJson(values);

    try {
      const createdJD = await createJd({
        ...jasonValues,
      });
      message.success("New JD Created");
      navigate(`/jds/${createdJD}`, { state: { newView: "assignment" } });
    } catch (err) {
      message.error("Failed to create JD. Please try again.");
      console.error("Error during JD creation:", err);
    }
  };

    const handleUpdateFormSubmit = (values) => {
        console.log("called")
        updateJd(
            {
                ...values,
                number_of_positions: parseInt(values.number_of_positions),
                experience: parseInt(values.experience),
                notice_period: parseInt(values.notice_period),
                offered_CTC: parseFloat(values.offered_CTC),
            },
            value
        );
        message.success("Job description updated successfully");
        setCurrentView("assignment");
        setIsEditMode(false);
    };

    console.log("Value is ",value);
    var jdValues
    if (value) {
        console.log("In JD Creation Form ",value);
        console.log("Selected JD",selectedJD);
        jdValues = {
            bu_name: selectedJD?.JDProject.BuName,
            proj_name: selectedJD?.JDProject.ProjectName,
            job_category: selectedJD?.JDDetails.JobCategory,
            job_permission: selectedJD?.JDDetails.JobPermission,
            title: selectedJD?.JDDetails.Title,
            req_type: selectedJD?.JDProject.ReqType,
            client_name: selectedJD?.JDClient.ClientName,
            endb_client_name: selectedJD?.JDClient.EndClient,
            job_type: selectedJD?.JDDetails.JobType,
            job_time: selectedJD?.JDDetails.JobTime,
            designation: selectedJD?.JDDetails.Designation,
            roles_and_responsibility: selectedJD?.JDRequired.RoleDetails,
            skills: selectedJD?.JDRequired.Skills,
            keywords: selectedJD?.JDRequired.Keywords,
            soft_skills: selectedJD?.soft_skills,
            education: selectedJD?.JDRequired.Education,
            min_ctc: selectedJD?.JDProject.MinCtc,
            max_ctc: selectedJD?.JDProject.MaxCtc,
            creation_date: selectedJD?.creation_date,
            priority: selectedJD?.JDOther.Priority,
            number_of_positions: selectedJD?.JDProject.NumPositions,
            valid_until: moment(selectedJD?.valid_until),
            work_location: selectedJD?.JDRequired.WorkLocation,
            work_mode: selectedJD?.JDRequired.WorkMode,
            notice_period: selectedJD?.JDRequired.NoticePeriod,
            min_experience: selectedJD?.JDRequired.MinExperience,
            max_experience: selectedJD?.JDRequired.MaxExperience,
            hm_name: selectedJD?.HmName,
            hm_email: selectedJD?.HmEmail,
            hm_number: selectedJD?.HmNumber,
        };
    }

    const [reqType, setReqType] = useState(jdValues?.req_type);    


    return (
        <div className="jd-creation-form-wrapper">
            <div className="header">
                {isEditMode ?<h3 className="jd-creation-form-title">Creating New JD</h3>  :
                              <h3 className="jd-creation-form-title">JD (Read Only)</h3>  }
                 
                {!isEditMode && (
                    <Button className="jd-creation-edit-button" onClick={() => setIsEditMode(true)} type="primary">
                        <img
                        src={editIcon}
                        alt="Edit"
                        width="14" height="14" 
                        />
                    </Button>
                )}
            </div>
            <Form
                key={formKey}
                className="jd-creation-form"
                name="jd-creation-form"
                justify="start"
                layout="vertical"
                autoComplete="off"
                onFinish={value ? handleUpdateFormSubmit : handleFormSubmit}
                initialValues={jdValues}
>

               <div className="dual-input-wrapper">
                    <Form.Item
                        className="jd-creation-field-item"
                        label="BU Name"
                        name="bu_name"
                    >
                        <Input
                            disabled={!isEditMode}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                    <Form.Item
                            name="proj_name"
                            className="jd-creation-field-item"
                            label="Project Name"
                        >
                            <Input
                                disabled={!isEditMode}
                                className="jd-creation-input"
                            />
                    </Form.Item>
                </div>

                <div className="dual-input-wrapper">
                    <Form.Item
                        required={value ? false : true}
                        className="jd-creation-field-item"
                        label="Job Category"
                        name="job_category"
                    >
                            <Select
                                disabled={!isEditMode}
                                required={value ? false : true}
                                className="jd-creation-input"                            
                                defaultValue="select"
                                options={[
                                    { value: 'select', label: 'Please select' },
                                    { value: 'it', label: 'IT and Software' },
                                    { value: 'vlsi', label: 'Hardware and VLSI' },
                                    { value: 'wireless', label: 'Wireless' },
                                    { value: 'embedded', label: 'Embedded'},
                                    { value: 'teaching', label: 'Teaching' },
                                    { value: 'BFSI', label: 'BFSI' },
                                    { value: 'mechanical', label: 'Mechanical' },
                                    { value: 'research', label: 'Research'}, 
                                    { value: 'purchasing', label: 'Purchasing ' },
                                    { value: 'marketing', label: 'Sales and Marketing' },
                                    { value: 'production', label: 'Production' },
                                    { value: 'medical', label: 'Medical '},   
                                ]}
                            />                         
                    </Form.Item>
                    <Form.Item
                            required={value ? false : true}
                            name="job_permission"
                            className="jd-creation-field-item"
                            label="Job Permission"
                        >
                            <Select
                                disabled={!isEditMode}
                                required={value ? false : true}
                                className="jd-creation-input"                            
                                defaultValue="select"
                                options={[
                                    { value: 'select', label: 'Please select' },
                                    { value: 'private', label: 'PRIVATE' },
                                    { value: 'public', label: 'PUBLIC' },
                                    { value: 'protected', label: 'PROTECTED' },
                                ]}
                            />                            
                    </Form.Item>
                </div>
                <Form.Item
                    required={value ? false : true}
                    className="jd-creation-field-item"
                    label="Job Title"
                    name="title"
                >
                    <Input
                        disabled={!isEditMode}
                        required={value ? false : true}
                        placeholder={"Enter the JD Title "}
                        className="jd-creation-input"
                    />
                </Form.Item>
                <Form.Item
                    required={value ? false : true}
                    className="jd-creation-field-item"
                    name="req_type"
                    label="Requirement Type"
                >
                    <Radio.Group
                        disabled={!isEditMode}
                        className="jd-creation-radio-wrapper"
                        onChange={onChange}
                        defaultValue={jdValues?.req_type!=""?jdValues?.req_type:reqType}
                    >
                        <Radio
                            className="radio-option"
                            value="internal-project"
                        >
                            Internal Requirements
                        </Radio>
                        <Radio
                            className="radio-option"
                            value="customer-project"
                        >
                            Customer Requirements
                        </Radio>
                    </Radio.Group>
                </Form.Item>

                {(reqType === "internal-project")?
                    <></>
                    :
                    <>
                        <div className="dual-input-wrapper">
                        <Form.Item
                            required={value ? false : true}
                            className="jd-creation-field-item"
                            label="Client/TA Name"
                            name="client_name"
                        >                        
                        <Cascader
                             disabled={!isEditMode}
                             required={value ? false : true}
                             className="jd-creation-input"  
                             options={clientTADB} 
                             onChange={handleClientandTA}
                             placeholder="Please select" 
                        />   
                            
                        </Form.Item>
                        <Form.Item
                                required={value ? false : true}
                                name="end_client_name"
                                className="jd-creation-field-item"
                                label="End Client"
                            >
                            <Input
                                disabled={!isEditMode}
                                required={value ? false : true}
                                className="jd-creation-input"
                            />    
                        </Form.Item>
                        </div>                                          
                    </>
                }

                <div className="dual-input-wrapper">
                    <Form.Item
                        required={value ? false : true}
                        className="jd-creation-field-item"
                        label="Job Type"
                        name="job_type"
                    >
                    <Select
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"                            
                            defaultValue="select"
                            options={[
                                  { value: 'select', label: 'Please Select' },
                                  { value: 'permanent', label: 'PERMANENT' },
                                  { value: 'contract', label: 'CONTRACT' },
                            ]}
                    />                        
                    </Form.Item>
                    <Form.Item
                            required={value ? false : true}
                            name="job_time"
                            className="jd-creation-field-item"
                            label="Job Time"
                        >
                        <Select
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"                            
                            defaultValue="select"
                            options={[
                                  { value: 'select', label: 'Please Select' },
                                  { value: 'full time', label: 'FULL TIME' },
                                  { value: 'part time', label: 'PART TIME' },
                            ]}
                        />  
                    </Form.Item>
                </div>
                <div className="triple-input-wrapper">
                    <Form.Item
                        required={value ? false : true}
                        name="priority"
                        className="jd-creation-field-item"
                        label="Job Priority"
                    >
                        <Select
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"                            
                            defaultValue="select"
                            options={[
                                  { value: 'select', label: 'Please Select' },
                                  { value: 'high', label: 'HIGH' },
                                  { value: 'medium', label: 'MEDIUM' },
                                  { value: 'low', label: 'LOW' },
                                  { value: 'urgent', label: 'URGENT' },
                                  { value: 'todays', label: 'TODAYs' },                                                                  
                            ]}
                        />                          
                    </Form.Item>

                    <Form.Item
                        required={value ? false : true}
                        name="number_of_positions"
                        className="jd-creation-field-item"
                        label="Number of Positions"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="text"
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                    <Form.Item
                        name="valid_until"
                        className="jd-creation-field-item"
                        label="JD Validity"
                    >
                        <DatePicker
                            style={{ width: "100%" }}
                            disabled={!isEditMode}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                </div>

                <Form.Item
                        required={value ? false : true}
                        name="designation"
                        className="jd-creation-field-item"
                        label="Designation"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                </Form.Item> 
                <Form.Item
                    required={value ? false : true}
                    name="roles_and_responsibility"
                    className="jd-creation-field-item"
                    label="Role & Responsibility of the job"
                >
                    <TextArea
                        disabled={!isEditMode}
                        required={value ? false : true}
                        className="jd-creation-input-large"
                    />
                </Form.Item>
                <Form.Item
                        required={value ? false : true}
                        name="skills"
                        className="jd-creation-field-item"
                        label="Required skills "
                >
                    <TextArea
                        disabled={!isEditMode}
                        required={value ? false : true}
                        className="jd-creation-input-large"
                    />
                </Form.Item>
                
                <Form.Item
                        required={value ? false : true}
                        name="keywords"
                        className="jd-creation-field-item"
                        label="Keyword Tags (skill1, skill2,...)"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                </Form.Item>                              
                <div className="triple-input-wrapper">
                    <Form.Item
                        className="jd-creation-field-item"
            label="Experience Range ( Min - Max ) Yrs "
            required={value ? false : true}
          >
            <Space style={{ display: "flex", gap: "8px" }}>
              <Form.Item
                required={value ? false : true}
                className="jd-creation-field-item"
                name="min_exp"
              >
                <Input
                  disabled={!isEditMode}
                  type="number"
                  required={value ? false : true}
                  className="jd-creation-input"
                  placeholder="Min"
                />
              </Form.Item>
              <Form.Item
                required={value ? false : true}
                className="jd-creation-field-item"
                name="max_exp"
              >
                <Input
                  disabled={!isEditMode}
                  type="number"
                  required={value ? false : true}
                  className="jd-creation-input"
                  placeholder="Max"
                />
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item
                        className="jd-creation-field-item"
            label="Offered CTC (Min - Max) LPA"
            required={value ? false : true}
          >
            <Space>
              <Form.Item
                name="min_ctc"
                className="jd-creation-field-item"
              >
                <Input
                  disabled={!isEditMode}
                  type="number"
                  required={value ? false : true}
                  className="jd-creation-input"
                  placeholder="Min"
                />
              </Form.Item>
              <Form.Item
                required={value ? false : true}
                name="max_ctc"
                className="jd-creation-field-item"
              >
                <Input
                  disabled={!isEditMode}
                  type="number"
                  required={value ? false : true}
                  className="jd-creation-input"
                  placeholder="Max"
                />
              </Form.Item>
            </Space>
          </Form.Item>
                    <Form.Item
                        required={value ? false : true}
                        className="jd-creation-field-item"
                        label="Notice Period (Days)"
                        name="notice_period"
                    >
                        <Input
                            disabled={!isEditMode}
                            type="number"
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                </div>

                <div className="triple-input-wrapper">
                    <Form.Item
                        required={value ? false : true}
                        name="education"
                        className="jd-creation-field-item"
                        label="Education"
                    >

                        <Cascader 
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"   
                            options={educationList} 
                            onChange={onChangeEducation} 
                            placeholder="Please Select"
                            multiple
                        />                                                
                    </Form.Item>  

                    <Form.Item
                        required={value ? false : true}
                        name="work_location"
                        className="jd-creation-field-item"
                        label="Work Location (City/State/Country)"
                    >
                        <Input
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                    <Form.Item
                        required={isRequired}
                        name="work_mode"
                        className="jd-creation-field-item"
                        label="Work Mode"
                    >
                        <Select
                            disabled={!isEditMode}
                            required={value ? false : true}
                            className="jd-creation-input"                            
                            defaultValue="select"
                            options={[
                                  { value: 'select', label: 'Please Select' },
                                  { value: 'hybrid', label: 'HYBRID' },
                                  { value: 'wfo', label: 'WFO' },
                                  { value: 'wfh', label: 'WFH' },                                                               
                            ]}
                        />                          
                    </Form.Item>
                </div>


                <div className="triple-input-wrapper">
                    <Form.Item
                        name="hm_name"
                        className="jd-creation-field-item"
                        label="Hiring Manager Name"
                    >
                        <Input
                            disabled={!isEditMode}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                    <Form.Item
                        name="hm_email"
                        className="jd-creation-field-item"
                        label="Hiring Manager E-Mail ID"
                    >
                        <Input
                            disabled={!isEditMode}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                    <Form.Item
                        name="hm_number"
                        className="jd-creation-field-item"
                        label="Hiring Manager Contact Number"
                    >
                        <Input
                            disabled={!isEditMode}
                            className="jd-creation-input"
                        />
                    </Form.Item>
                </div>
                <Button
                    style={{ width: "100%", height: "40px", fontWeight: "600" }}
                    type="primary"
                    disabled={!isEditMode}
                    htmlType="submit"
                >
                    Submit Job Description
                </Button>
            </Form>
        </div>
    );
}
